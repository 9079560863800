import { Auth } from 'aws-amplify';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import { ThemeProvider } from '@mui/material/styles';
import Primarytheme from './components/utility/theme';
import CssBaseline from "@mui/material/CssBaseline";

import ChangePassword from './components/auth/ChangePassword';
import ForgotPasswordConfirm from './components/auth/ForgotPasswordConfirm';
import FirstLogin from './components/auth/FirstLogin';
import ForgotPassword from './components/auth/ForgotPassword';
import ForgotPasswordVerification from './components/auth/ForgotPasswordVerification';
import Notfound from './components/utility/Notfound';
import Login from './components/auth/Login';

import History from './components/History';

import Navbar from './components/utility/Navbar';

import Dashboard from './components/Dashboard';
import Administration from './components/Administration';
import Billing from './components/Billing';
import Charts from './components/Charts';
import AssetManagement from './components/AssetManagement';
// import Tools from './components/Tools';
import Traffic from './components/Traffic';

import ErrorBoundary from './components/utility/ErrorBoundary';

import LoadingPage from './components/utility/LoadingPage';

import { config as AWSconfig, SSM, S3, CognitoIdentityCredentials } from 'aws-sdk';

AWSconfig.update({ region: 'eu-west-1' });

const axios = require('axios');
const { buildUsernameFromEmail } = require('./components/auth/utils-auth');

const paramIds = [
  `/web-app/invokeUrl`,
  `/web-app/USER_ID`,
  `/web-app/X_API_KEY`,
  `/web-app/USER_POOL_ID`,
  `/web-app/IDENTITY_POOL_ID`,
  `/web-app/APP_CLIENT_ID`,
  `/web-app/IDENTITY_POOL_ID_SDK`,
  `/web-app/S3_BUCKET`,
  `/web-app/ACCESS_KEY`,
  `/web-app/SECRET_ACCESS_KEY`,
  `/web-app/UPDATE_CONF_KEY`,
  `/web-app/UPDATE_CONF_URL`
];


const autorefreshAuthInterval = 3600000 //[ms] ogni ora

const {
  REACT_APP_X_API_KEY,
  REACT_APP_USER_ID,
  REACT_APP_USER_POOL_ID,
  REACT_APP_invokeUrl,
  REACT_APP_UPDATE_CONF_URL,
  REACT_APP_UPDATE_CONF_KEY,
  REACT_APP_SECRET_ACCESS_KEY,
  REACT_APP_ACCESS_KEY,
} = process.env;


export default function App() {

  const [userState, setUserState] = useState({
    isAuthenticated: false,
    isAuthenticating: true,
    isFetching: true,
    isConfirmed: false,
    isAdmin: false,
    user: null,
    lastLogin: null,
    email: null,
    projects: [],
    customer: null,
    userItem: null,
    attached_projects: [],
    active_project_ids: [],
    ssmParams: null,
    custom_message: null,
    type: null
  })


  const setAWStempCredentials = (JWTaccessToken) => {
    const LoginsObj = {};
    AWSconfig.update({ region: 'eu-west-1' });
    LoginsObj[`cognito-idp.eu-west-1.amazonaws.com/${process.env.REACT_APP_USER_POOL_ID}`] = JWTaccessToken;
    AWSconfig.update({
      credentials: new CognitoIdentityCredentials({
        IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
        Logins: LoginsObj
      })
    });
    AWSconfig.credentials.clearCachedId();

    return new Promise((resolve, reject) => {
      AWSconfig.credentials.refresh(err => {
        if (err) reject(err)
        else resolve("Successful login")
      });
    })
    /*     AWSconfig.credentials.refresh(err => {
          if (err) console.error(err)
          else console.log("Successful login")
        });
        AWSconfig.getCredentials((err) => {
          if (err) {
            console.log(err);
            console.log(err.originalError)
          }
        }); */

  }


  const fetchDynamoUser = async ({ username, xApiKey }) => {

    return axios({
      method: "get",
      url: 'https://api.pagination.com/vpc/users',
      headers: {
        "user_id": 'web-app',
        "userinfo_id": username,
        "content-type": "application/json",
        "x-api-key": xApiKey,
      }
    })
      .catch(err => console.log(err))


  }


  const refreshAuthentication = () => {
    //console.log('refresh auth')
    const currentAuthPromises = [
      Auth.currentSession(),
      Auth.currentAuthenticatedUser()
    ]
    return Promise.all(currentAuthPromises)
      .then(async ([session, user]) => {
        console.log('curren authenticated user ', user, session)
        await setAWStempCredentials(session.getIdToken().getJwtToken());
        //console.log('credenziali recuperate', AWSconfig.credentials);

        const ssmClient = new SSM();
        const ssmParamsPromises = paramIds.map((param) =>
          ssmClient.getParameter({
            Name: param,
            WithDecryption: true,
          }).promise().catch(error => {
            console.error(`error retrieving-param ${param}:`, error);
            return null;
          })
        )
        return Promise.all([
          user,
          Promise.all(ssmParamsPromises)
          // SSMPARAMS
        ]);
      })
      .then(([user, ssmParams]) => {
        const ssmPars = {};
        if (ssmParams.length) ssmParams.forEach(param => param ? ssmPars[param.Parameter.Name.split('/').pop()] = param.Parameter.Value : undefined);
        //console.log('ssm params ', ssmPars)
        // console.log('fetching user ', user.username, ssmPars.X_API_KEY)
        //console.log(user)
        const user_id = buildUsernameFromEmail(user.attributes.email);
        return fetchDynamoUser({ username: user_id, xApiKey: REACT_APP_X_API_KEY || ssmPars.X_API_KEY })
          .then(dynData => {
            //console.log('fetched user ', dynData)
            AWSconfig.update({
              accessKeyId: ssmPars.ACCESS_KEY || REACT_APP_ACCESS_KEY,
              secretAccessKey: ssmPars.SECRET_ACCESS_KEY || REACT_APP_SECRET_ACCESS_KEY
            })
            const userItem = dynData.data;
            const policy = {
              canDelete: true,
              canLaunch: true,
              canManageAsset: true,
              ...userItem.policy
            };
            setUserState({
              ...userState,
              isAuthenticated: true,
              user: userItem.user_id,
              email: userItem.email,
              isAuthenticating: false,
              isFetching: false,
              userItem,
              lastLogin: (new Date()).getTime(),
              attached_projects: userItem.attached_projects.sort(),
              customer: userItem.customer,
              isAdmin: userItem.type === 'administrator',
              type: userItem.type,
              ssmParams: ssmPars,
              policy: policy,
              s3: new S3(),
              custom_message: userItem.custom_message
            })
            return userItem
          })


      })

      .catch(err => {
        console.log('Login failed, please refresh page and try again', err);
        setUserState({
          ...userState,
          isAuthenticating: false,
          isFetching: false,
        })
      })
  }


  // record last login time
  useEffect(() => {
    if (userState.isAuthenticated) {
      axios.patch(`https://mjj82gfcl8.execute-api.eu-west-1.amazonaws.com/vpc/users`,
        {
          userInfo_id: buildUsernameFromEmail(userState.email),
          lastLogin: userState.lastLogin,
          project_ids: userState.attached_projects,
          type: userState.isAdmin ? 'administrator' : (userState.type === 'billing' ? 'billing' : ''),
          policy: userState.policy,
          user_pool_id: REACT_APP_USER_POOL_ID || userState.ssmParams.USER_POOL_ID,
          custom_message: userState.custom_message
        },
        {
          headers: {
            'user_id': REACT_APP_USER_ID || userState.ssmParams.USER_ID,
            "content-type": "application/json",
            "x-api-key": REACT_APP_X_API_KEY || userState.ssmParams.X_API_KEY,
          }

        })
        .then(resp => console.log('login success'))
        .catch(err => console.log(err))
    }
  }, [userState.lastLogin])

  // session
  useEffect(() => {
    console.log('app did mount')
    refreshAuthentication();
  }, []);


  // auto refresh auth
  useEffect(() => {
    function refrhtimer() {
      console.log('refresh auth');
      refreshAuthentication();
    }
    const intervalRefreshAuth = setInterval(refrhtimer, autorefreshAuthInterval);
    return function cleanup() {
      clearInterval(intervalRefreshAuth);
    }
  }, []);




  const authProps = {
    userState,
    setUserState,
    fetchDynamoUser: fetchDynamoUser,
    refreshAuthentication


  }


  return (
    <div className="App" >
      <ErrorBoundary>
        <ThemeProvider theme={Primarytheme}>
          <CssBaseline />
          <Router>
            <Navbar auth={authProps} />
            <Switch>
              <Route exact path="/">
                <PrivateComponent component={<Redirect to={`/dashboard`} auth={authProps} />} redirectPath='Login' authProps={authProps} />
              </ Route>
              <Route exact path="/dashboard">
                <PrivateComponent component={<Dashboard auth={authProps} />} redirectPath='Login' authProps={authProps} />
              </ Route>
              <Route exact path="/history">
                <PrivateComponent component={<History auth={authProps} itemsToShow={12} showFilters={true} />} redirectPath='Login' authProps={authProps} />
              </ Route>
              <Route exact path="/traffic">
                <PrivateComponent component={<Traffic auth={authProps} />} redirectPath='Login' authProps={authProps} onlyAdmin={true} />
              </ Route>
              <Route exact path="/administration">
                <PrivateComponent component={<Administration auth={authProps} />} redirectPath='Login' authProps={authProps} onlyAdmin={true} />
              </ Route>
              <Route exact path="/billing">
                <PrivateComponent component={<Billing auth={authProps} />} redirectPath='Login' authProps={authProps} onlyBilling={true} />
              </ Route>
              <Route exact path="/charts">
                <PrivateComponent component={<Charts auth={authProps} />} redirectPath='Login' authProps={authProps} onlyAdmin={true} />
              </ Route>
              <Route exact path="/assets">
                <PrivateComponent component={<AssetManagement auth={authProps} />} redirectPath='Login' authProps={authProps} onlyManageAsset={true} />
              </ Route>
              {/* <Route exact path="/tools">
                <PrivateComponent component={<Tools auth={authProps} />} redirectPath='Login' authProps={authProps} onlyAdmin={true} />
              </ Route> */}
              <Route exact path="/login">
                <Login auth={authProps} />
              </ Route>
              <Route exact path="/firstlogin">
                <FirstLogin auth={authProps} />
              </ Route>
              <Route exact path="/forgotpassword">
                <ForgotPassword />
              </ Route>
              <Route exact path="/forgotpasswordverification">
                <ForgotPasswordVerification auth={authProps} />
              </ Route>
              <Route exact path="/changepassword">
                <PrivateComponent component={<ChangePassword />} redirectPath='Login' authProps={authProps} />
              </ Route>
              <Route exact path="/forgotpasswordconfirm">
                <ForgotPasswordConfirm auth={authProps} />
              </ Route>
              <Route path="*">
                <Notfound />
              </ Route>
            </Switch>

          </Router>
        </ThemeProvider>
      </ErrorBoundary>
    </div>

  )
}



function PrivateComponent({ component, redirectPath, authProps, onlyAdmin, onlyBilling, onlyManageAsset }) {
  // se sei autenticato, mostra il componente
  if (authProps.userState.isAuthenticated) {

    if (onlyAdmin && !authProps.userState.isAdmin) {
      return <Redirect to={`/`} auth={authProps} />
    } else if (onlyManageAsset && !authProps.userState.policy.canManageAsset) {
      return <Redirect to={`/`} auth={authProps} />
    } else if (onlyBilling && (authProps.userState.type !== 'billing' && !authProps.userState.isAdmin)) {
      return <Redirect to={`/`} auth={authProps} />
    } else {
      return component
    }
  }
  // se ti stai autenticando, non mostrare niente, intanto aspetta
  else if (authProps.userState.isAuthenticating) {
    return <LoadingPage />
  }

  // se non sei autenticato, vai a login
  else return <Redirect to={`/${redirectPath}`} auth={authProps} />
}