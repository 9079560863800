import React, { useState } from 'react';
import { useHistory, useLocation, } from 'react-router-dom';
import { Auth } from "aws-amplify";

import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';

import Validate from "../utility/FormValidation";
import { Box, Paper, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';

import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import queryString from 'query-string';

import logo from './../../logos/Logo_Cerchio_1000px.svg'

export default function FirstLogin({ auth }) {

    const history = useHistory();
    const locParams = queryString.parse(useLocation().search);

    const [credentialsForm, setCredentialsForm] = useState({
        params: locParams,
        email: decodeURIComponent(locParams.email),
        password: '',
        confirmPassword: "",
        temporaryCode: "",
        showPassword: false,
        isLogging: false,
        errors: {
            email: "",
            password: "",
            confirmPassword: "",
            cognito: ""
        },
    });



    const handleSubmit = () => {
        //event.preventDefault();
        //form validation

        setCredentialsForm({ ...credentialsForm, isLogging: true });
        // console.log('trying first login', credentialsForm.params.username, credentialsForm.params.code)
        // username in cognito non possono avere spazi
        // nell'uri i + vengono tradotti con spazi, allora viene ricodificato il +
        Auth.signIn(credentialsForm.params.username.replace(' ', '+'), credentialsForm.params.code)
            .then(user => {
                if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    // console.log('temp login done, new password reset ', credentialsForm.password)
                    Auth.completeNewPassword(user, credentialsForm.password)
                        .then(user_resetted => Auth.signIn(credentialsForm.email, credentialsForm.password))
                        .then(user_logged => auth.refreshAuthentication())
                        .then(() => {
                            //console.log('auth refreshd, now redirect')
                            setCredentialsForm({ ...credentialsForm, isLogging: false });
                            redirect('/dashboard');
                        })
                        .catch(error => {
                            console.log(error)
                            setCredentialsForm({
                                ...credentialsForm,
                                isLogging: false,
                                errors: {
                                    ...credentialsForm.errors,
                                    cognito: error.message || 'Unexpect error during authentication',
                                }
                            });
                        })
                }
            })





    }


    const onInputChange = event => {
        const error = Validate({ ...credentialsForm, [event.target.id]: event.target.value });
        //console.log(error)
        setCredentialsForm({
            ...credentialsForm,
            [event.target.id]: event.target.value,
            errors: { ...credentialsForm.errors, ...error, cognito: null }
        });

    }

    const handleClickShowPassword = () => {
        setCredentialsForm({
            ...credentialsForm,
            showPassword: !credentialsForm.showPassword,
        });
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    const redirect = (dst) => {
        history.push(dst);
    }


    if (credentialsForm.params.email && credentialsForm.params.code && credentialsForm.params.username) {
        return (
            <Paper sx={{ width: '60%', minWidth: '30em', maxWidth: '40em', m: 'auto', marginTop: '10%', paddingTop: '2em' }} elevation={3}>
                <Stack
                    component="form"
                    noValidate
                    autoComplete="new-password"
                    direction='column'
                    alignItems='center'      
                >

                    <img src={logo} height="100" alt="pagination logo" />

                    <Typography sx={{ m: '1em', marginBottom: '0.5em', marginTop: '2em' }} >
                        Please set a new password
                    </Typography>
                    <Typography variant='caption' sx={{ m: '1em', marginBottom: '2em', }}>
                        Password must have at least 8 characters, upper and lower case, a number.
                    </Typography>

                    <TextField
                        label="email"
                        id="email"
                        disabled
                        defaultValue={credentialsForm.params.email}
                        sx={{ m: 1, width: '80%', maxWidth: '30em' }}
                    />


                    <FormControl sx={{ m: 1, width: '80%', maxWidth: '30em' }} variant="outlined">
                        <InputLabel error={credentialsForm.errors.password !== ""}>
                            Password
                        </InputLabel>
                        <OutlinedInput
                            id="password"
                            label="password"
                            type={credentialsForm.showPassword ? 'text' : 'password'}
                            value={credentialsForm.password}
                            onChange={onInputChange}
                            error={credentialsForm.errors.password !== ""}
                            autoComplete="new-password"
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {credentialsForm.showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        {credentialsForm.errors.password !== "" &&
                            <FormHelperText
                                error={true}
                                id="pass-err">
                                {credentialsForm.errors.password}
                            </FormHelperText>
                        }
                    </FormControl>


                    <FormControl sx={{ m: 1, width: '80%', maxWidth: '30em' }} variant="outlined">
                        <InputLabel error={credentialsForm.errors.confirmPassword !== ""}>
                            Confirm password
                        </InputLabel>
                        <OutlinedInput
                            id="confirmPassword"
                            label="Confirm password"
                            type={credentialsForm.showPassword ? 'text' : 'password'}
                            value={credentialsForm.confirmPassword}
                            onChange={onInputChange}
                            error={credentialsForm.errors.confirmPassword !== ""}
                            autoComplete="new-password"
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {credentialsForm.showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        {credentialsForm.errors.confirmPassword !== "" &&
                            <FormHelperText
                                error={true}
                                id="pass-err">
                                {credentialsForm.errors.confirmPassword}
                            </FormHelperText>
                        }
                    </FormControl>


                    {credentialsForm.errors.cognito !== undefined &&
                        <Box id='cognito-error'>
                            <Typography variant='caption' color='error' sx={{ marginBottom: '1em' }}>
                                {credentialsForm.errors.cognito}
                            </Typography>
                        </Box>
                    }


                    <LoadingButton
                        color="primary"
                        disabled={(credentialsForm.password === '' || credentialsForm.confirmPassword === '') || (credentialsForm.errors.email !== '' || credentialsForm.errors.password !== '' || credentialsForm.errors.confirmPassword !== '')}
                        loading={credentialsForm.isLogging}
                        //loadingPosition="start"
                        variant="contained"

                        onClick={handleSubmit}
                        sx={{ marginTop: '2em', marginBottom: '2em' }}
                    >
                        Login
                    </LoadingButton>



                </Stack >
            </Paper>
        )
    }
    else {
        return null
    }


}


