import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth } from "aws-amplify";

import Button from '@mui/material/Button';

import Validate from "../utility/FormValidation";
import { Box, Paper, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import logo from './../../logos/Logo_Cerchio_1000px.svg'

export default function Login({ auth }) {

    const [credentialsForm, setCredentialsForm] = useState({
        username: '',
        password: '',
        showPassword: false,
        isLogging: false,
        errors: {
            username: "",
            password: "",
            cognito: ""
        },
    });

    const history = useHistory();
    const redirect = (dst) => {
        history.push(dst);
    }

    const handleSubmit = async () => {
        //event.preventDefault();
        //form validation


        setCredentialsForm({ ...credentialsForm, isLogging: true });
        // console.log('autenthicating first time', credentialsForm.username)

        Auth.signIn(credentialsForm.username, credentialsForm.password)
            .then((user) => auth.refreshAuthentication())
            .then(() => {
                // console.log('auth refreshd, now redirect')
                setCredentialsForm({ ...credentialsForm, isLogging: false });
                redirect('/dashboard');
            })



            .catch(error => {
                console.log(error)
                setCredentialsForm({
                    ...credentialsForm,
                    errors: {
                        ...credentialsForm.errors,
                        cognito: error.message || 'Unexpect error during authentication',
                    }
                });
            })
    }


    const onInputChange = event => {
        const error = Validate({ ...credentialsForm, [event.target.id]: event.target.value });
        setCredentialsForm({
            ...credentialsForm,
            [event.target.id]: event.target.value,
            errors: { ...credentialsForm.errors, ...error, cognito: null }
        });

    }

    const handleClickShowPassword = () => {
        setCredentialsForm({
            ...credentialsForm,
            showPassword: !credentialsForm.showPassword,
        });
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };



    return (

        <Paper sx={{ width: '60%', minWidth: '30em', maxWidth: '40em', m: 'auto', marginTop: '10%', paddingTop: '2em' }} elevation={3}>
            <Stack
                direction='column'
                noValidate
                autoComplete="off"
                alignItems='center'

                spacing={2}

            >

                <img src={logo} height="100" alt="pagination logo" />

                <TextField
                    label="email"
                    id="username"
                    value={credentialsForm.username}
                    onChange={onInputChange}
                    error={credentialsForm.errors.username !== ""}
                    helperText={credentialsForm.errors.username ? credentialsForm.errors.username : ''}
                    sx={{ width: '80%', maxWidth: '30em' }}
                />


                <FormControl sx={{ m: 1, width: '80%', maxWidth: '30em' }} variant="outlined">
                    <InputLabel error={credentialsForm.errors.password !== ""}>
                        Password
                    </InputLabel>
                    <OutlinedInput
                        id="password"
                        label="password"
                        type={credentialsForm.showPassword ? 'text' : 'password'}
                        value={credentialsForm.password}
                        onChange={onInputChange}
                        error={credentialsForm.errors.password !== ""}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {credentialsForm.showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    {credentialsForm.errors.password !== "" &&
                        <FormHelperText
                            error={true}
                            id="pass-err">
                            {credentialsForm.errors.password}
                        </FormHelperText>
                    }
                </FormControl>


                {credentialsForm.errors.cognito !== undefined &&
                    <Typography variant='caption' color='error' sx={{ marginBottom: '1em' }}>
                        {credentialsForm.errors.cognito}
                    </Typography>
                }


                <LoadingButton
                    color="primary"
                    disabled={credentialsForm.errors.username !== '' || credentialsForm.errors.password !== ''}
                    loading={credentialsForm.isLogging}
                    //loadingPosition="start"
                    variant="contained"

                    onClick={handleSubmit}
                    sx={{ marginTop: '1em' }}
                >
                    Login
                </LoadingButton>



            </Stack >


            <Button variant='outlined' onClick={() => redirect('/forgotpassword')} sx={{ margin: '1em', }}>
                Forgot password
            </Button>


        </Paper>

    )

}
