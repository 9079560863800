import React from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { Tooltip, Box } from '@mui/material';
import Primarytheme from './theme';




export default function AlertDialog({ handleActionOnAgree, title, text, tip='', icon, buttonProps={}}) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleAgree = () => {
        setOpen(false);
        handleActionOnAgree()
    };
    const handleDisagree = () => {
        setOpen(false);
    };

    return (

        <Box>
            {/* <Button component="label" variant='outlined' size="small" onClick={handleClickOpen} endIcon={<DeleteIcon />}>Clear process</Button> */}
            <Tooltip title={tip}>
                <Box>
                    {icon && (
                        <IconButton sx={{ color: Primarytheme.palette.neutral.dark }} onClick={handleClickOpen} size='small' >
                            {icon}
                        </IconButton>
                    )}
                    {!icon && (
                        <Button sx={{ mt: 1, mr: 1 }} {...buttonProps} onClick={handleClickOpen}>
                           { buttonProps.label}
                        </Button>
                    )}
                </Box>




            </ Tooltip >
            <Dialog
                open={open}

                onClose={handleDisagree}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {text}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDisagree} color="primary">
                        Disagree
                    </Button>
                    <Button onClick={handleAgree} color="primary" autoFocus>
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>


    );
}
