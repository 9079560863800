import { useEffect, useState, useRef } from 'react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import Skeleton from '@mui/material/Skeleton';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Switch from '@mui/material/Switch';
import Divider from '@mui/material/Divider';
import Popper from '@mui/material/Popper';
import CircularProgress from '@mui/material/CircularProgress';

import IconButton from '@mui/material/IconButton';
import Refresh from '@mui/icons-material/Refresh'

import { getAssetFoldersFromConf, mapTableData } from '../helpers/assets';

const axios = require('axios');

const {
    REACT_APP_X_API_KEY,
    REACT_APP_USER_ID,
} = process.env;

export default function CoversIndesign({ conf, setConf, auth, authAll }) {


    const [refreshAsset, setRefreshAsset] = useState(1);

    const [assets, setAssets] = useState({

        fetching: true,
        refreshing: true,
        uploading: false,

        tag: null,
        refresh: 1,

        // navbar
        folders: [],

        // solo per images
        objectList: [],
        dataTables: [],
        dataTablesId: {},
        //selectionModel: [],

        // solo per indd
        inddValues: {},



    })

    const fetchS3Objects1 = function ({ src, name, type, tags, sharedProjectId, obj = {}, i = 0 }) {
        let action = type === 'img' ? 'query-items-dynamo' : type;
        let body = {};

        if (action === 'additional-data') {
            action = 'query-items-dynamo';
            body = {
                project_id: sharedProjectId ? sharedProjectId : src.split('/').slice(1, 3).join('/'),
                selectedFolder: name,
                fullSize: obj[src] ? obj[src].fullSize : 0,
                filterUser: authAll.policy.filterUser ? authAll.user : false,
            }
        }
        if (action === 'indesign') {
            action = 'query-indesign-dynamo';
        }
        if (type === 'img') {
            body = {
                project_id: sharedProjectId ? sharedProjectId : src.split('/').slice(1, 3).join('/'),
                selectedFolder: name,
                LastEvaluatedKey: obj[src] ? obj[src].LastEvaluatedKey : undefined,
                fullSize: obj[src] ? obj[src].fullSize : 0,
                filterUser: authAll.policy.filterUser ? authAll.user : false,
            }
        }

        return axios({
            method: "post",
            url: 'https://mjj82gfcl8.execute-api.eu-west-1.amazonaws.com/vpc/assets',
            headers: {
                'user_id': REACT_APP_USER_ID || auth.USER_ID,
                "content-type": "application/json",
                "x-api-key": REACT_APP_X_API_KEY || auth.X_API_KEY,
                "src": src,
                "tags": JSON.stringify(tags),
                "action": action,
                "project_id": src.split('/').slice(1, 3).join('/'),
                "selectedFolder": name,
                "LastEvaluatedKey": obj[src] ? JSON.stringify(obj[src].LastEvaluatedKey) : undefined,
                "lastId": obj[src] ? obj[src].lastId : 0,
                "fullSize": obj[src] ? obj[src].fullSize : 0,
            },
            data: { ...body }
        })
            .then(resp => {
                if (type === 'img') {
                    if (!resp.data.LastEvaluatedKey && !obj[src]) {
                        return { data: { s3KeyList: resp.data.s3KeyList, fullSize: resp.data.fullSize }, type }
                    } else {
                        if (!obj[src] || (obj[src] && resp.data.LastEvaluatedKey !== undefined)) {
                            obj[src] = {
                                fullSize: resp.data.fullSize,
                                s3KeyList: obj[src] ? [...obj[src].s3KeyList, ...resp.data.s3KeyList] : resp.data.s3KeyList,
                                startAfter: resp.data.lastKey,
                                src,
                                type,
                                tags,
                                LastEvaluatedKey: resp.data.LastEvaluatedKey ? resp.data.LastEvaluatedKey : undefined,
                            }

                            i++;
                            // recusrive call
                            return fetchS3Objects1({ src, type, name, tags, sharedProjectId, obj, i });
                        } else {

                            // return with the updated data
                            obj[src] = {
                                fullSize: resp.data.fullSize,
                                s3KeyList: obj[src] ? [...obj[src].s3KeyList, ...resp.data.s3KeyList] : resp.data.s3KeyList,
                                startAfter: resp.data.lastKey,
                                src,
                                type,
                                tags,
                                LastEvaluatedKey: resp.data.LastEvaluatedKey ? resp.data.LastEvaluatedKey : 0,
                            }

                            return { data: { s3KeyList: obj[src].s3KeyList, fullSize: obj[src].fullSize }, type }
                        }
                    }
                } else if (type === 'indesign') {
                    // return as before
                    if (!resp.data.LastEvaluatedKey && !obj[src]) {
                        return { data: resp.data, type }
                    } else if ((!obj[src] && resp.data.LastEvaluatedKey) || (obj[src] && resp.data.LastEvaluatedKey)) {
                        // manage receiving tags
                        let tagsToObj = {};
                        if (obj[src]) {
                            const oldTags = obj[src].tagOrderedObj;
                            const newTags = resp.data.tagOrderedObj;
                            tagsToObj = { ...obj[src].tagOrderedObj };
                            for (const key in newTags) {
                                if (oldTags[key]) {
                                    let tagsArr = [...oldTags[key], ...newTags[key]];
                                    tagsArr = tagsArr.filter((value, index, self) => index === self.findIndex((t) => (t.name === value.name)))
                                    tagsToObj[key] = [...tagsArr];
                                } else {
                                    tagsToObj[key] = [...newTags[key]];
                                }
                            }
                        } else {
                            tagsToObj = { ...resp.data.tagOrderedObj };
                        }

                        // caso particolare con last evalueted key
                        if (obj[src] && obj[src].inddList) {
                            const respInddMap = new Map();
                            resp.data.inddList.forEach(el => respInddMap.set(el.reqid, el));

                            const objInddMap = new Map();
                            obj[src].inddList.forEach(el => objInddMap.set(el.reqid, el));

                            for (const [reqid, value] of objInddMap) {
                                if (respInddMap.has(reqid)) {
                                    objInddMap.set(reqid, {
                                        ...value,
                                        fonts: [...value.fonts, ...respInddMap.get(reqid).fonts],
                                        links: [...value.links, ...respInddMap.get(reqid).links],
                                        bucket: value.bucket ? value.bucket : respInddMap.get(reqid).bucket,
                                        filename: value.filename !== 'undefined' ? value.filename : respInddMap.get(reqid).filename,
                                        idml: value.idml ? value.idml : respInddMap.get(reqid).idml,
                                        indd: value.indd ? value.indd : respInddMap.get(reqid).indd,
                                        jpg: value.jpg ? value.jpg : respInddMap.get(reqid).jpg,
                                        keyName: value.keyName ? value.keyName : respInddMap.get(reqid).keyName,
                                        lastModified: value.lastModified ? value.lastModified : respInddMap.get(reqid).lastModified,
                                        linksCheckArr: value.linksCheckArr ? value.linksCheckArr : respInddMap.get(reqid).linksCheckArr,
                                        reqid: value.reqid ? value.reqid : respInddMap.get(reqid).reqid,
                                        size: value.size ? value.size : respInddMap.get(reqid).size,
                                        tags: value.tags ? value.tags : respInddMap.get(reqid).tags,
                                        unusedLinksArr: value.unusedLinksArr ? value.unusedLinksArr : respInddMap.get(reqid).unusedLinksArr,
                                    })

                                    // cancella reqid già processati
                                    respInddMap.delete(reqid);
                                }
                            }
                            for (const [reqid, value] of respInddMap) {
                                objInddMap.set(reqid, {
                                    ...value,
                                    fonts: [...value.fonts, ...respInddMap.get(reqid).fonts],
                                    links: [...value.links, ...respInddMap.get(reqid).links],
                                    bucket: value.bucket ? value.bucket : respInddMap.get(reqid).bucket,
                                    filename: value.filename !== 'undefined' ? value.filename : respInddMap.get(reqid).filename,
                                    idml: value.idml ? value.idml : respInddMap.get(reqid).idml,
                                    indd: value.indd ? value.indd : respInddMap.get(reqid).indd,
                                    jpg: value.jpg ? value.jpg : respInddMap.get(reqid).jpg,
                                    keyName: value.keyName ? value.keyName : respInddMap.get(reqid).keyName,
                                    lastModified: value.lastModified ? value.lastModified : respInddMap.get(reqid).lastModified,
                                    linksCheckArr: value.linksCheckArr ? value.linksCheckArr : respInddMap.get(reqid).linksCheckArr,
                                    reqid: value.reqid ? value.reqid : respInddMap.get(reqid).reqid,
                                    size: value.size ? value.size : respInddMap.get(reqid).size,
                                    tags: value.tags ? value.tags : respInddMap.get(reqid).tags,
                                    unusedLinksArr: value.unusedLinksArr ? value.unusedLinksArr : respInddMap.get(reqid).unusedLinksArr,
                                })
                            }

                            obj[src].inddList = [];
                            objInddMap.forEach((value, key, map) => obj[src].inddList.push(value))
                        }

                        obj[src] = {
                            ...obj[src],
                            fullSize: (obj[src] ? Number(obj[src].fullSize) : 0) + Number(resp.data.fullSize),
                            inddList: (obj[src] && obj[src].inddList) ? obj[src].inddList : [...resp.data.inddList],
                            tagOrderedObj: tagsToObj,
                            LastEvaluatedKey: resp.data.LastEvaluatedKey ? JSON.parse(resp.data.LastEvaluatedKey) : undefined,
                        }

                        i++;
                        // recusrive call
                        return fetchS3Objects1({ src, name, type, tags, sharedProjectId, obj, i });
                    } else {
                        let tagsToObj = {};
                        if (obj[src]) {
                            const oldTags = obj[src].tagOrderedObj;
                            const newTags = resp.data.tagOrderedObj;
                            tagsToObj = { ...obj[src].tagOrderedObj };
                            for (const key in newTags) {
                                if (oldTags[key]) {
                                    let tagsArr = [...oldTags[key], ...newTags[key]];
                                    tagsArr = tagsArr.filter((value, index, self) => index === self.findIndex((t) => (t.name === value.name)))
                                    tagsToObj[key] = [...tagsArr];
                                } else {
                                    tagsToObj[key] = [...newTags[key]];
                                }
                            }

                        } else {
                            tagsToObj = { ...resp.data.tagOrderedObj };
                        }

                        // caso particolare con last evalueted key
                        if (obj[src] && obj[src].inddList) {
                            const respInddMap = new Map();
                            resp.data.inddList.forEach(el => respInddMap.set(el.reqid, el));

                            const objInddMap = new Map();
                            obj[src].inddList.forEach(el => objInddMap.set(el.reqid, el));

                            for (const [reqid, value] of objInddMap) {
                                if (respInddMap.has(reqid)) {
                                    objInddMap.set(reqid, {
                                        ...value,
                                        fonts: [...value.fonts, ...respInddMap.get(reqid).fonts],
                                        links: [...value.links, ...respInddMap.get(reqid).links],
                                        bucket: value.bucket ? value.bucket : respInddMap.get(reqid).bucket,
                                        filename: value.filename !== 'undefined' ? value.filename : respInddMap.get(reqid).filename,
                                        idml: value.idml ? value.idml : respInddMap.get(reqid).idml,
                                        indd: value.indd ? value.indd : respInddMap.get(reqid).indd,
                                        jpg: value.jpg ? value.jpg : respInddMap.get(reqid).jpg,
                                        keyName: value.keyName ? value.keyName : respInddMap.get(reqid).keyName,
                                        lastModified: value.lastModified ? value.lastModified : respInddMap.get(reqid).lastModified,
                                        linksCheckArr: value.linksCheckArr ? value.linksCheckArr : respInddMap.get(reqid).linksCheckArr,
                                        reqid: value.reqid ? value.reqid : respInddMap.get(reqid).reqid,
                                        size: value.size ? value.size : respInddMap.get(reqid).size,
                                        tags: value.tags ? value.tags : respInddMap.get(reqid).tags,
                                        unusedLinksArr: value.unusedLinksArr ? value.unusedLinksArr : respInddMap.get(reqid).unusedLinksArr,
                                    })

                                    // cancella reqid già processati
                                    respInddMap.delete(reqid);
                                }
                            }

                            for (const [reqid, value] of respInddMap) {
                                objInddMap.set(reqid, {
                                    ...value,
                                    fonts: [...value.fonts, ...respInddMap.get(reqid).fonts],
                                    links: [...value.links, ...respInddMap.get(reqid).links],
                                    bucket: value.bucket ? value.bucket : respInddMap.get(reqid).bucket,
                                    filename: value.filename !== 'undefined' ? value.filename : respInddMap.get(reqid).filename,
                                    idml: value.idml ? value.idml : respInddMap.get(reqid).idml,
                                    indd: value.indd ? value.indd : respInddMap.get(reqid).indd,
                                    jpg: value.jpg ? value.jpg : respInddMap.get(reqid).jpg,
                                    keyName: value.keyName ? value.keyName : respInddMap.get(reqid).keyName,
                                    lastModified: value.lastModified ? value.lastModified : respInddMap.get(reqid).lastModified,
                                    linksCheckArr: value.linksCheckArr ? value.linksCheckArr : respInddMap.get(reqid).linksCheckArr,
                                    reqid: value.reqid ? value.reqid : respInddMap.get(reqid).reqid,
                                    size: value.size ? value.size : respInddMap.get(reqid).size,
                                    tags: value.tags ? value.tags : respInddMap.get(reqid).tags,
                                    unusedLinksArr: value.unusedLinksArr ? value.unusedLinksArr : respInddMap.get(reqid).unusedLinksArr,
                                })
                            }

                            obj[src].inddList = [];
                            objInddMap.forEach((value, key, map) => obj[src].inddList.push(value))
                        }

                        obj[src] = {
                            ...obj[src],
                            fullSize: Number(resp.data.fullSize),
                            inddList: obj[src].inddList ? obj[src].inddList : [...resp.data.inddList],
                            tagOrderedObj: tagsToObj,
                            LastEvaluatedKey: resp.data.LastEvaluatedKey ? JSON.parse(resp.data.LastEvaluatedKey) : undefined,
                        }

                        return { data: { ...obj[src] }, type }
                    }
                } else {
                    // return as before
                    return { data: resp.data, type }
                }
            })
            .catch(err => {
                return (err)
            });

    }

    const handleChangeCover = function ({ event, id, value }) {
        // console.log('before change', 'id ', id, 'value ', value, event, conf.selectedCovers);
        if (id >= 0 && assets.dataTables[id]) {
            const newConfSelected = [...conf.selectedCovers];
            if (value) {
                const indd = assets.dataTables[id].filter(indd => indd.reqid === value.reqid)[0];        // new standard unique name for uploaded file using webapp
                //console.log(indd)
                newConfSelected[id].value = indd.indd.Name.indexOf('/') === -1 ? indd.reqid + '/' + indd.indd.Name : indd.indd.Name;
                newConfSelected[id].folder = indd.indd.prefix;
                newConfSelected[id].filename = indd.filename;
                newConfSelected[id].fullPath = indd.indd.fullPath;
                newConfSelected[id].reqId = indd.reqid;
                newConfSelected[id].tagsValues = Object.values(indd.tags).join(' ');
                newConfSelected[id].indd = indd; // used for option comparison
                newConfSelected[id].jpg = indd.jpg; // 
            }
            else {
                newConfSelected[id].value = '';
                newConfSelected[id].indd = undefined;
                newConfSelected[id].tags = newConfSelected[id].tags;
                newConfSelected[id].tagsValues = '';
                newConfSelected[id].reqId = ''; // clear selection 
                newConfSelected[id].jpg = undefined

            }


            setConf({ ...conf, selectedCovers: newConfSelected });

        }
    }

    const handleChangeCheckOption = (idDoc, newValue) => {
        const newConfSelected = [...conf.selectedCovers];
        newConfSelected[idDoc].noCover = newValue;
        newConfSelected[idDoc].value = '';
        newConfSelected[idDoc].indd = undefined;
        newConfSelected[idDoc].fullPath = '';
        newConfSelected[idDoc].tags = newConfSelected[idDoc].tags;
        newConfSelected[idDoc].tagsValues = '';
        newConfSelected[idDoc].reqId = ''; // clear selection   
        newConfSelected[idDoc].jpg = undefined

        if (newValue) {
            newConfSelected[idDoc].value = 'ignore';
            newConfSelected[idDoc].filename = 'ignore';
            newConfSelected[idDoc].tagsValues = 'ignore';
        }
        //console.log(newConfSelected[idDoc])
        setConf({ ...conf, selectedCovers: newConfSelected });
    }

    const refreshPage = function () {
        setRefreshAsset(!refreshAsset)
    }
    // retrieve conf after selected project
    useEffect(() => {

        // console.log('use effect get folders');
        // setAssets({ ...assets, refreshing: true })
        const assetFolders = getAssetFoldersFromConf(conf, authAll).filter(src => src.type === 'indesign' && !src.automatic);
        //  setAssets({ ...assets, folders: assetFolders, conf: projectConf })
        // console.log('assetFolders: ', assetFolders);
        const promises = [];
        for (const src of assetFolders) {
            if (!src.automatic) {
                promises.push(fetchS3Objects1({
                    src: src.s3,
                    name: src.name,
                    type: 'indesign',
                    tags: src.tags,
                    sharedProjectId: src.sharedProjectId,
                }))
            }
        }

        Promise.all(promises)
            .then(folderResList => {
                const dataTables = folderResList.map((singleFolder, index) => {

                    const table = singleFolder.data.inddList
                        .filter(doc => doc.indd !== undefined)
                        .map(doc => {
                            if (doc.indd) {
                                doc.indd.prefix = assetFolders[index].name;
                                return doc
                            }
                        })

                    table.tagOrderedObj = singleFolder.data.tagOrderedObj;
                    return table
                })
                setAssets({ ...assets, folders: assetFolders, dataTables, conf, fetching: false, refreshing: false })


                // init value first of the list
                const newConfSelected = [...conf.selectedCovers];
                // console.log(conf.selectedCovers)
                // console.log('after refresh: ', conf.selectedCovers);
                // console.log(dataTables)
                for (let id = 0; id < dataTables.length; id++) {
                    const indd = dataTables[id][0];        // new standard unique name for uploaded file using webapp

                    if (indd && conf.selectedCovers[id] && conf.selectedCovers[id].value !== 'ignore') {
                        newConfSelected[id].value = indd.indd.Name.indexOf('/') === -1 ? indd.reqid + '/' + indd.indd.Name : indd.indd.Name;
                        newConfSelected[id].folder = indd.indd.prefix;
                        newConfSelected[id].filename = indd.filename;
                        newConfSelected[id].fullPath = indd.indd.fullPath;
                        newConfSelected[id].reqId = indd.reqid;
                        newConfSelected[id].tagsValues = Object.values(indd.tags).join(' ');
                        newConfSelected[id].indd = indd; // used for option comparison
                        newConfSelected[id].jpg = indd.jpg; // 
                    }

                    // set ignore if no idml uploaded
                    if(dataTables[id].tagOrderedObj[Object.keys(dataTables[id].tagOrderedObj)[0]].length === 0){
                        newConfSelected[id].value = 'ignore';
                        newConfSelected[id].filename = 'ignore';
                        newConfSelected[id].tagsValues = 'ignore';
                    }
                }
                setConf({ ...conf, selectedCovers: newConfSelected });


            })


    }, [refreshAsset]);



    return (
        <Box>
            <Box sx={{ marginTop: '2em' }} >
                {conf.selectedCovers.map((doc, id) => {
                    //if(!doc.tags) doc.tags = ['tag'];
                    // console.log(doc)
                    return (

                        <Stack key={doc.folder} direction='row' sx={{ height: '10em' }} spacing={10} alignItems='start'>
                            <Box sx={{ marginBottom: '1em' }}>
                                <Box mb='1em'>
                                    <b>{doc.folder}</b>
                                </Box>
                                <Box>
                                    {assets.fetching && <Skeleton variant="rectangular" animation="wave" height={40} sx={{ width: 400 }} />}
                                    {!assets.fetching && (
                                        <Stack direction='row' spacing={2}>
                                            {!doc.required && assets.dataTables[id] && assets.dataTables[id].tagOrderedObj &&
                                                <Switch
                                                    disabled={assets.dataTables[id].tagOrderedObj[Object.keys(assets.dataTables[id].tagOrderedObj)[0]].length === 0}
                                                    checked={!doc.noCover && assets.dataTables[id].tagOrderedObj[Object.keys(assets.dataTables[id].tagOrderedObj)[0]].length !== 0}
                                                    defaultChecked
                                                    onChange={(e) => handleChangeCheckOption(id, !e.target.checked)}
                                                />
                                            }

                                            <Autocomplete id="tag-selector"
                                                disablePortal
                                                disabled={doc.noCover || assets.dataTables[id].tagOrderedObj[Object.keys(assets.dataTables[id].tagOrderedObj)[0]].length === 0}
                                                options={assets.dataTables[id]}
                                                sx={{ width: 'fit-content', minWidth: 300 }}
                                                clearOnEscape
                                                //disableCloseOnSelect
                                                //defaultValue={{ reqid: '' }}
                                                value={doc.indd || { reqid: '' }}
                                                onChange={(event, value) => handleChangeCover({ event, id, value })}
                                                size='small'
                                                isOptionEqualToValue={(option, value) => option.reqid === value.reqid}
                                                getOptionLabel={(option) => {
                                                    return option.tags ? Object.values(option.tags).join(' ') : ''
                                                }}
                                                // autoHighlight
                                                // onHighlightChange={(event, option)=>handleHighlightChange({option, id})}
                                                renderInput={(params) => <TextField {...params} placeholder={doc.tags ? doc.tags.join(' ') : undefined} />}
                                            />
                                            <IconButton onClick={refreshPage} > <Refresh /> </IconButton>

                                        </Stack>
                                    )}
                                </Box>
                            </Box>
                            {!doc.noCover && assets.dataTables[id] && assets.dataTables[id].tagOrderedObj && assets.dataTables[id].tagOrderedObj[Object.keys(assets.dataTables[id].tagOrderedObj)[0]].length > 0 && (
                                <CoverPreview jpg={doc.jpg} isFetching={assets.fetching} />
                            )}
                        </Stack>
                    )

                })}
            </Box>

        </Box >

    )
}



function CoverPreview({ jpg, isFetching }) {
    const [loaded, setLoaded] = useState(false);
    function onLoad() {
        console.log('loaded');
        setLoaded(true);
    }

    useEffect(() => {
        if (loaded) setLoaded(false);
    }, [jpg])


    if (isFetching) return <Skeleton variant="rectangular" animation="wave" height={150} sx={{ width: 100 }} />
    else if (jpg) return (
        <Box sx={{ height: '100%' }}>
            <Paper sx={{ height: '90%', display: loaded ? 'block' : 'none', }}>
                <img
                    style={{ height: '100%' }}
                    onLoad={onLoad}
                    src={jpg}
                    alt={''}
                />
            </Paper>

            {!loaded && <CircularProgress variant='indeterminate' size={25} sx={{ mt: '2.8em', ml: '2.5em' }} />}
        </Box>
    )
    else return null
}