import Box from '@mui/material/Box';
import { useEffect, useState, } from 'react';
import Primarytheme from './theme';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Fab from '@mui/material/Fab';
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';
import AssetSingleIndesignBlock from './AssetSingleIndesignBlock';
import AssetUploadNewIndd from './AssetUploadNewIndd';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const { v4: uuidv4 } = require('uuid');

const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.15),
    marginLeft: 0, marginRight: theme.spacing(0.4),
}));

const ChipListItem = ({ tagKey, label, variantBool, id, handleClick }) => (
    <ListItem>
        <Chip label={label}
            variant={variantBool ? 'filled' : 'outlined'}
            color='primary' size="small"
            onClick={() => handleClick({ tagKey: tagKey, id: id })}
            sx={{ mr: '0.5em', border: "1px solid", borderColor: Primarytheme.palette.primary.main }} />
    </ListItem>
)

const showLimitMin = 12;

export default function AssetInDesign({ auth, conf, dataTable, tagsKeys, format = { ext: 'idml', version: 'CC 2020' }, folderName, folderPath, handleRefreshTable, checkLinks, inDashboard = false }) {


    //console.log('ind data table', dataTable.tagOrderedObj)
    const [uploadOpen, setUploadOpen] = useState({ isOpen: false, reqIdDoc: '' });
    const [drawerOpen, setDrawerOpen] = useState(false)

    const [showLimit, setShowLimit] = useState(showLimitMin);

    const [tagFilterObj, setTagFilterObj] = useState(dataTable.tagOrderedObj)
    const [allTagFilterObj, setAllTagFilterObj] = useState(tagsKeys.reduce((acc, curr) => (acc[curr] = true, acc), {}))


    const handleChangeLimit = (sign) => {
        if (sign >= 0) setShowLimit(showLimit => showLimit + 6)
        else setShowLimit(showLimit => Math.max(showLimitMin, showLimit - 6))
    }

    const handleClickFilterChip = ({ tagKey, id, tagValue }) => {
        const newFilterObj = { ...tagFilterObj };
        const newAllFilterObj = { ...allTagFilterObj };
        if (id === 'all') {
            newAllFilterObj[tagKey] = !newAllFilterObj[tagKey];
            // clean all other tags if all selected
            newFilterObj[tagKey] = newFilterObj[tagKey].map(el => {
                return { ...el, active: false }
            })
        }
        else if (id >= 0) {
            // select only specific tag
            newFilterObj[tagKey][id].active = !newFilterObj[tagKey][id].active;
            if (newFilterObj[tagKey][id].active) newAllFilterObj[tagKey] = false;
        } else if (tagValue) {
            // select tag from card
            newFilterObj[tagKey] = newFilterObj[tagKey].map(el => {
                if (el.name === tagValue) {
                    if (!el.active) newAllFilterObj[tagKey] = false;
                    return { ...el, active: !el.active }
                }
                else return el
            })

        }

        // check if all tags are not active
        const hasTrue = newFilterObj[tagKey].some(obj => obj.active === true);
        if(!hasTrue){
            // se non ci sono true allora deve attivarsi il tag all
            newAllFilterObj[tagKey] = true
        }

        //console.log('change all tag', newAllFilterObj)
        setShowLimit(showLimitMin); // reset limit min
        setTagFilterObj(newFilterObj);
        setAllTagFilterObj(newAllFilterObj)
    }

    const handleUploadDialogOpen = () => {
        setUploadOpen({ ...uploadOpen, isOpen: true, reqIdDoc: uuidv4() })
    }

    const handleUploadDialogClose = (event, reason) => {
        if (reason && reason == "backdropClick") return;
        setUploadOpen({ ...uploadOpen, isOpen: false, reqIdDoc: '' })
    }

    useEffect(() => {

        //const filteroBj = reduceTagObj({ tagsKeys, dataTable })
        const allFilterObj = tagsKeys.reduce((acc, curr) => (acc[curr] = true, acc), {});

        // console.log(dataTable.tagOrderedObj)
        setTagFilterObj(dataTable.tagOrderedObj);
        setAllTagFilterObj(allFilterObj)
    }, [dataTable]);


    const filtereDocList = dataTable.filter(document => {
        if (!inDashboard) {
            const hasAllTags = [];
            for (const tagKey in document.tags) {
                if (allTagFilterObj[tagKey] || (tagFilterObj[tagKey] && tagFilterObj[tagKey].filter(elPair => elPair.active).filter(elPair => elPair.name === document.tags[tagKey]).length > 0)) {
                    hasAllTags.push(true);
                    //break;
                }
            }
            return hasAllTags.filter(el => el === true).length === Object.keys(document.tags).length;// flag_HasSomeTags
        }
        else return true
    })


    return (
        <Box mb='2em'>
            <Stack direction='row' spacing={2} alignItems='center' style={{ position: 'relative', zIndex: '0' }}>
                <Tooltip title='Configure and upload a new InDesign cover'>
                    <Fab aria-label="add" color="secondary" variant={inDashboard ? null : "extended"} onClick={handleUploadDialogOpen}
                        sx={{ ml: '0.5em', mb: '2em' }}
                        style={inDashboard ? {
                            backgroundColor: "white",
                            color: Primarytheme.palette.primary.main,
                            boxShadow: 'none',
                            border: '1px solid',
                            //borderColor: Primarytheme.palette.primary.main,
                            "&:hover": {
                                backgroundColor: Primarytheme.palette.primary.main,
                                color: "white"
                            }
                        } : {}}>
                        {inDashboard ? <AddIcon /> : <Stack direction='row' spacing={1} alignItems='center'><AddIcon /><b>ADD NEW</b></Stack>}
                    </Fab>
                </Tooltip>
                {uploadOpen.isOpen && <AssetUploadNewIndd {...{ uploadOpen, folderName, tagsKeys, tagOrderedObj: dataTable.tagOrderedObj, format, auth, bucket: conf.bucket, project_id: conf.project_id, folderPath, checkLinks, handleUploadDialogClose, handleRefreshTable }} />}
            </Stack>

            {!inDashboard && (
                <Box>
                    <Accordion expanded={drawerOpen} onChange={() => setDrawerOpen(!drawerOpen)} elevation={1}
                        sx={{
                            "&.Mui-expanded": { minHeight: 0 },
                            "& .MuiAccordionSummary-content.Mui-expanded": {
                                // margin from https://github.com/mui-org/material-ui/blob/cc0e2ab63e8be9ec4d51a49bfde17ef28fc77b9c/packages/mui-material/src/AccordionSummary/AccordionSummary.js#L64-L64
                                margin: "12px 0"
                            }
                        }}
                        disableGutters>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel1bh-header">
                            <b>Filter Tags</b>
                        </AccordionSummary>
                        <AccordionDetails sx={{ pb: '1em' }}>
                            <Stack direction='column' spacing={1}>
                                {tagsKeys.map(tagKey => {
                                    if (tagFilterObj[tagKey] && tagFilterObj[tagKey].length > 0) {
                                        return (
                                            <Stack key={tagKey} direction='row' spacing={1} alignItems='center' >
                                                <Typography variant='body' mr='2em' sx={{ minWidth: '4em' }} >{tagKey}</Typography>
                                                <Box component='ul' sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', flexWrap: 'wrap', listStyle: 'none', p: 0, m: 0 }}>
                                                    <ChipListItem tagKey={tagKey} label='all' variantBool={allTagFilterObj[tagKey]} id='all' handleClick={handleClickFilterChip} />
                                                    {tagFilterObj[tagKey].map((tagPair, id) =>
                                                        <ChipListItem key={tagPair.name} tagKey={tagKey} label={tagPair.name} variantBool={tagFilterObj[tagKey][id].active} id={id} handleClick={handleClickFilterChip} />
                                                    )}
                                                </Box>
                                            </Stack>
                                        )
                                    }
                                    else return null
                                })}
                            </Stack>
                        </AccordionDetails>
                    </Accordion>





                    <Box component='ul' sx={{ display: 'flex', justifyContent: 'left', flexWrap: 'wrap', listStyle: 'none', p: 0, m: 0 }}>
                        {filtereDocList.slice(0, showLimit).map(document => {
                            if (!document) return null
                            else return <AssetSingleIndesignBlock key={document.indd.Name + document.reqid}
                                document={document}
                                auth={auth}
                                tagsKeys={tagsKeys}
                                handleClickFilterChip={handleClickFilterChip}
                                handleRefreshTable={handleRefreshTable}
                                folderPath={folderPath}
                                folderName={folderName}
                                tagOrderedObj={dataTable.tagOrderedObj}
                                format={format}
                                bucket={conf.bucket}
                                project_id={conf.project_id}
                                checkLinks={checkLinks}
                            />
                        })}
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: '2em' }} >
                        <Stack direction='row' >
                            <Tooltip title="Show less">
                                <span>
                                    <IconButton disabled={showLimit <= showLimitMin} onClick={() => handleChangeLimit(-1)}>
                                        <KeyboardArrowUpIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <Tooltip title="Show more">
                                <span>
                                    <IconButton disabled={!(filtereDocList.length > showLimit)} onClick={() => handleChangeLimit(1)}>
                                        <KeyboardArrowDownIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </Stack>
                    </Box>
                </Box>


            )}
        </Box >

    )
}


