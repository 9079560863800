
import React, { useState, } from "react";

import Primarytheme from './theme';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';


import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Divider from '@mui/material/Divider';


export default function AttentionBlock({ attentions = [], buttonLabel, title, notes, textAlign = 'center', color = 'neutral', size = 'medium', type = 'button', iconBBkg }) {

    const [dialogOpen, setDialogOpen] = useState(false);

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const printText = (text) => {
        if (text && typeof text === 'string' && text.split('\r\n').length > 1) {
            return (text.split('\r\n')
                .map((t, index) => (
                    <React.Fragment key={`${t}-${index}`}>
                        {t}
                        <br />
                    </React.Fragment>)))
        } else {
            return text
        }
    }

    return (
        <Box sx={{ textAlign: { textAlign }, mb: '1em' }}>
            {type == 'button' &&
                <Button variant='contained' component={'label'} size={size} color={color} onClick={handleDialogOpen}
                    endIcon={<WarningAmberIcon />} sx={{ mt: '0.5em', mb: '0.5em' }}
                >
                    {buttonLabel}
                </Button>
            }
            {type == 'iconButton' &&
                <IconButton size={size} color={iconBBkg ? 'white' : color} onClick={handleDialogOpen} sx={iconBBkg ? { backgroundColor: iconBBkg.main, "&:hover": { backgroundColor: iconBBkg.dark } } : ''}>
                    <WarningAmberIcon fontSize="inherit" />
                </IconButton >
            }
            <Dialog
                //fullWidth={true}
                maxWidth={'l'}
                open={dialogOpen}
                onClose={handleDialogClose}
            >
                <DialogTitle>{title}</DialogTitle>
                {attentions.map(err => (
                    <Box key={err.name}>
                        <DialogContent >
                            {err.name.split('<br>').map(el => <DialogContentText key={el}>{el}  </DialogContentText>)}
                            <TableContainer component={Box} mt='0.5em' >
                                <Table size="small" >
                                    <TableHead>
                                        <TableRow>
                                            {err.header.map(h => <TableCell sx={{ border: 0 }} component='th' key={h}><b>{h}</b></TableCell >)}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {err.data.map((row, id) => (
                                            <TableRow key={id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                                {row.map((cell, id) => <TableCell sx={{ border: 0 }} key={id} >
                                                    {printText(cell)}
                                                </TableCell >)}
                                            </TableRow>)
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </DialogContent>
                        <Divider variant="middle" color='gray' />
                    </Box>
                ))}
                {notes && (
                    <DialogContent >
                        <DialogContentText>{notes}</DialogContentText>
                    </DialogContent>
                )}

                <DialogActions>
                    <Button onClick={handleDialogClose} component={'label'}>Close</Button>
                </DialogActions>
            </Dialog>

        </Box>
    )


}