import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';

export default function Picker({ nome, dataStato, handleDateChange }) {
    return (

        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                format="dd/MM/yyyy"
                //OpenPickerButtonProps={{component: 'label'}}
                label={nome}
                value={dataStato}
                onChange={(date) => handleDateChange(date, 0)}
                renderInput={(params) => <TextField {...params} sx={{ width: 160 }} />}
            />

        </LocalizationProvider>



    )
}

