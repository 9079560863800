import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Auth } from "aws-amplify";

import Button from '@mui/material/Button';

import Validate from "../utility/FormValidation";
import { Box, Paper, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';

import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import queryString from 'query-string';

import logo from './../../logos/Logo_Cerchio_1000px.svg'

export default function ForgotPasswordVerification() {
    const history = useHistory();
    const redirect = (dst) => {
        history.push(dst);
    }

    const [credentialsForm, setCredentialsForm] = useState({
        params: queryString.parse(useLocation().search),
        password: '',
        confirmPassword: "",
        showPassword: false,
        errors: {
            password: "",
            confirmPassword: "",
            cognito: ""
        },
    });


    const onInputChange = event => {
        const error = Validate({ ...credentialsForm, [event.target.id]: event.target.value });
        // console.log(error)
        setCredentialsForm({
            ...credentialsForm,
            [event.target.id]: event.target.value,
            errors: { ...credentialsForm.errors, ...error, cognito: null }
        });
    }


    const handleSubmit = async event => {
        event.preventDefault();

        Auth.forgotPasswordSubmit(
            credentialsForm.params.email,
            credentialsForm.params.code,
            credentialsForm.password)
            .then(() => redirect('/forgotpasswordconfirm'))
            .catch(error => {
                setCredentialsForm({
                    ...credentialsForm,
                    errors: {
                        ...credentialsForm.errors,
                        cognito: error.message || 'Unexpect error during authentication'
                    }
                });

            })
    };


    const handleClickShowPassword = () => {
        setCredentialsForm({
            ...credentialsForm,
            showPassword: !credentialsForm.showPassword,
        });
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    if (credentialsForm.params.email && credentialsForm.params.code) {
        return (

            <Paper sx={{ width: '60%', m: 'auto', marginTop: '10%', paddingTop: '2em' }} elevation={3}>
                <Stack
                    component="form"
                    noValidate
                    autoComplete="new-password"
                    direction='column'
                    alignItems='center'
                >

                    <img src={logo} height="100" alt="pagination logo" />

                    <Typography sx={{ m: '1em', marginBottom: '0.5em', marginTop: '2em' }}>
                        Please set a new password
                    </Typography>
                    <Typography variant='caption' sx={{ m: '1em', marginBottom: '2em', }}>
                        Password must have at least 8 chars, upper and lower case, a number.
                    </Typography>

                    <TextField
                        label="email"
                        id="email"
                        disabled
                        defaultValue={credentialsForm.params.email}
                        sx={{ m: 1, width: '80%', maxWidth: '30em' }}
                    />



                    <FormControl sx={{ m: 1, width: '80%', maxWidth: '30em' }} variant="outlined">
                        <InputLabel error={credentialsForm.errors.password !== ""}>
                            Password
                        </InputLabel>
                        <OutlinedInput
                            id="password"
                            label="password"
                            type={credentialsForm.showPassword ? 'text' : 'password'}
                            value={credentialsForm.password}
                            onChange={onInputChange}
                            error={credentialsForm.errors.password !== ""}
                            autoComplete="new-password"
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {credentialsForm.showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        {credentialsForm.errors.password !== "" &&
                            <FormHelperText
                                error={true}
                                id="pass-err">
                                {credentialsForm.errors.password}
                            </FormHelperText>
                        }
                    </FormControl>


                    <FormControl sx={{ m: 1, width: '80%', maxWidth: '30em' }} variant="outlined">
                        <InputLabel error={credentialsForm.errors.confirmPassword !== ""}>
                            Confirm password
                        </InputLabel>
                        <OutlinedInput
                            id="confirmPassword"
                            label="Confirm password"
                            type={credentialsForm.showPassword ? 'text' : 'password'}
                            value={credentialsForm.confirmPassword}
                            onChange={onInputChange}
                            error={credentialsForm.errors.confirmPassword !== ""}
                            autoComplete="new-password"
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {credentialsForm.showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        {credentialsForm.errors.confirmPassword !== "" &&
                            <FormHelperText
                                error={true}
                                id="pass-err">
                                {credentialsForm.errors.confirmPassword}
                            </FormHelperText>
                        }
                    </FormControl>


                    {credentialsForm.errors.cognito !== "" &&
                        <Typography variant='caption' color='error' sx={{ marginBottom: '1em' }}>
                            {credentialsForm.errors.cognito}
                        </Typography>
                    }


                    <Button color="primary" variant='contained' sx={{ margin: '2em' }}
                        onClick={handleSubmit}
                        disabled={(credentialsForm.password === '' || credentialsForm.confirmPassword === '') || (credentialsForm.errors.password !== '' || credentialsForm.errors.confirmPassword !== '')}
                    >
                        Submit
                    </Button>


                </Stack >
            </Paper>

        )
    } else {
        return null;
    }

}
