import { useState, useEffect } from 'react';

import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import Stack from '@mui/material/Stack'

export default function FilterSourceData({ label, originQuery, handleChange, ...props }) {

  const [origin, setOrigin] = useState(originQuery);

  const handleStateChange = (event) => {
    setOrigin(event.target.value);
  }


  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      console.log('originquery', origin, originQuery)
      if (origin !== originQuery) handleChange(origin)
      // Send Axios request here
    }, 600)

    return () => clearTimeout(delayDebounceFn)
  }, [origin])

  return (
    <TextField
      label={
        <Stack direction='row' alignItems='flex-start' spacing={1}>
          <SearchIcon />
          <p>{label}</p>
        </Stack>
      }
      id="origin"
      value={origin}
      onChange={handleStateChange}
      sx={{width: '20%'}}

    />
  )

}