import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

export default function RecapLaunch({ launch, conf }) {

    return (
        <Box>

            <ul>
                <Typography variant='body2' color='textSecondary' sx={{ marginBottom: '1em' }}>
                    <Typography key='1' component='li' variant='body2' color='textSecondary'>

                        <b>Project</b>: {launch.selectedProject}
                    </Typography>
                    {launch.tag && (
                        <Typography key='flow' component='li' variant='body2' color='textSecondary'>
                            <b>Process</b>: {launch.tag}
                        </Typography>
                    )}
                    <Typography key='dataset' component='li' variant='body2' color='textSecondary' sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                        <b>Dataset</b>: {launch.filename}
                    </Typography>
                    {launch.steps.filter(el => el.label === 'Additional data file').length > 0 && conf.selectedAdditionalData.map((file, id) => (
                        <Typography key={'additional file' + id} component='li' variant='body2' color='textSecondary'>
                            <b>{file.folder}</b>: {file.value}
                        </Typography>
                    ))}
                    {launch.steps.filter(el => el.label === 'Static Pages').length > 0 && conf.selectedCovers.map((cover, id) => (
                        <Typography key={'Static pages' + id} component='li' variant='body2' color='textSecondary'>
                            <b>{cover.folder}</b>: {cover.tagsValues}
                        </Typography>
                    ))}
                    {launch.steps.filter(el => el.label === 'Visuals').length > 0 && conf.selectedAssets.map((file, id) => (
                        <Typography key={'file' + id} component='li' variant='body2' color='textSecondary'>
                            <b>{file.folder}</b>: {file.value}
                        </Typography>
                    ))}
                    {launch.steps.filter(el => el.label === 'Options').length > 0 && conf.selectedPaginationOptions.map((opt, id) => {
                        if (opt.component === 'form-list') {
                            return (
                                <Typography key={'options' + id} component='li' variant='body2' color='textSecondary'>
                                    <b>{opt.nome}</b>: {opt.list.map(block => block.map(single => `${single.nome}: ${single.value}`).join(', ')).join(' - ')}
                                </Typography>
                            )

                        }
                        else return (
                            <Typography key={'options' + id} component='li' variant='body2' color='textSecondary'>
                                <b>{opt.nome}</b>: {opt.value !== undefined ? `${opt.value}` : `${opt.default}`}
                            </Typography>
                        )
                    }

                    )}
                    {conf.internalTest && (
                        <Typography key='only-admin' component='li' variant='body2' color='red'>
                            <b>internal test</b>: yes
                        </Typography>
                    )}
                    {conf.doOnlyValidation && (
                        <Typography key='only-validation' component='li' variant='body2' color='blue'>
                            <b>data integration only</b>: yes
                        </Typography>
                    )}


                </Typography>
            </ul>




            {launch.filename.length > 50 &&
                <Box width={'100%'}>
                    <Alert elevation={0} severity="warning" sx={{ marginTop: '1em', display: 'inline-flex' }}>Datafile's original name will be truncated at 50 characters for exported files.</Alert>
                </Box>
            }
            {(conf.assetFolder.length > 0 || conf.indesignFolder.length > 0 || conf.additionalDataFolder.length > 0) &&
                <Box width={'100%'}>
                    <Alert elevation={0} severity="info" sx={{ marginTop: '1em', display: 'inline-flex' }}>Remember to upload all assets before launch!</Alert>
                </Box>
            }
            {conf.queue === 'requests_vpc_test' && (
                <Box width={'100%'}>
                    <Alert elevation={0} severity="warning" sx={{ marginTop: '1em', display: 'inline-flex' }}>
                        This process will run in a test enviroment! The conf set up the queue "requests_vpc_test".<br></br>
                        indesign_version: {conf.indesign_version}
                    </Alert>
                </Box>)
            }



        </Box>

    )
}