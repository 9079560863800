import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Primarytheme from './utility/theme';

import { styled } from '@mui/material/styles';

import History from './History';
import Launch from './utility/Launch';

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';


import IconButton from '@mui/material/IconButton';
import Refresh from '@mui/icons-material/Refresh'
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import Tooltip from "@mui/material/Tooltip";

const breaksLaunch = { xs: 12, sm: 12, md: 10, lg: 10, xl: 8 };
const breaksHistory = breaksLaunch;

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(2),
    margin: 0, //'1em',
    textAlign: 'left',
    //color: Primarytheme.palette.primary.main,
    fontSize: '16px',
    //fontWeight: 'bold',
    background: 'white',
    boxShadow: '0px 0px 0px 0px, 1px 1px 5px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%)',
    //border: '100px',
    //variant: 'outlined',
    borderRadius: '10px'
}));


export default function Dashboard({ auth }) {

    const [parentForceRefresh, setParentForceRefresh] = useState(0);

    const history = useHistory();
    const redirect = (dst) => {
        history.push(dst);
    }

    useEffect(() => {
        setParentForceRefresh(parentForceRefresh => parentForceRefresh + 1)
    }, [auth.userState.attached_projects]);


    return (
        <Box m='2em' mt='4em'>
            <Grid container justifyContent="center" spacing={2} >
                <Grid item id='launch' {...breaksLaunch} >
                    <Item key='Stepper' sx={{ width: '100%', }}>
                        <Launch auth={auth} setParentForceRefresh={setParentForceRefresh} />
                    </Item>
                </Grid>

                <Grid item id='requests' {...breaksHistory}>
                    <Item key='Requests' sx={{ width: '100%', }}>
                        <Stack direction='row' justifyContent="space-between" >
                            <Typography variant='h5' color={Primarytheme.palette.primary.main} >
                                <b>Latest Requests</b>
                            </Typography>
                            <Box >
                                <Tooltip title="Open full history in new tab">
                                    <IconButton onClick={() => window.open("/history", "_blank")} > <OpenInNewIcon /> </IconButton>
                                </Tooltip>
                                <IconButton onClick={() => setParentForceRefresh(parentForceRefresh => parentForceRefresh + 1)} > <Refresh /> </IconButton>
                            </Box>
                        </Stack>

                        <History auth={auth} parentForceRefresh={parentForceRefresh} itemsToShow={4} inDashBoard={true} />
                    </Item>
                </Grid>

            </Grid >
        </Box >


    )


}
