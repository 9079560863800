
import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink} from 'react-router-dom';

import { ListItem, ListItemText, ListItemIcon } from '@mui/material';



function ListItemLink(props) {
    const { icon, primary, to } = props;
    
    const renderLink = React.useMemo(
        () =>
            React.forwardRef(function Link(itemProps, ref) {
                return <RouterLink to={to} ref={ref} {...itemProps} role={undefined} />;
            }),
        [to],
    );

    return (

        <ListItem button component={renderLink}>
            {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
            <ListItemText primary={primary} />
        </ListItem>

    );
}

ListItemLink.propTypes = {
    icon: PropTypes.element,
    primary: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
};

export default ListItemLink