import Auth from '@aws-amplify/auth';
import React, { useEffect, useState, } from 'react';
import { NavLink as RouterLink, useLocation, useHistory } from 'react-router-dom';
import Primarytheme from './theme';
import Typography from '@mui/material/Typography';

import ListItemLink from './ListItemLink';
import Link from '@mui/material/Link';

import AppBar from '@mui/material/AppBar';
import { IconButton, ListItem, ButtonBase, Button } from '@mui/material';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Popover from '@mui/material/Popover';

import AccountInfo from './AccountInfo';
import AlertDialog from './AlertDialog';
import AttentionBlock from './AttentionBlock';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Divider from '@mui/material/Divider';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import BuildIcon from '@mui/icons-material/Build';
import CelebrationIcon from '@mui/icons-material/Celebration';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';


//import logo from './../../logos/Logo_white.svg'
import logo from './../../logos/soloNuvola_bianco.svg'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import InfoIcon from '@mui/icons-material/Info';

import release from './../../release-note.json';

import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import Collapse from '@mui/material/Collapse';
import WarningIcon from '@mui/icons-material/Warning';

const releaseIconMap = {
  'enhancement': <AutoAwesomeIcon color='warning' />,
  'bug': <BuildIcon color='neutral' />,
  'feature': <CelebrationIcon color='secondary' />,
}


export default function Navbar({ auth }) {

  let pathIdTab = {
    "/dashboard": 0,
    "/dashboard/": 0,
    "/history": 1,
    "/history/": 1,
    "/assets": 2,
    "/assets/": 2,
    "/traffic": 3,
    "/traffic/": 3,
    "/administration": 4,
    "/administration/": 4,
    "/charts": 5,
    "/charts/": 5,
    "/billing": 6,
    "/billing/": 6,
    /* "/tools": 6,
    "/tools/": 6, */

    "/": 0,
    "/login": 0,
    "/firstlogin": 0,
    default: false,
  }

  if (!auth.userState.isAdmin) {
    if (auth.userState.policy && !auth.userState.policy.canManageAsset) {
      pathIdTab = {
        "/dashboard": 0,
        "/dashboard/": 0,
        "/history": 1,
        "/history/": 1,

        "/": 0,
        "/login": 0,
        "/firstlogin": 0,
        default: false,
      }
    } else {
      pathIdTab = {
        "/dashboard": 0,
        "/dashboard/": 0,
        "/history": 1,
        "/history/": 1,
        "/assets": 2,
        "/assets/": 2,

        "/": 0,
        "/login": 0,
        "/firstlogin": 0,
        default: false,
      }
    }
  }
  if (!auth.userState.isAdmin && auth.userState.type === 'billing') {
    if (auth.userState.policy && !auth.userState.policy.canManageAsset) {
      pathIdTab = {
        "/dashboard": 0,
        "/dashboard/": 0,
        "/history": 1,
        "/history/": 1,
        "/billing": 2,
        "/billing/": 2,

        "/": 0,
        "/login": 0,
        "/firstlogin": 0,
        default: false,
      }
    } else {
      pathIdTab = {
        "/dashboard": 0,
        "/dashboard/": 0,
        "/history": 1,
        "/history/": 1,
        "/assets": 2,
        "/assets/": 2,
        "/billing": 3,
        "/billing/": 3,

        "/": 0,
        "/login": 0,
        "/firstlogin": 0,
        default: false,
      }
    }
  }


  const location = useLocation();

  const [value, setValue] = useState(location.pathname ? pathIdTab[location.pathname.toLowerCase()] : pathIdTab.default);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorInfoEl, setAnchorInfoEl] = useState(null);

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleReleaseNote = () => {
    if (!dialogOpen) handleDialogOpen();
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleInfoClick = (event) => {
    setAnchorInfoEl(event.currentTarget);
  };

  const handleInfoClose = () => {
    setAnchorInfoEl(null);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleLogOut = () => {
    try {
      Auth.signOut();
      handleClose();
      auth.setUserState({
        ...auth.userState,
        isAuthenticated: false,
        user: null,
        userItem: null,
      })
    } catch (error) {
      console.log(error.message);
    }
  }

  useEffect(() => {
    setValue(pathIdTab[location.pathname.toLowerCase()]);
    //console.log('effett change auth', location.pathname)
  }, [auth.userState.isAuthenticated, auth.userState.isAdmin]);

  useEffect(() => {
    setValue(pathIdTab[location.pathname.toLowerCase()])
    //console.log('effett change location',location.pathname )
  }, [location]);

  const history = useHistory();
  const redirect = (dst) => {
    history.push(dst);
  }

  const openPop = Boolean(anchorEl);
  const openInfoPop = Boolean(anchorInfoEl);

  //////// ALERT
  const [open, setOpen] = React.useState(true);

  return (
    <Box>
      <AppBar position="fixed"  >

        <Stack direction='row'
          justifyContent="space-between"
          alignItems="center"
          sx={{ padding: 0, margin: 0, marginLeft: '1em', marginRight: '1em', height: '3em' }}
        >

          <Stack direction='row' justifyContent='flex-start' alignItems="center" sx={{ padding: 0, margin: 0 }}>
            <Button variant='text' onClick={() => redirect('/dashboard')} sx={{ color: Primarytheme.palette.primary.main, p: 0 }}>
              <img src={logo} height="30" alt="pagination logo" />
            </Button>

            {(auth.userState.isAuthenticated &&
              <Tabs value={value} onChange={handleChange} TabIndicatorProps={{ sx: { background: 'white' } }} textColor='inherit' >
                <Tab LinkComponent={RouterLink} to='/dashboard' label="pagination" />
                <Tab LinkComponent={RouterLink} to='/history' label="history" />
                {auth.userState.policy.canManageAsset && <Tab LinkComponent={RouterLink} to='/assets' label="asset management" />}
                {auth.userState.isAdmin && <Tab LinkComponent={RouterLink} to='/traffic' label="traffic" />}
                {auth.userState.isAdmin && <Tab LinkComponent={RouterLink} to='/administration' label="administration" />}
                {auth.userState.isAdmin && <Tab LinkComponent={RouterLink} to='/charts' label="charts" />}
                {(auth.userState.type === 'billing' || auth.userState.isAdmin) && <Tab LinkComponent={RouterLink} to='/billing' label="billing" />}
                {/* {auth.userState.isAdmin && <Tab LinkComponent={RouterLink} to='/tools' label="tools" />} */}
              </Tabs>
            )}
          </Stack>

          {(auth.userState.isAuthenticated &&
            <Stack direction='row' alignItems="center">
              {(auth.userState.custom_message &&
                <Box >
                  <IconButton aria-describedby='infoIcon'
                    onClick={() => {
                      setOpen(true);
                    }}>
                    < WarningIcon htmlColor="white" />
                  </IconButton>
                </Box>
              )}
              <Box >
                <List disablePadding>
                  <ListItem id='release-list' disablePadding >
                    <ListItemButton id='release-button' primary={'v. ' + release.release[0].version + 'button'} variant="contained" onClick={handleReleaseNote}>
                      <ListItemText id='release-title' primary={'v. ' + release.release[0].version} />
                    </ListItemButton>
                  </ListItem>
                </List>
                <Dialog
                  id='release-dialog'
                  //fullWidth={true}
                  maxWidth={'md'}
                  open={dialogOpen}
                  onClose={handleDialogClose}
                  fullWidth={true}
                >

                  {release.release.map(objVersion =>

                    <Box key={objVersion.version + 'box'}>
                      <DialogContent key={objVersion.version + 'dialog'}>
                        <Typography variant='h5' color='primary' sx={{}}>
                          <b>{objVersion.version}</b>
                        </Typography>
                        <Typography variant='caption' color='neutral' sx={{ marginBottom: '0.5em' }}>
                          {objVersion.date}
                        </Typography>

                        <Box key={objVersion.version + 'subbox'}>
                          <TableContainer key={objVersion.version + 'tableCont'} component={Box} mt='0.5em' >
                            <Table key={objVersion.version + 'table'} size="small" >
                              <TableBody key={objVersion.version + 'tablebody'}>
                                {objVersion.note.map(objNote => (
                                  <TableRow key={objNote.title + 'row'} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                    <TableCell key={objNote.title + 'cell'} width='1%' sx={{ border: 0 }}>{releaseIconMap[objNote.type.toLowerCase()]}</TableCell>
                                    <TableCell key={objNote.title + 'cellnote'} sx={{ border: 0 }}>
                                      <Typography variant='body1'>
                                        <b>{objNote.title}</b><br />
                                        {objNote.description}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                )
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>
                      </DialogContent>
                      <Divider variant="middle" color='gray' />
                    </Box>
                  )}

                  <DialogActions>
                    <Button onClick={handleDialogClose} component={'label'}>Close</Button>
                  </DialogActions>
                </Dialog>
              </Box>

              <Box >
                <IconButton aria-describedby='infoIcon' variant="contained" onClick={handleInfoClick}>
                  < InfoIcon htmlColor='white' />
                </IconButton>
                <Popover
                  id='info'
                  open={openInfoPop}
                  anchorEl={anchorInfoEl}
                  onClose={handleInfoClose}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                >
                  <ListItem disablePadding >
                    <AccountInfo auth={auth} />
                  </ListItem>
                </Popover>
              </Box>

              <Box>
                <IconButton aria-describedby='pino' variant="contained" onClick={handleClick}>
                  < AccountCircleIcon htmlColor='white' />
                </IconButton>
                <Popover
                  id='pino'
                  open={openPop}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                >
                  <List>
                    <ListItem  >
                      <ListItemText primary={auth.userState.email} primaryTypographyProps={{ color: 'textSecondary' }} />
                    </ListItem>

                    <ListItem disablePadding >
                      <ListItemLink to="/changepassword" primary="Change Password" variant="contained" />
                    </ListItem>

                    <ListItem disablePadding >
                      <ListItemButton primary="Log out" variant="contained" onClick={handleLogOut}>
                        <ListItemText primary="Log out" />
                      </ListItemButton>
                    </ListItem>

                  </List>
                </Popover>
              </Box>
            </Stack>
          )}


        </Stack>

        {(auth.userState.isAuthenticated && auth.userState.custom_message &&
          <Collapse in={open}>
            <Alert
              sx={{ pl: "2em", pr: "2em" }}
              icon={<WarningIcon fontSize="inherit" />}
              variant="filled"
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {auth.userState.custom_message}
            </Alert>
          </Collapse>
        )}
      </AppBar>
    </Box >
  )
}

