import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Amplify from 'aws-amplify';
import reportWebVitals from './reportWebVitals';

if (process.env.NODE_ENV === 'development') {
  //require('dotenv').config();
  console.log('Now the value for FOO is:', process.env.REACT_APP_USER_ID);
}

const {
  REACT_APP_REGION,
  REACT_APP_USER_POOL_ID,
  REACT_APP_APP_CLIENT_ID,

  REGION,
  USER_POOL_ID,
  APP_CLIENT_ID,
} = process.env;

Amplify.configure({
  Auth: {
    madatorySignId: true,
    region: REACT_APP_REGION || REGION,
    userPoolId: REACT_APP_USER_POOL_ID || USER_POOL_ID,
    userPoolWebClientId: REACT_APP_APP_CLIENT_ID || APP_CLIENT_ID,
  }
})
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
