import React, { useState, useEffect, } from 'react';
import { styled } from '@mui/material/styles';
import Primarytheme from './theme';

import Chip from '@mui/material/Chip';


import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Box from '@mui/material/Box';

import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'



const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.15),
    marginLeft: 0, marginRight: theme.spacing(0.4),
}));




export default function TagsFilter({ tagOrderedObj, tagOrderedList, handleClickTagChip }) {

    const [drawerOpen, setDrawerOpen] = useState(false)

    return (
        <Box>
            <Accordion expanded={drawerOpen} onChange={() => setDrawerOpen(!drawerOpen)} elevation={1}
                sx={{
                    "&.Mui-expanded": { minHeight: 0 },
                    "& .MuiAccordionSummary-content.Mui-expanded": {
                        // margin from https://github.com/mui-org/material-ui/blob/cc0e2ab63e8be9ec4d51a49bfde17ef28fc77b9c/packages/mui-material/src/AccordionSummary/AccordionSummary.js#L64-L64
                        margin: "12px 0"
                    }
                }}
                disableGutters>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel1bh-header">
                    <b>Filter Tags</b>
                </AccordionSummary>
                <AccordionDetails sx={{ pb: '1em' }}>
                    <Stack direction='column' spacing={1}>
                        {tagOrderedList.map(key => (
                            <Stack key={key} direction='row' spacing={1} alignItems='center' >
                                <Typography variant='body' mr='2em' sx={{ minWidth: '4em' }} >{key}</Typography>
                                <Box component='ul' sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', flexWrap: 'wrap', listStyle: 'none', p: 0, m: 0 }}>
                                   
                                   {/*  <ListItem>
                                        <Chip label={'all'}
                                            //variant={tagPair.active ? 'filled' : 'outlined'}
                                            color='primary' size="small"
                                            //onClick={() => handleClickTagChip({ key, id })}
                                            sx={{ mr: '0.5em', border: "1px solid", borderColor: Primarytheme.palette.primary.main }} />
                                    </ListItem> */}
                                    {tagOrderedObj[key].map((tagPair, id) =>
                                        <ListItem>
                                            <Chip key={tagPair.label} label={tagPair.label}
                                                variant={tagPair.active ? 'filled' : 'outlined'}
                                                color='primary' size="small"
                                                onClick={() => handleClickTagChip({ key, id })}
                                                sx={{ mr: '0.5em', border: "1px solid", borderColor: Primarytheme.palette.primary.main }} />
                                        </ListItem>

                                    )}
                                </Box>
                            </Stack>
                        ))}
                    </Stack>
                </AccordionDetails>
            </Accordion>
        </Box>
    )
}