import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from "react-router-dom";

import Primarytheme from './theme';

import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Tooltip from "@mui/material/Tooltip";

import IconButton from '@mui/material/IconButton';

import FileUploadIcon from '@mui/icons-material/FileUpload';
import CloseIcon from '@mui/icons-material/Close';
import HelpIcon from '@mui/icons-material/Help';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Fade from '@mui/material/Fade';
import CircularProgress from '@mui/material/CircularProgress';


const {
    REACT_APP_S3_BUCKET,
} = process.env;

const { v4: uuidv4 } = require('uuid');


export default function DataUpload({ launch, setLaunch, conf, auth, handleStopProcess }) {
    const refInput = useRef();
    const dataRef = useRef();
    let history = useHistory();

    const [selectedFile, setSelectedFile] = useState(launch.selectedFile);
    const [progress, setProgress] = useState(0);
    const [uploadErrorMessage, setUploadErrorMessage] = useState('');


    dataRef.current = selectedFile ? sanitizeOriginName(selectedFile.name) : '';

    const resetInput = () => {
        refInput.current.value = "";
        handleStopProcess({ reqId: launch.projectData.reqId, project_id: conf.project_id })
            .then(resp => console.log(resp.data, launch.projectData.reqId))
            .catch(err => console.log(err))

        setSelectedFile(null);
        setProgress(0);
        if (uploadErrorMessage !== '') setUploadErrorMessage('');
        setLaunch({ ...launch, isReady: false, selectedFile: null, isLoaded: false, isLoading: false })
    };


    const handleFileInput = (e) => {

        const file = e.target.files[0];
        const sanitizedName = sanitizeOriginName(file.name)

        if (launch.dataFormat.indexOf(`.${sanitizedName.split('.').pop().toLowerCase()}`) === -1) {
            // WRONG FILE EXTENSION
            setUploadErrorMessage(`Wrong file extension. Please upload a datafile with extension ${launch.dataFormat}`)
        } else {
            setSelectedFile(file);
            if (uploadErrorMessage !== '') setUploadErrorMessage('');
            const flag_isFileAlready = launch.selectedFile;
            const flag_isFileSame = flag_isFileAlready && sanitizedName === launch.selectedFile.name && file.lastModified === launch.selectedFile.lastModified;
            //console.log('same file ',flag_isFileSame)
            if (!flag_isFileSame) {
                setLaunch({ ...launch, isLoading: true })

                // init reqid
                const reqId = uuidv4();

                const project_id = launch.selectedProject;
                const dataFolder = `data-webapp/${reqId}`;

                
                const project = {
                    project_id,
                    //projectConf: conf,
                    bucket: conf.bucket || REACT_APP_S3_BUCKET,
                    dataFolder,
                    key: sanitizedName,
                    reqId

                }

                //uploadFile(file, config)
                auth.s3.upload({
                    Body: file,
                    Bucket: project.bucket,
                    Key: `${project_id}/${dataFolder}` + '/' + sanitizedName,
                })
                    .on('httpUploadProgress', evt => {
                        var uploaded = Math.round(evt.loaded / evt.total * 100);
                        setProgress(uploaded);
                        //console.log(`File uploaded: ${uploaded}%`);
                    })
                    .send((err, data) => {
                        if (err) {
                            console.error('error uploading data, please refresh', err)
                            setLaunch(launch => { return { ...launch, isReady: false, selectedFile: null, isLoading: false } })
                            setUploadErrorMessage(err)
                            //history.push("/dashboard");
                        }
                        console.log('original uploaded ', file.name);

                        if (dataRef.current === sanitizedName)
                            setLaunch(launch => { return { ...launch, isLoaded: true, isLoading: false, filename: sanitizedName, projectData: project, isReady: true, selectedFile: file } })
                    })
            } else {
                setLaunch(launch => { return { ...launch, isReady: true, selectedFile: file } })
            }
        }


    }





    return (
        <Box>
            <Box>
                {launch.selectedProject !== '' && conf.projectStatus && conf.projectStatus.length !== "" &&
                    (<Typography sx={{ marginBottom: '1em' }}>
                        <b>Project Status</b>: {conf.project_status}
                    </Typography>)}
                <Box>
                    <Stack direction='row' spacing={2}
                        justifyContent="flex-start"
                        alignItems="center"
                        sx={{ marginTop: '1em', marginBottom: '1em' }}
                    >

                        <Button
                            variant="outlined"
                            component="label"
                            size='small'
                            color='primary'
                            disabled={!launch.selectedProject}
                        >
                            <FileUploadIcon color={launch.selectedProject !== '' ? 'primary' : 'neutral'} />
                            <input
                                type="file"
                                hidden
                                accept={launch.dataFormat}
                                onChange={handleFileInput}
                                ref={refInput}
                            />
                        </Button>

                        {(selectedFile && selectedFile.name !== '') && (
                            <Chip label={<Typography sx={{ fontSize: 13, whiteSpace: 'normal', wordWrap: 'break-word' }}>{selectedFile.name}</Typography>} variant="outlined"
                                onDelete={resetInput}
                                sx={{ borderRadius: 1, maxWidth: '80%', height: '100%' }}

                            />
                        )}
                        {!selectedFile && <Typography color='textSecondary'>Choose your file</Typography>}

                        <Fade in={launch.isLoading} timeout={{ enter: 10, exit: 1000 }} style={{ transitionDelay: '10ms' }} >
                            {/*   <Typography color='textSecondary' variant='caption'>Uploading...</Typography>  */}
                            <CircularProgress variant='determinate' value={progress} size={25} />
                        </Fade>
                        {uploadErrorMessage !== '' && (
                            <Alert elevation={0} severity="error" sx={{ marginTop: '1em', display: 'inline-flex' }}>
                                Error uploading the file.
                                <Tooltip title={
                                    <Typography variant='caption' >
                                        {`${uploadErrorMessage}`}
                                    </Typography>}>
                                    <HelpIcon sx={{ fontSize: '1em', ml: '0.5em', color: Primarytheme.palette.neutral.main }} />
                                </Tooltip>

                            </Alert>
                        )}
                    </Stack>

                </Box>
            </Box>
        </Box >
    )
}


function sanitizeOriginName(originName) {
    if (originName) {
        const ext = originName.split('.').pop();
        const name = originName.split('.').slice(0, -1).join('.').trim();
        originName = name ? name + '.' + ext : ext; //'se origin è fissato senza estensione, stampo la cosa fissata senza punti
    }
    return originName;
}