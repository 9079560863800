import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth } from "aws-amplify";

import LoadingButton from '@mui/lab/LoadingButton';
import Validate from "../utility/FormValidation";
import { Box, Paper, Stack } from '@mui/material';
//import { IoTJobsDataPlane } from 'aws-sdk';
import Typography from '@mui/material/Typography';

import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import logo from './../../logos/Logo_Cerchio_1000px.svg'

export default function ChangePassword() {

    const history = useHistory();

    const [credentialsForm, setCredentialsForm] = useState({
        username: '',
        password: '',
        oldPassword: "",
        confirmPassword: "",
        showPassword: false,
        errors: {
            username: "",
            email: "",
            password: "",
            oldPassword: "",
            confirmPassword: "",
            cognito: ""
        },
    });


    const handleSubmit = async () => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            console.log(user);
            await Auth.changePassword(
                user,
                credentialsForm.oldPassword,
                credentialsForm.password
            );
            redirect('/dashboard')

        } catch (error) {
            setCredentialsForm({
                ...credentialsForm,
                errors: {
                    ...credentialsForm.errors,
                    cognito: error.message || 'Unexpect error during authentication',
                }
            });
        }
    }


    const onInputChange = event => {
        const error = Validate({ ...credentialsForm, [event.target.id]: event.target.value });
        // console.log(error)
        setCredentialsForm({
            ...credentialsForm,
            [event.target.id]: event.target.value,
            errors: { ...credentialsForm.errors, ...error, cognito: null }
        });

    }

    const handleClickShowPassword = () => {
        setCredentialsForm({
            ...credentialsForm,
            showPassword: !credentialsForm.showPassword,
        });
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    const redirect = (dst) => {
        history.push(dst);
    }

    return (
        <Paper sx={{ width: '60%', minWidth: '30em', maxWidth: '40em', m: 'auto', marginTop: '10%', paddingTop: '2em' }} elevation={3}>
            <Stack
                component="form"
                noValidate
                autoComplete="new-password"
                direction='column'
                alignItems='center'
            >

                <img src={logo} height="100" alt="pagination logo" />

                <Typography variant='caption'  sx={{ m:'1em',marginBottom: '2em', marginTop: '2em' }}>
                    Password must have at least 8 characters, upper and lower case, a number.
                </Typography>

                <FormControl sx={{ m: 1, width: '80%', maxWidth: '30em' }} variant="outlined">
                    <InputLabel error={credentialsForm.errors.oldPassword !== ""}>
                        Old password
                    </InputLabel>
                    <OutlinedInput
                        id="oldPassword"
                        label="Old password"
                        type={credentialsForm.showPassword ? 'text' : 'password'}
                        value={credentialsForm.oldPassword}
                        onChange={onInputChange}
                        error={credentialsForm.errors.oldPassword !== ""}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {credentialsForm.showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    {credentialsForm.errors.oldPassword !== "" &&
                        <FormHelperText
                            error={true}
                            id="pass-err">
                            {credentialsForm.errors.oldPassword}
                        </FormHelperText>
                    }
                </FormControl>

                <FormControl sx={{ m: 1, width: '80%', maxWidth: '30em' }} variant="outlined">
                    <InputLabel error={credentialsForm.errors.password !== ""}>
                        Password
                    </InputLabel>
                    <OutlinedInput
                        id="password"
                        label="password"
                        type={credentialsForm.showPassword ? 'text' : 'password'}
                        value={credentialsForm.password}
                        onChange={onInputChange}
                        error={credentialsForm.errors.password !== ""}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {credentialsForm.showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    {credentialsForm.errors.password !== "" &&
                        <FormHelperText
                            error={true}
                            id="pass-err">
                            {credentialsForm.errors.password}
                        </FormHelperText>
                    }
                </FormControl>

                <FormControl sx={{ m: 1, width: '80%', maxWidth: '30em' }} variant="outlined">
                    <InputLabel error={credentialsForm.errors.confirmPassword !== ""}>
                        Confirm password
                    </InputLabel>
                    <OutlinedInput
                        id="confirmPassword"
                        label="Confirm password"
                        type={credentialsForm.showPassword ? 'text' : 'password'}
                        value={credentialsForm.confirmPassword}
                        onChange={onInputChange}
                        error={credentialsForm.errors.confirmPassword !== ""}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {credentialsForm.showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    {credentialsForm.errors.confirmPassword !== "" &&
                        <FormHelperText
                            error={true}
                            id="pass-err">
                            {credentialsForm.errors.confirmPassword}
                        </FormHelperText>
                    }
                </FormControl>


                {credentialsForm.errors.cognito !== undefined &&
                    <Typography variant='caption' color='error' sx={{ marginBottom: '1em' }}>
                        {credentialsForm.errors.cognito}
                    </Typography>
                }

                <LoadingButton
                    color="primary"
                    disabled={(credentialsForm.password === '' || credentialsForm.confirmPassword === '') || credentialsForm.errors.password !== '' || credentialsForm.errors.confirmPassword !== ''}
                    loading={credentialsForm.isLogging}
                    //loadingPosition="start"
                    variant="contained"

                    onClick={handleSubmit}
                    sx={{ m: '2em', marginTop: '1em' }}
                >
                    Change password
                </LoadingButton>


            </Stack >
        </Paper>
    )

}


