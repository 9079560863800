import Box from '@mui/material/Box';
import { useEffect, useState, useRef } from 'react';
import Primarytheme from './theme';
import { styled } from '@mui/material/styles';


import Tabs from '@mui/material/Tabs';



import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';

import Alert from '@mui/material/Alert';

import LoadingButton from '@mui/lab/LoadingButton';

import IconButton from '@mui/material/IconButton';
import Refresh from '@mui/icons-material/Refresh'
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';



import AssetImage from './AssetImage';
import AssetInDesign from './AssetInDesign';

import { buildFakeDataTables, getAssetFoldersFromConf, mapTableData } from '../helpers/assets';

const axios = require('axios');

const {
    REACT_APP_X_API_KEY,
    REACT_APP_USER_ID,
} = process.env;


export default function AssetManagementDashboard({ auth, project_id, conf }) {

    const [assets, setAssets] = useState({
        selectedProject: project_id,
        conf: conf,

        fetching: true,
        refreshing: false,
        uploading: false,

        tag: null,
        refresh: 1,

        // navbar
        folders: [],

        // solo per images
        objectList: [],
        dataTables: [],
        dataTablesId: {},
        //selectionModel: [],

        // solo per indd
        inddList: [],
    })

    const refreshPage = () => {
        setAssets(assets => {
            return { ...assets, refresh: !assets.refresh }
        })

    }

    const handleRefreshTable = ({ folderId }) => {
        setAssets({ ...assets, refreshing: true });
        console.log(folderId, assets.folders[folderId])
        fetchS3Objects1({
            src: assets.folders[folderId].s3,
            name: assets.folders[folderId].name,
            type: assets.folders[folderId].type,
            tags: assets.folders[folderId].tags,
            sharedProjectId: assets.folders[folderId].sharedProjectId,
        })
            .then(singleFolder => {
                console.log('singlefolder', singleFolder)
                let table;
                if (singleFolder.type === 'img' || singleFolder.type === 'additional-data') {
                    table = mapTableData({ type: singleFolder.type, name: assets.folders[folderId].name, S3rows: singleFolder.data.s3KeyList, size: singleFolder.data.fullSize, renderCell })
                }
                // se è indesign, la folder contiene la proprietà inddList
                else {
                    table = singleFolder.data.inddList.map(doc => {
                        doc.indd.width = 250;
                        doc.indd.prefix = assets.folders[folderId].name
                        doc.fonts = mapTableData({ type: singleFolder.type, name: 'Document fonts', S3rows: doc.fonts, ignoreCols: new Set(['Size', 'Last Modified']), renderCell });
                        doc.links = mapTableData({ type: singleFolder.type, name: 'Links', S3rows: doc.links, ignoreCols: new Set(['Size', 'Last Modified']), renderCell });
                        return doc
                    })
                }

                const newDataTables = [...assets.dataTables];
                newDataTables[folderId] = table;
                setAssets({ ...assets, dataTables: newDataTables, refreshing: false })

            })

    }

    const fetchS3Objects1 = function ({ src, name, type, tags, sharedProjectId, obj = {}, i = 0 }) {
        console.log('init dash assets', src, name, type, tags, sharedProjectId, obj)
        let action = type === 'img' ? 'query-items-dynamo' : type;
        let body = {};

        if (action === 'additional-data') {
            action = 'query-items-dynamo';
            body = {
                project_id: sharedProjectId ? sharedProjectId : src.split('/').slice(1, 3).join('/'),
                selectedFolder: name,
                fullSize: obj[src] ? obj[src].fullSize : 0,
                filterUser: auth.userState.policy.filterUser ? auth.userState.user : false,
            }
        }
        if (type === 'img') {
            body = {
                project_id: sharedProjectId ? sharedProjectId : src.split('/').slice(1, 3).join('/'),
                selectedFolder: name,
                LastEvaluatedKey: obj[src] ? obj[src].LastEvaluatedKey : undefined,
                fullSize: obj[src] ? obj[src].fullSize : 0,
                filterUser: auth.userState.policy.filterUser ? auth.userState.user : false,
            }
        }

        return axios({
            method: "post",
            url: 'https://mjj82gfcl8.execute-api.eu-west-1.amazonaws.com/vpc/assets',
            headers: {
                'user_id': REACT_APP_USER_ID || auth.USER_ID,
                "content-type": "application/json",
                "x-api-key": REACT_APP_X_API_KEY || auth.X_API_KEY,
                "src": src,
                "tags": JSON.stringify(tags),
                "action": action,
                "project_id": src.split('/').slice(1, 3).join('/'),
                "selectedFolder": name,
                "LastEvaluatedKey": obj[src] ? JSON.stringify(obj[src].LastEvaluatedKey) : undefined,
                "lastId": obj[src] ? obj[src].lastId : 0,
                "fullSize": obj[src] ? obj[src].fullSize : 0,
            },
            data: { ...body }
        })
            .then(resp => {
                if (type === 'img') {
                    if (!resp.data.LastEvaluatedKey && !obj[src]) {
                        console.log(resp.data)
                        return { data: { s3KeyList: resp.data.s3KeyList, fullSize: resp.data.fullSize }, type }
                    } else {
                        if (!obj[src] || (obj[src] && resp.data.LastEvaluatedKey !== undefined)) {
                            obj[src] = {
                                fullSize: resp.data.fullSize,
                                s3KeyList: obj[src] ? [...obj[src].s3KeyList, ...resp.data.s3KeyList] : resp.data.s3KeyList,
                                startAfter: resp.data.lastKey,
                                src,
                                type,
                                tags,
                                LastEvaluatedKey: resp.data.LastEvaluatedKey ? resp.data.LastEvaluatedKey : undefined,
                            }

                            i++;
                            // recusrive call
                            return fetchS3Objects1({ src, type, name, tags, sharedProjectId, obj, i });
                        } else {

                            // return with the updated data
                            obj[src] = {
                                fullSize: resp.data.fullSize,
                                s3KeyList: obj[src] ? [...obj[src].s3KeyList, ...resp.data.s3KeyList] : resp.data.s3KeyList,
                                startAfter: resp.data.lastKey,
                                src,
                                type,
                                tags,
                                LastEvaluatedKey: resp.data.LastEvaluatedKey ? resp.data.LastEvaluatedKey : 0,
                            }
                            // console.log('final object ', obj);

                            return { data: { s3KeyList: obj[src].s3KeyList, fullSize: obj[src].fullSize }, type }
                        }
                    }
                } else {
                    // return as before
                    return { data: resp.data, type }
                }
            })
            .catch(err => {
                //console.log(err)
                return (err)
            });

    }

    const deleteFileS3 = ({ keyList }) => {
        console.log('delete ', keyList)
        const uri = 'https://mjj82gfcl8.execute-api.eu-west-1.amazonaws.com/vpc/assets';
        // console.log(uri);
        return axios({
            method: "post",
            url: uri,
            headers: {
                'user_id': REACT_APP_USER_ID || auth.userState.ssmParams.USER_ID,
                "content-type": "application/json",
                "x-api-key": REACT_APP_X_API_KEY || auth.userState.ssmParams.X_API_KEY,
                "action": "delete"
            },
            data: {
                keyList
            }
        })
            .then(resp => {
                //console.log(resp.data);
                setAssets(assets => {
                    return { ...assets, refresh: -assets.refresh, selectionModel: [] }
                })

            })
            .catch(err => {
                console.log(err)
            });
    }

    // download a key from s3, bucket is default state
    const getFileFromS3 = ({ fullPath }) => {
        //console.log('get from s3 ', key)
        retrieveSignedUrl({ fullPath })
            .then(response => {
                window.open(response.data.signedUrl)
            })
            .catch(err => console.log(err))
    }

    const retrieveSignedUrl = ({ fullPath, Expires = 180 }) => {

        const [bucket, realKey] = fullPath.split('###');
        // console.log('bucket ', bucket, 'key', realKey)
        return axios({
            method: "post",
            url: 'https://api.pagination.com/vpc/operations',
            headers: {
                'user_id': REACT_APP_USER_ID || auth.userState.ssmParams.USER_ID,
                "content-type": "application/json",
                "x-api-key": REACT_APP_X_API_KEY || auth.userState.ssmParams.X_API_KEY,
            },
            data: {
                Bucket: bucket,
                Key: realKey,
                Expires
            }
        })
    }


    const renderCell = (name) => {
        return (param) => (
            <Stack direction='row'
                justifyContent="center"
                alignItems="center"
                spacing={2} >
                <IconButton size="small" onClick={() => getFileFromS3({ fullPath: param.value })} >
                    <OpenInNewIcon />
                </IconButton>
                {name !== 'Links' && name !== 'Document fonts' && (
                    <IconButton size="small" onClick={() => deleteFileS3({ keyList: [param.value] })} >
                        <DeleteIcon />
                    </IconButton>
                )}

            </Stack>
        )
    }

    // retrieve conf after selected project
    useEffect(() => {
        if (project_id) {
            setAssets({ ...assets, refreshing: true })
            const assetFolders = getAssetFoldersFromConf(conf, auth.userState);
            const dataTables = buildFakeDataTables(assetFolders);
            //console.log('data tables FAKE', dataTables)
            setAssets({ ...assets, folders: assetFolders, dataTables, fetching: false, refreshing: false })
        }

    }, [project_id, assets.refresh]);


    return (
        <Box sx={{ m: '0.5em', p: 0, m: 0 }}>
            {!assets.selectedProject && (
                <Stack direction='row' spacing={2} alignItems='center'>
                    <Typography variant='h7' color='primary' > Select the project first</Typography>
                    <ArrowUpwardIcon size='medium' color='primary' />
                </Stack>
            )}
            {assets.selectedProject && assets.fetching && (
                <Box >
                    <Skeleton animation="wave" />
                    <Skeleton variant="rectangular" animation="wave" height={100} />
                </Box>
            )}
            {assets.selectedProject && (
                <Box component='ul' sx={{
                    display: 'flex',
                    justifyContent: 'left',
                    flexWrap: 'wrap',
                    listStyle: 'none',
                    p: 0,
                    m: 0,
                }}>
                    {assets.dataTables.map((selectedFolder, id) => {
                        if (selectedFolder.setup) return null;
                        else if (selectedFolder.type === 'img' || selectedFolder.type === 'additional-data') return (
                            <Stack key={selectedFolder.name}
                                component='li' direction='column' spacing={1} sx={{ mr: '5em' }}>
                                <Typography variant='body' >
                                    <b>{selectedFolder.name}</b>
                                </Typography>
                                <AssetImage key={selectedFolder.name}
                                    auth={auth} conf={conf}
                                    handleRefreshTable={() => handleRefreshTable({ folderId: id })}
                                    dataTable={selectedFolder}
                                    inDashboard={true}
                                    folderObjList={assets.folders}
                                />
                            </Stack >
                        );
                        else return (
                            <Stack key={assets.folders[id].name}
                                direction='column' component='li' spacing={1} sx={{ mr: '5em' }} >
                                <Typography variant='body' sx={{ textAlign: 'center' }}>
                                    <b>{assets.folders[id].name}</b>
                                </Typography>
                                <AssetInDesign
                                    auth={auth} conf={assets.conf}
                                    handleRefreshTable={() => handleRefreshTable({ folderId: id })}
                                    dataTable={selectedFolder}
                                    folderName={assets.folders[id].name}
                                    folderPath={assets.folders[id].s3}
                                    tagsKeys={assets.folders[id].tags}
                                    inDashboard={true}

                                />
                            </Stack >
                        )
                    })}
                </Box>
            )}
            {assets.selectedProject && !assets.fetching && assets.dataTables.length === 0 && (
                <Alert elevation={0} severity="info" sx={{ marginTop: '0.5em', display: 'inline-flex', pr: '2em' }}>The project doesn't support assets upload</Alert>
            )}
        </Box>
    )
}



