import Primarytheme from './theme';
import React, { useState } from 'react';


import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';

import Tooltip from "@mui/material/Tooltip";
import HelpIcon from '@mui/icons-material/Help';

import FileUploadIcon from '@mui/icons-material/FileUpload';
import CloseIcon from '@mui/icons-material/Close';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';

import { getContentType, encodeTag, supportedLinksFormats } from '../helpers/assets';

const axios = require('axios');

const {
    REACT_APP_X_API_KEY,
    REACT_APP_USER_ID,
} = process.env;

export default function GenericMultiUpload({ auth, bucket, project_id, folderObj, folderName, format = { ext: 'idml', version: 'CC 2020' }, refreshTable, uploadTags, closeDialog, disabled, action, checkLinks, setLinks }) {

    //console.log('uploading component ', folderObj)
    const [openAsset, setOpenAsset] = useState(false);

    const [selectedAssets, setSelectedAssets] = useState({});

    const [assetProgressGlobal, setAssetProgressGlobal] = useState(0);

    const [isLoadingAsset, setIsLoadingAsset] = useState({});
    const [isLoadingAssetGlobal, setIsLoadingAssetGlobal] = useState(false);

    const [showWarningInddFormat, setShowWarningInddFormat] = useState('');

    const [showUploadMessage, setShowUploadMessage] = useState(false);

    const updateObjState = (stateObj, setStateObj, value, folder) => {
        //console.log('value ', value)
        setStateObj(stateObj => {
            const updatedStateObj = { ...stateObj };
            updatedStateObj[folder] = value;
            return updatedStateObj;
        })
    }

    const handleCloseAsset = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAsset(false);
    };

    const handleClearAssetsAll = (folder) => {

        updateObjState(selectedAssets, setSelectedAssets, [], folder);
        updateObjState(isLoadingAsset, setIsLoadingAsset, false, folder);
        if (showWarningInddFormat) setShowWarningInddFormat('')

    };
    const handleAssetsInput = (event, folder) => {
        //event.preventDefault();
        console.log('files ', event.target.files.length, format.ext, folder, event.target.files[0].name)
        let flag_Upload = true;

        if (folder === 'inDesign') {
            if (format.ext === 'idml' && event.target.files[0].name.indexOf('.idml') === -1) {
                setShowWarningInddFormat('The file is not a valid .idml document');
                flag_Upload = false;
            }
            else if (format.ext === 'indd' && event.target.files[0].name.indexOf('.indd') === -1) {
                setShowWarningInddFormat('The file is not a valid .indd document');
                flag_Upload = false;
            }

            if (flag_Upload && showWarningInddFormat !== '') setShowWarningInddFormat('')
        }

        if (flag_Upload) updateObjState(selectedAssets, setSelectedAssets, event.target.files, folder);

    }

    const checkIdmlFiles = (docPath) => {
        console.log('init checks ...')
        return axios({
            method: "post",
            url: 'https://mjj82gfcl8.execute-api.eu-west-1.amazonaws.com/vpc/assets',
            headers: {
                'user_id': REACT_APP_USER_ID || auth.ssmParams.USER_ID,
                "content-type": "application/json",
                "x-api-key": REACT_APP_X_API_KEY || auth.ssmParams.X_API_KEY,
                "src": docPath,
                "project_id": project_id,
                "action": 'dynamo-checks'
            },
        })
            .then(resp => {
                const linksObj = {
                    missingLiks: resp.data.linksCheckArr,
                    unusedLinks: resp.data.unusedLinksArr,
                }
                setLinks(linksObj);
                return resp;
            })
            .catch(err => {
                // console.log(err);
                return err;
            })
    }

    const handleUploadDocument = async () => {
        console.log('folderObj', folderObj)
        console.log('selectedAssets', selectedAssets)
        setIsLoadingAssetGlobal(true);
        const folderToUpload = Object.keys(selectedAssets);

        // show upload message to wait
        setShowUploadMessage(true);

        // init tags encoding
        const tagsEncoded = uploadTags.map(k => `${encodeURIComponent(encodeTag(k.name))}=${encodeURIComponent(encodeTag(k.value))}`).join('&');
        const uriEncodedTags = uploadTags.map(tagObj => { return { Key: encodeURIComponent(encodeTag(tagObj.name)), Value: encodeURIComponent(encodeTag(tagObj.value)) } })

        // prima cancellare la cartella
        console.log('handle upload tags', tagsEncoded)
        const sizing = { total: 0, uploaded: [] };
        const filesToUpload = [];
        for (const f of folderToUpload) {
            sizing.total += Array.from(selectedAssets[f]).reduce((a, b) => +a + +b.size, 0);
            sizing.uploaded.push(...Array(selectedAssets[f].length).fill(0))
            for (const sf of selectedAssets[f]) {
                sf.dirName = folderObj[f].replace(bucket + '/', '');
            }
            filesToUpload.push(...selectedAssets[f])
        }

        const promises = [];
        let dynamoArr = [];
        for (const [id, f] of filesToUpload.entries()) {
            promises.push(
                new Promise((resolve, reject) => {
                    auth.s3.upload({
                        Body: f, //files[i],
                        Bucket: bucket,
                        Key: f.dirName + '/' + f.name,//files[i].name,
                        Tagging: tagsEncoded,
                        ContentType: getContentType(f.name.split('.').pop())
                    })
                        .on('httpUploadProgress', evt => {
                            sizing.uploaded[id] = evt.loaded;
                            //const loadedPart = sizing.uploaded.reduce((partialSum, a) => partialSum + a, 0)
                            // console.log(id, loadedPart, sizing.total, loadedPart / sizing.total)
                            setAssetProgressGlobal(sizing.uploaded.reduce((partialSum, a) => partialSum + a, 0) / sizing.total * 100);
                        })
                        .send((err, data) => {
                            if (err) {
                                console.error('error uploading data, please refresh', err)
                                return reject();
                            } else {
                                // set up obj for dynamo
                                dynamoArr.push({
                                    ...data,
                                    uploadDate: new Date().getTime(),
                                    name: f.name,
                                    lastModified: f.lastModified,
                                    lastModifiedDate: f.lastModifiedDate,
                                    size: f.size,
                                    type: f.type ? f.type : 'unknown',
                                    selectedFolder: folderName,
                                    fileCategory: folderToUpload.indexOf(f.dirName.split('/').pop()) !== -1 ? f.dirName.split('/').pop() : 'inDesign',
                                    tags: JSON.stringify(uriEncodedTags),
                                    project_id: project_id, // key
                                    reqId: folderObj['inDesign'].split('/').pop()
                                })
                                return resolve();
                            }
                        })
                })
            )

            if (id % 25 === 0 || id === filesToUpload.length - 1) {
                await Promise.all(promises).catch(err => console.log(err));
                promises.length = 0

                // upload in dynamo
                let dynamoArrRequest = [];
                for (let i = 0; i < dynamoArr.length; i++) {
                    dynamoArrRequest.push(dynamoArr[i]);

                    if (i % 25 === 0 || i === dynamoArr.length - 1) {
                        const putReq = await axios({
                            method: "post",
                            url: 'https://mjj82gfcl8.execute-api.eu-west-1.amazonaws.com/vpc/assets',
                            headers: {
                                'user_id': REACT_APP_USER_ID || auth.ssmParams.USER_ID,
                                "content-type": "application/json",
                                "x-api-key": REACT_APP_X_API_KEY || auth.ssmParams.X_API_KEY,
                                "action": 'new-item-dynamo-batch',
                            },
                            data: JSON.stringify(dynamoArrRequest)
                        })
                        // console.log('resp upload:',id, 'step', i, putReq)

                        dynamoArrRequest = [];
                    }
                }
                dynamoArr = [];
            }
        }

        //if ((checkLinks !== 'false' && checkLinks !== false) && (folderName === 'InDesign' || action === 'update')) {
        if (checkLinks !== 'false' && checkLinks !== false) {
            const checkDone = await checkIdmlFiles(folderObj['inDesign'])
        }

        setIsLoadingAssetGlobal(false);
        closeDialog()
        refreshTable()


    }


    return (
        <Box id='pino'>
            {action !== 'update' && (
                <Box mt='1em' mb='2em' sx={{
                    border: 1,
                    borderRadius: 2,
                    borderWidth: 2,
                    borderColor: disabled ? Primarytheme.palette.neutral.light : Primarytheme.palette.primary.main,
                    padding: '1em'
                }}>
                    {folderObj && Object.keys(folderObj).filter(f => (f === 'Pdf' && format.ext === 'idml') ? false : true).map(folder => {
                        let exts;
                        let labelFolder = folder;
                        if (folder === 'inDesign') {
                            if (format.ext === 'indd') {
                                exts = ['.indd'];
                                labelFolder = `InDesign INDD - ${format.version}`;
                            }
                            else {
                                exts = ['.idml'];
                                labelFolder = 'InDesign IDML';
                            }

                        }

                        else if (folder === 'Links') exts = supportedLinksFormats;
                        else if (folder === 'Document fonts') exts = ['.otf', '.ttf'];
                        else if (folder === 'Pdf') exts = ['.pdf'];

                        return (
                            <Stack direction='row'
                                justifyContent="space-between"

                                alignItems="center"
                                spacing={2}
                                sx={{ marginBottom: '0.5em', }}
                                key={folder}
                            >
                                <Typography sx={{ opacity: (disabled || isLoadingAssetGlobal) ? 0.15 : 1 }}>
                                    {labelFolder}
                                </Typography>

                                <Stack direction='row'
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    spacing={2}
                                //sx={{ paddingBottom: '1em', }}
                                >

                                    {folder === 'inDesign' && showWarningInddFormat !== '' && (
                                        <Alert elevation={0} severity="warning" sx={{ margin: '-0.5em', display: 'inline-flex' }}>{showWarningInddFormat}</Alert>
                                    )}
                                    {selectedAssets[folder] && selectedAssets[folder].length > 0 && (
                                        <Stack direction='row' spacing={2} alignItems='center' >
                                            <IconButton
                                                onClick={() => handleClearAssetsAll(folder)}
                                                disabled={disabled || isLoadingAssetGlobal} >
                                                < CloseIcon />
                                            </IconButton>
                                            <Typography variant='caption' >
                                                {selectedAssets[folder].length} files selected
                                            </Typography>
                                        </Stack  >
                                    )}
                                    <Button
                                        variant="outlined"
                                        component="label"
                                        color='primary'
                                        size='small'
                                        disabled={disabled || isLoadingAssetGlobal}
                                    >
                                        Select files
                                        <FileUploadIcon color={(disabled || isLoadingAssetGlobal) ? 'netrual' : 'primary'} />
                                        <input
                                            type="file"
                                            multiple
                                            hidden
                                            accept={exts}
                                            onChange={(event) => handleAssetsInput(event, folder)}
                                            onClick={(event) => {
                                                event.target.value = null //per poter selezionare lo stesso file di seguito
                                            }}

                                        />
                                    </Button>

                                    {/* <Box> <LinearProgress variant="determinate" value={40} /></Box> */}

                                    {/*  {isLoadingAsset[folder] && <LinearProgress variant="determinate" value={assetProgress[folder]} sx={{ width: '100%', height: 25 }} />} */}
                                </Stack>

                            </Stack>
                        )

                    })}
                </Box>
            )}

            {action === 'update' && folderObj && Object.keys(folderObj).filter(f => (f !== 'inDesign') ? false : true).map(folder => {
                let exts;
                let labelFolder = folder;
                if (folder === 'inDesign') {
                    if (format.ext === 'indd') {
                        exts = ['.indd'];
                        labelFolder = `InDesign INDD - ${format.version}`;
                    }
                    else {
                        exts = ['.idml'];
                        labelFolder = 'InDesign IDML';
                    }

                }

                return (
                    <Box id={folder}>
                        <Typography component='div' variant='body' color='textSecondary' mt='1.5em'>
                            Select the idml file that will replace the existing one.
                        </Typography>
                        <Box mt='1em' mb='2em' sx={{
                            border: 1,
                            borderRadius: 2,
                            borderWidth: 2,
                            borderColor: disabled ? Primarytheme.palette.neutral.light : Primarytheme.palette.primary.main,
                            padding: '1em'
                        }}>
                            <Stack direction='row'
                                justifyContent="space-between"

                                alignItems="center"
                                spacing={2}
                                sx={{ marginBottom: '0.5em', }}
                                key={folder}
                            >
                                <Typography sx={{ opacity: (disabled || isLoadingAssetGlobal) ? 0.15 : 1 }}>
                                    {labelFolder}
                                </Typography>

                                <Stack direction='row'
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    spacing={2}
                                //sx={{ paddingBottom: '1em', }}
                                >

                                    {folder === 'inDesign' && showWarningInddFormat !== '' && (
                                        <Alert elevation={0} severity="warning" sx={{ margin: '-0.5em', display: 'inline-flex' }}>{showWarningInddFormat}</Alert>
                                    )}
                                    {selectedAssets[folder] && selectedAssets[folder].length > 0 && (
                                        <Stack direction='row' spacing={2} alignItems='center' >
                                            <IconButton
                                                onClick={() => handleClearAssetsAll(folder)}
                                                disabled={disabled || isLoadingAssetGlobal} >
                                                < CloseIcon />
                                            </IconButton>
                                            <Typography variant='caption' >
                                                {selectedAssets[folder].length} files selected
                                            </Typography>
                                        </Stack  >
                                    )}
                                    <Button
                                        variant="outlined"
                                        component="label"
                                        color='primary'
                                        size='small'
                                        disabled={disabled || isLoadingAssetGlobal}
                                    >
                                        Select files
                                        <FileUploadIcon color={(disabled || isLoadingAssetGlobal) ? 'netrual' : 'primary'} />
                                        <input
                                            type="file"
                                            multiple
                                            hidden
                                            accept={exts}
                                            onChange={(event) => handleAssetsInput(event, folder)}
                                            onClick={(event) => {
                                                event.target.value = null //per poter selezionare lo stesso file di seguito
                                            }}

                                        />
                                    </Button>

                                    {/* <Box> <LinearProgress variant="determinate" value={40} /></Box> */}

                                    {/*  {isLoadingAsset[folder] && <LinearProgress variant="determinate" value={assetProgress[folder]} sx={{ width: '100%', height: 25 }} />} */}
                                </Stack>

                            </Stack>
                        </Box>
                    </Box>
                )

            })}
            {action === 'update' && (
                <Box id='linksAndFonts'>
                    <Typography component='div' variant='body' color='textSecondary' mt='1.5em'>
                        Select Links and/or Fonts files to upload. (Uploaded files will overwrite existing ones if their names match)
                    </Typography>
                    <Box mt='1em' mb='2em' sx={{
                        border: 1,
                        borderRadius: 2,
                        borderWidth: 2,
                        borderColor: disabled ? Primarytheme.palette.neutral.light : Primarytheme.palette.primary.main,
                        padding: '1em'
                    }}>
                        {folderObj && Object.keys(folderObj).filter(f => (f === 'inDesign') ? false : true).map(folder => {
                            let exts;
                            let labelFolder = folder;
                            if (folder === 'inDesign') {
                                if (format.ext === 'indd') {
                                    exts = ['.indd'];
                                    labelFolder = `InDesign INDD - ${format.version}`;
                                }
                                else {
                                    exts = ['.idml'];
                                    labelFolder = 'InDesign IDML';
                                }

                            }

                            else if (folder === 'Links') exts = supportedLinksFormats;
                            else if (folder === 'Document fonts') exts = ['.otf', '.ttf'];
                            else if (folder === 'Pdf') exts = ['.pdf'];

                            return (
                                <Stack direction='row'
                                    justifyContent="space-between"

                                    alignItems="center"
                                    spacing={2}
                                    sx={{ marginBottom: '0.5em', }}
                                    key={folder}
                                >
                                    <Typography sx={{ opacity: (disabled || isLoadingAssetGlobal) ? 0.15 : 1 }}>
                                        {labelFolder}
                                    </Typography>

                                    <Stack direction='row'
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        spacing={2}
                                    //sx={{ paddingBottom: '1em', }}
                                    >

                                        {folder === 'inDesign' && showWarningInddFormat !== '' && (
                                            <Alert elevation={0} severity="warning" sx={{ margin: '-0.5em', display: 'inline-flex' }}>{showWarningInddFormat}</Alert>
                                        )}
                                        {selectedAssets[folder] && selectedAssets[folder].length > 0 && (
                                            <Stack direction='row' spacing={2} alignItems='center' >
                                                <IconButton
                                                    onClick={() => handleClearAssetsAll(folder)}
                                                    disabled={disabled || isLoadingAssetGlobal} >
                                                    < CloseIcon />
                                                </IconButton>
                                                <Typography variant='caption' >
                                                    {selectedAssets[folder].length} files selected
                                                </Typography>
                                            </Stack  >
                                        )}
                                        <Button
                                            variant="outlined"
                                            component="label"
                                            color='primary'
                                            size='small'
                                            disabled={disabled || isLoadingAssetGlobal}
                                        >
                                            Select files
                                            <FileUploadIcon color={(disabled || isLoadingAssetGlobal) ? 'netrual' : 'primary'} />
                                            <input
                                                type="file"
                                                multiple
                                                hidden
                                                accept={exts}
                                                onChange={(event) => handleAssetsInput(event, folder)}
                                                onClick={(event) => {
                                                    event.target.value = null //per poter selezionare lo stesso file di seguito
                                                }}

                                            />
                                        </Button>

                                        {/* <Box> <LinearProgress variant="determinate" value={40} /></Box> */}

                                        {/*  {isLoadingAsset[folder] && <LinearProgress variant="determinate" value={assetProgress[folder]} sx={{ width: '100%', height: 25 }} />} */}
                                    </Stack>

                                </Stack>
                            )

                        })}
                    </Box>
                </Box>
            )}

            <LoadingButton
                sx={{ float: 'left', marginRight: '1em' }}
                variant="contained"
                component="label"
                color='secondary'
                size='medium'
                loading={isLoadingAssetGlobal}
                loadingIndicator={(
                    <Stack direction='row' alignItems='center'>
                        <CircularProgress color="inherit" size={16} variant='determinate' value={assetProgressGlobal} />
                        <Typography variant='body2' ml='0.5em'>{assetProgressGlobal.toFixed(0)}%</Typography>
                    </Stack>
                )}
                disabled={(action !== 'update' && (!selectedAssets['inDesign'] || (selectedAssets['inDesign'] && selectedAssets['inDesign'].length === 0))) ||
                    (action === 'update' && Object.keys(selectedAssets).length === 0)}
                onClick={handleUploadDocument}
            >

                {action !== 'update' ? 'UPLOAD' : 'UPLOAD AND CHECK'}
            </LoadingButton>
            {showUploadMessage && (
                <Typography component='div' variant='caption' color='error' sx={{ padding: '0.8em' }}>
                    The uploading process could take a few minutes. Please wait and do not close the window until it finishes.
                </Typography>
            )}



            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={openAsset}
                autoHideDuration={6000}
                onClose={handleCloseAsset}
                message='Upload completed'
                action={
                    <React.Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseAsset}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            />

            <Typography sx={{ marginBottom: '1em' }}
                variant="body2"
                color={Primarytheme.palette.neutral.dark}> </Typography>
        </Box >

    )
}

