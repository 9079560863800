import React, { useEffect, useState } from "react";
import Typography from '@mui/material/Typography';


const intervalTick = 1000; //[ms]


const Timer = ({ fetchDynamoReqId, remainingEstimated, progress, status, doOnlyValidation, startTime, endTime, ignoreEta }) => {

    const [remainingAuto, setremainingAuto] = useState(remainingEstimated); // [ms]

    // const [remainingDB, setremainingDB] = useState(remainingFromDB); // [ms]

    const setIntervalNode = () => {
        if (remainingAuto - intervalTick > (0 - intervalTick)) {
            return setInterval(
                () => tick(),
                intervalTick
            );
        }
    }


    // GESTIONE refresh timer 
    useEffect(() => {
        if (status !== 'pagination complete') {
            const intervalState = setIntervalNode();
            return () => {
                clearInterval(intervalState);
            }
        }

    }, [remainingAuto]);

    useEffect(() => {
        setremainingAuto(remainingEstimated)
    }, [remainingEstimated]);

    const tick = () => {
        //console.log('tick ', remainingEstimated, remainingAuto)
        setremainingAuto(remainingAuto - intervalTick)

    }

    //console.log('remaining auto ',remainingAuto)
    // Return react timer
    if (status === 'pagination complete') {
        if (endTime && startTime) {
            return (
                <Typography variant="caption" component="p" gutterBottom={false}>
                    <b>Pagination Time</b>: {timeFormatPretty((endTime - startTime) / 1000)}
                </Typography>
            )
        } else {
            return null
        }

    } else if (!ignoreEta && status === 'pagination in progress - running') {
        if (remainingAuto > 0) {
            return (
                <Typography variant='caption' component="p" gutterBottom={false}>
                    <b>Remaining Time</b>: {timeFormatPretty(remainingAuto / 1000)}
                </Typography>
            )
        } else if (!isNaN(remainingAuto)) {
            return (
                <Typography variant="caption" component="p" gutterBottom={false}>
                    <b>Remaining Time</b>: Calculating...
                </Typography>
            )
        } else {
            return null
        }

    } else if (!ignoreEta && status === 'pagination in progress - queued') {
        return (
            <Typography variant="caption" component="p" gutterBottom={false}>
                Queued now. Please wait...
            </Typography>
        )

    } else if (status === 'queued (validated)') {
        if (!doOnlyValidation) {
            return (
                <Typography variant="caption" component="p" gutterBottom={false}>
                    Queued now. Please wait...
                </Typography>
            ) 
        } else {
            return (
                <Typography variant="caption" component="p" gutterBottom={false}>
                   Data integration done
                </Typography>
            ) 
        }
        

    } else if (status === 'queued') {
        return (
            <Typography variant="caption" component="p" gutterBottom={false}>
                <b>Validating Data...</b>
            </Typography>
        )
    }

    else {
        return null
    }



};



export default Timer


const timeFormatPretty = (durationSeconds) => {
    // Hours, minutes and seconds
    var hrs = ~~(durationSeconds / 3600);
    var mins = ~~((durationSeconds % 3600) / 60);
    var secs = ~~durationSeconds % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";

    if (hrs > 0) {
        ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }

    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
}


