import React, { useState } from 'react';
import { Box, Paper, Typography } from '@mui/material';

import logoNotFound from './../../logos/404-webapp-01.png'

export default function Notfound({ }) {
    return (


        <Box
            sx={{textAlign: 'center'}}
            mt={20}
        >
                <img src={logoNotFound} style={{ width: "auto", height: "300px" }} />
                <Typography variant="h4" mt={5}>
                    Page not found
                </Typography>

            
        </Box >



    )

}
