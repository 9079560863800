import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import Primarytheme from './utility/theme';
import { styled } from '@mui/material/styles';

import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';


import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';

import LinearProgress from '@mui/material/LinearProgress';
import Alert from '@mui/material/Alert';

import SvgIcon from '@mui/material/SvgIcon';

import IconButton from '@mui/material/IconButton';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ImageIcon from '@mui/icons-material/Image';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

import AssetImage from './utility/AssetImage';
import AssetInDesign from './utility/AssetInDesign';

import logoNotFound from '../logos/stars.png';
import { ReactComponent as InddLogo } from '../logos/indesign_black.svg';

import { getAssetFoldersFromConf, mapTableData } from './helpers/assets';
import { getFileFromS3 } from './helpers/operations';

const axios = require('axios');

const {
    REACT_APP_X_API_KEY,
    REACT_APP_USER_ID,
    REACT_APP_USER_POOL_ID,
} = process.env;

const StyledTabs = styled(Tabs)({
    borderBottom: '0px solid #e8e8e8',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    '& .MuiTabs-indicator': {
        backgroundColor: Primarytheme.palette.primary.main,
        height: 3
    },
    '& .MuiTabs-scrollButtons': {
        opacity: 1,
        color: Primarytheme.palette.neutral.main,
        "&.Mui-disabled": {
            opacity: 0.3
        }
    }
});

const TabStyled = styled(Tab)({
    '& .Mui-selected': {
        backgroundColor: 'red'
    }


});


export default function AssetManagement({ auth, inDashboard = false }) {


    const [attachedProjects, setAttachedProjects] = useState(auth.userState.attached_projects);

    const [deleteItemFlag, setDeleteItemFlag] = useState(0);

    const [assets, setAssets] = useState({
        selectedProject: attachedProjects.length === 1 ? attachedProjects[0] : null,
        conf: null,

        init: true,
        fetching: false,
        refreshing: false,
        uploading: false,

        tag: null,
        refresh: 1,

        // navbar
        folders: [],
        selectedFolder: 0,

        // solo per images
        objectList: [],
        dataTables: [],
        dataTablesId: {},
        //selectionModel: [],

        // solo per indd
        inddList: [],



    })


    const handleChangeProject = (event, value) => {
        if (!value) handleClearProject();
        else {
            setAssets({
                folders: [],
                objectList: [],
                tag: null,
                dataTables: [],
                dataTablesId: {},
                selectedFolder: 0,
                refresh: 1,
                selectionModel: [],
                uploading: false,
                conf: null,
                inddList: [],

                fetching: true,
                selectedProject: value,
                init: false,

            });
        }
    }
    const handleClearProject = () => {
        setAssets({
            ...assets,
            selectedProject: null,
            fetching: false,
            folders: [],
            objectList: [],
            tag: null,
            dataTables: [],
            dataTablesId: {},
            selectedFolder: 0,
            refresh: 1,
            selectionModel: [],
            uploading: false,
            conf: null,
            inddList: [],
            init: true,
        })

    };

    const handleSelectFolder = (event, newValue) => {
        //console.log(newValue)
        // console.log(assets.dataTables.filter((el, index) => index === assets.selectedFolder)[0].rows.length)
        //console.log(assets.dataTables[newValue])
        setAssets(assets => {
            return {
                ...assets,
                selectedFolder: newValue,
                init: false,
            }
        })
    }

    const fetchConf = ({ project_id }) => {
        const [customer, project_name] = project_id.split('/').slice(0, 2);
        //console.log(auth.userState.email)
        return axios.get('https://api.pagination.com/vpc/projects-webapp',
            {
                headers: {
                    'user_id': auth.userState.user,
                    "content-type": "application/json",
                    "x-api-key": REACT_APP_X_API_KEY || auth.userState.ssmParams.X_API_KEY,
                    "project_id": project_id,
                    "project_name": project_name,
                    "customer": customer,
                }

            },
        );
    }


    const handleRefreshTable = ({ folderId }) => {
        setAssets({ ...assets, refreshing: true });
        fetchS3Objects1({
            src: assets.folders[folderId].s3,
            name: assets.folders[folderId].name,
            type: assets.folders[folderId].type,
            tags: assets.folders[folderId].tags,
            sharedProjectId: assets.folders[folderId].sharedProjectId,
            refresh: true,
        })
            .then(singleFolder => {
                console.log('resp fetch indd', singleFolder)
                const table = manageFetchedSingleFolder({ singleFolder, assetFolder: assets.folders[folderId] });
                const newDataTables = [...assets.dataTables];
                newDataTables[folderId] = table;
                setAssets({ ...assets, dataTables: newDataTables, refreshing: false })

            })

    }


    const fetchS3Objects1 = function ({ src, name, type, tags, sharedProjectId, refresh, obj = {}, i = 0 }) {
        // console.log(assets.selectedFolder)
        // console.log('init obj: ', obj[src], ' of iteration ', i, ' for src ', src, 'name', name);
        let action = type === 'img' ? 'query-items-dynamo' : type;
        let body = {};

        if (action === 'additional-data') {
            action = 'query-items-dynamo';
            body = {
                project_id: sharedProjectId ? sharedProjectId : src.split('/').slice(1, 3).join('/'),
                selectedFolder: name,
                fullSize: obj[src] ? obj[src].fullSize : 0,
                refresh: refresh,
                filterUser: auth.userState.policy.filterUser ? auth.userState.user : false,
            }
        }
        if (action === 'indesign') {
            action = 'query-indesign-dynamo';
            //name = 'InDesign'
        }
        if (type === 'img') {
            body = {
                project_id: sharedProjectId ? sharedProjectId : src.split('/').slice(1, 3).join('/'),
                selectedFolder: name,
                LastEvaluatedKey: obj[src] ? obj[src].LastEvaluatedKey : undefined,
                fullSize: obj[src] ? obj[src].fullSize : 0,
                refresh: refresh,
                filterUser: auth.userState.policy.filterUser ? auth.userState.user : false,
            }
        }

        return axios({
            method: "post",
            url: 'https://mjj82gfcl8.execute-api.eu-west-1.amazonaws.com/vpc/assets',
            headers: {
                'user_id': REACT_APP_USER_ID || auth.userState.ssmParams.USER_ID,
                "content-type": "application/json",
                "x-api-key": REACT_APP_X_API_KEY || auth.userState.ssmParams.X_API_KEY,
                "src": src,
                "tags": JSON.stringify(tags),
                "action": action,
                "project_id": src.split('/').slice(1, 3).join('/'),
                "selectedFolder": name,
                "LastEvaluatedKey": obj[src] ? JSON.stringify(obj[src].LastEvaluatedKey) : undefined,
                "lastId": obj[src] ? obj[src].lastId : 0,
                "fullSize": obj[src] ? obj[src].fullSize : 0,
            },
            data: { ...body }
        })
            .then(resp => {
                if (type === 'img') {
                    if (!resp.data.LastEvaluatedKey && !obj[src]) {
                        // console.log('final resp subito ', resp.data);
                        return { data: { s3KeyList: resp.data.s3KeyList, fullSize: resp.data.fullSize }, type }
                    } else if ((!obj[src] && resp.data.LastEvaluatedKey) || (obj[src] && resp.data.LastEvaluatedKey)) {
                        obj[src] = {
                            fullSize: resp.data.fullSize,
                            s3KeyList: obj[src] ? [...obj[src].s3KeyList, ...resp.data.s3KeyList] : resp.data.s3KeyList,
                            startAfter: resp.data.lastKey,
                            src,
                            type,
                            tags,
                            LastEvaluatedKey: resp.data.LastEvaluatedKey ? resp.data.LastEvaluatedKey : undefined,
                        }
                        // console.log('obj updated ', obj, ' of iteration ', i);

                        i++;
                        // recusrive call
                        return fetchS3Objects1({ src, type, name, tags, sharedProjectId, refresh: refresh, obj, i });
                    } else {

                        // return with the updated data
                        obj[src] = {
                            fullSize: resp.data.fullSize,
                            s3KeyList: obj[src] ? [...obj[src].s3KeyList, ...resp.data.s3KeyList] : resp.data.s3KeyList,
                            startAfter: resp.data.lastKey,
                            src,
                            type,
                            tags,
                            LastEvaluatedKey: resp.data.LastEvaluatedKey ? resp.data.LastEvaluatedKey : 0,
                        }
                        // console.log('final object ', obj);

                        return { data: { s3KeyList: obj[src].s3KeyList, fullSize: obj[src].fullSize }, type }
                    }
                } else if (type === 'indesign') {
                    // return as before
                    if (!resp.data.LastEvaluatedKey && !obj[src]) {
                        // console.log(name, 'return subito indesign', resp.data)
                        return { data: resp.data, type }
                    } else if ((!obj[src] && resp.data.LastEvaluatedKey) || (obj[src] && resp.data.LastEvaluatedKey)) {
                        // manage receiving tags
                        let tagsToObj = {};
                        if (obj[src]) {
                            const oldTags = obj[src].tagOrderedObj;
                            const newTags = resp.data.tagOrderedObj;
                            tagsToObj = { ...obj[src].tagOrderedObj };
                            for (const key in newTags) {
                                if (oldTags[key]) {
                                    let tagsArr = [...oldTags[key], ...newTags[key]];
                                    tagsArr = tagsArr.filter((value, index, self) => index === self.findIndex((t) => (t.name === value.name)))
                                    tagsToObj[key] = [...tagsArr];
                                } else {
                                    tagsToObj[key] = [...newTags[key]];
                                }
                            }
                        } else {
                            tagsToObj = { ...resp.data.tagOrderedObj };
                        }

                        // caso particolare con last evalueted key
                        if (obj[src] && obj[src].inddList) {
                            const respInddMap = new Map();
                            resp.data.inddList.forEach(el => respInddMap.set(el.reqid, el));

                            const objInddMap = new Map();
                            obj[src].inddList.forEach(el => objInddMap.set(el.reqid, el));

                            for (const [reqid, value] of objInddMap) {
                                if (respInddMap.has(reqid)) {
                                    objInddMap.set(reqid, {
                                        ...value,
                                        fonts: [...value.fonts, ...respInddMap.get(reqid).fonts],
                                        links: [...value.links, ...respInddMap.get(reqid).links],
                                        bucket: value.bucket ? value.bucket : respInddMap.get(reqid).bucket,
                                        filename: value.filename !== 'undefined' ? value.filename : respInddMap.get(reqid).filename,
                                        idml: value.idml ? value.idml : respInddMap.get(reqid).idml,
                                        indd: value.indd ? value.indd : respInddMap.get(reqid).indd,
                                        jpg: value.jpg ? value.jpg : respInddMap.get(reqid).jpg,
                                        keyName: value.keyName ? value.keyName : respInddMap.get(reqid).keyName,
                                        lastModified: value.lastModified ? value.lastModified : respInddMap.get(reqid).lastModified,
                                        linksCheckArr: value.linksCheckArr ? value.linksCheckArr : respInddMap.get(reqid).linksCheckArr,
                                        reqid: value.reqid ? value.reqid : respInddMap.get(reqid).reqid,
                                        size: value.size ? value.size : respInddMap.get(reqid).size,
                                        tags: value.tags ? value.tags : respInddMap.get(reqid).tags,
                                        unusedLinksArr: value.unusedLinksArr ? value.unusedLinksArr : respInddMap.get(reqid).unusedLinksArr,
                                    })

                                    // cancella reqid già processati
                                    respInddMap.delete(reqid);
                                }
                            }
                            for (const [reqid, value] of respInddMap) {
                                objInddMap.set(reqid, {
                                    ...value,
                                    fonts: [...value.fonts, ...respInddMap.get(reqid).fonts],
                                    links: [...value.links, ...respInddMap.get(reqid).links],
                                    bucket: value.bucket ? value.bucket : respInddMap.get(reqid).bucket,
                                    filename: value.filename !== 'undefined' ? value.filename : respInddMap.get(reqid).filename,
                                    idml: value.idml ? value.idml : respInddMap.get(reqid).idml,
                                    indd: value.indd ? value.indd : respInddMap.get(reqid).indd,
                                    jpg: value.jpg ? value.jpg : respInddMap.get(reqid).jpg,
                                    keyName: value.keyName ? value.keyName : respInddMap.get(reqid).keyName,
                                    lastModified: value.lastModified ? value.lastModified : respInddMap.get(reqid).lastModified,
                                    linksCheckArr: value.linksCheckArr ? value.linksCheckArr : respInddMap.get(reqid).linksCheckArr,
                                    reqid: value.reqid ? value.reqid : respInddMap.get(reqid).reqid,
                                    size: value.size ? value.size : respInddMap.get(reqid).size,
                                    tags: value.tags ? value.tags : respInddMap.get(reqid).tags,
                                    unusedLinksArr: value.unusedLinksArr ? value.unusedLinksArr : respInddMap.get(reqid).unusedLinksArr,
                                })
                            }

                            obj[src].inddList = [];
                            objInddMap.forEach((value, key, map) => obj[src].inddList.push(value))
                        }

                        obj[src] = {
                            ...obj[src],
                            fullSize: (obj[src] ? Number(obj[src].fullSize) : 0) + Number(resp.data.fullSize),
                            inddList: (obj[src] && obj[src].inddList) ? obj[src].inddList : [...resp.data.inddList],
                            tagOrderedObj: tagsToObj,
                            LastEvaluatedKey: resp.data.LastEvaluatedKey ? JSON.parse(resp.data.LastEvaluatedKey) : undefined,
                        }

                        // console.log('obj updated ', obj, ' of iteration ', i);
                        // console.log(name, 'recursive indesign query', obj[src])
                        i++;
                        // recusrive call
                        return fetchS3Objects1({ src, name, type, tags, sharedProjectId, obj, i });
                    } else {
                        let tagsToObj = {};
                        if (obj[src]) {
                            const oldTags = obj[src].tagOrderedObj;
                            const newTags = resp.data.tagOrderedObj;
                            tagsToObj = { ...obj[src].tagOrderedObj };
                            for (const key in newTags) {
                                if (oldTags[key]) {
                                    let tagsArr = [...oldTags[key], ...newTags[key]];
                                    tagsArr = tagsArr.filter((value, index, self) => index === self.findIndex((t) => (t.name === value.name)))
                                    tagsToObj[key] = [...tagsArr];
                                } else {
                                    tagsToObj[key] = [...newTags[key]];
                                }
                            }

                        } else {
                            tagsToObj = { ...resp.data.tagOrderedObj };
                        }

                        // caso particolare con last evalueted key
                        if (obj[src] && obj[src].inddList) {
                            const respInddMap = new Map();
                            resp.data.inddList.forEach(el => respInddMap.set(el.reqid, el));

                            const objInddMap = new Map();
                            obj[src].inddList.forEach(el => objInddMap.set(el.reqid, el));

                            for (const [reqid, value] of objInddMap) {
                                if (respInddMap.has(reqid)) {
                                    objInddMap.set(reqid, {
                                        ...value,
                                        fonts: [...value.fonts, ...respInddMap.get(reqid).fonts],
                                        links: [...value.links, ...respInddMap.get(reqid).links],
                                        bucket: value.bucket ? value.bucket : respInddMap.get(reqid).bucket,
                                        filename: value.filename !== 'undefined' ? value.filename : respInddMap.get(reqid).filename,
                                        idml: value.idml ? value.idml : respInddMap.get(reqid).idml,
                                        indd: value.indd ? value.indd : respInddMap.get(reqid).indd,
                                        jpg: value.jpg ? value.jpg : respInddMap.get(reqid).jpg,
                                        keyName: value.keyName ? value.keyName : respInddMap.get(reqid).keyName,
                                        lastModified: value.lastModified ? value.lastModified : respInddMap.get(reqid).lastModified,
                                        linksCheckArr: value.linksCheckArr ? value.linksCheckArr : respInddMap.get(reqid).linksCheckArr,
                                        reqid: value.reqid ? value.reqid : respInddMap.get(reqid).reqid,
                                        size: value.size ? value.size : respInddMap.get(reqid).size,
                                        tags: value.tags ? value.tags : respInddMap.get(reqid).tags,
                                        unusedLinksArr: value.unusedLinksArr ? value.unusedLinksArr : respInddMap.get(reqid).unusedLinksArr,
                                    })

                                    // cancella reqid già processati
                                    respInddMap.delete(reqid);
                                }
                            }

                            for (const [reqid, value] of respInddMap) {
                                objInddMap.set(reqid, {
                                    ...value,
                                    fonts: [...value.fonts, ...respInddMap.get(reqid).fonts],
                                    links: [...value.links, ...respInddMap.get(reqid).links],
                                    bucket: value.bucket ? value.bucket : respInddMap.get(reqid).bucket,
                                    filename: value.filename !== 'undefined' ? value.filename : respInddMap.get(reqid).filename,
                                    idml: value.idml ? value.idml : respInddMap.get(reqid).idml,
                                    indd: value.indd ? value.indd : respInddMap.get(reqid).indd,
                                    jpg: value.jpg ? value.jpg : respInddMap.get(reqid).jpg,
                                    keyName: value.keyName ? value.keyName : respInddMap.get(reqid).keyName,
                                    lastModified: value.lastModified ? value.lastModified : respInddMap.get(reqid).lastModified,
                                    linksCheckArr: value.linksCheckArr ? value.linksCheckArr : respInddMap.get(reqid).linksCheckArr,
                                    reqid: value.reqid ? value.reqid : respInddMap.get(reqid).reqid,
                                    size: value.size ? value.size : respInddMap.get(reqid).size,
                                    tags: value.tags ? value.tags : respInddMap.get(reqid).tags,
                                    unusedLinksArr: value.unusedLinksArr ? value.unusedLinksArr : respInddMap.get(reqid).unusedLinksArr,
                                })
                            }

                            obj[src].inddList = [];
                            objInddMap.forEach((value, key, map) => obj[src].inddList.push(value))
                        }

                        obj[src] = {
                            ...obj[src],
                            fullSize: Number(resp.data.fullSize),
                            inddList: obj[src].inddList ? obj[src].inddList : [...resp.data.inddList],
                            tagOrderedObj: tagsToObj,
                            LastEvaluatedKey: resp.data.LastEvaluatedKey ? JSON.parse(resp.data.LastEvaluatedKey) : undefined,
                        }

                        return { data: { ...obj[src] }, type }
                    }
                } else {
                    // return as before
                    return { data: resp.data, type }
                }
            })
            .catch(err => {
                //console.log(err)
                return (err)
            });

    }

    const deleteFileS3 = ({ keyList }) => {
        // console.log('delete ', keyList)
        // console.log('sharedProjectId', assets.folders[assets.selectedFolder].sharedProjectId)
        const sharedProjectId = (assets.folders[assets.selectedFolder] && assets.folders[assets.selectedFolder].sharedProjectId) ? assets.folders[assets.selectedFolder].sharedProjectId : undefined;
        const uri = 'https://mjj82gfcl8.execute-api.eu-west-1.amazonaws.com/vpc/assets';
        // console.log(uri);
        return axios({
            method: "post",
            url: uri,
            headers: {
                'user_id': REACT_APP_USER_ID || auth.userState.ssmParams.USER_ID,
                "content-type": "application/json",
                "x-api-key": REACT_APP_X_API_KEY || auth.userState.ssmParams.X_API_KEY,
                "action": "delete"
            },
            data: {
                keyList,
                sharedProjectId
            }
        })
            .then(resp => {
                // devo riscaricare assets, fa un refresh
                setDeleteItemFlag(deleteItemFlag + 1);
                // handleRefreshTable({ folderId: assets.selectedFolder })
                setAssets(assets => {
                    return { ...assets, refresh: -assets.refresh, selectionModel: [] }
                })

            })
            .catch(err => {
                console.log(err)
            });
    }

    const renderCell = (name) => {
        return (param) => {
            const [bucket, key] = param.value.split('###');
            return (
                <Stack direction='row'
                    justifyContent="center"
                    alignItems="center"
                    spacing={1} >
                    <IconButton size="small" onClick={() => getFileFromS3({ bucket, key, ssmParams: auth.userState.ssmParams })} >
                        <OpenInNewIcon />
                    </IconButton>
                    {name !== 'Links' && name !== 'Document fonts' && name !== 'InDesign IDML' && (
                        <IconButton size="small" onClick={() => deleteFileS3({ keyList: [param.value] })} >
                            <DeleteIcon />
                        </IconButton>
                    )}
                </Stack >
            )
        }
    }

    const manageFetchedSingleFolder = ({ singleFolder, assetFolder }) => {
        let table;
        // console.log('manageSIngleFOlderTable', singleFolder)
        if (singleFolder.type === 'img' || singleFolder.type === 'additional-data') {
            table = mapTableData({ name: assetFolder.name, type: singleFolder.type, S3rows: singleFolder.data.s3KeyList, size: singleFolder.data.fullSize, renderCell })
        }
        // se è indesign, la folder contiene la proprietà inddList + proprietà tagOrderObj
        else if (singleFolder.data.inddList) {
            // la data table di indesign folder è un array di indd {indd, idml, fonts, links} + proprietà tagOrderedObj usata per pre renderizzare i filtri
            table = singleFolder.data.inddList.map(doc => {
                //console.log(doc);
                if (doc.indd) {
                    doc.indd.width = 250;
                    doc.indd.prefix = assetFolder.name;
                    doc.idml = mapTableData({ name: 'InDesign IDML', type: singleFolder.type, S3rows: [{ ...doc.indd, Name: doc.indd.name.split('/').pop() }], ignoreCols: new Set(['Size', 'Last Modified']), renderCell });
                    doc.fonts = mapTableData({ name: 'Document fonts', type: singleFolder.type, S3rows: doc.fonts, ignoreCols: new Set(['Size', 'Last Modified']), renderCell });
                    doc.links = mapTableData({ name: 'Links', type: singleFolder.type, S3rows: doc.links, ignoreCols: new Set(['Size', 'Last Modified']), renderCell });
                    return doc
                } else {
                    // il file indesign è mal caricato, ritorna una cosa fake e ignoralo
                    return { indd: { Name: 'fake' }, pdf: 'fake', type: 'indesign', tags: ['tag'], }
                }
            })

            // add tagobj come proprietà a parte, per filterare i tag
            table.tagOrderedObj = singleFolder.data.tagOrderedObj;
        }
        else table = { name: 'fake', type: 'fake', setup: 'fake' }
        return table
    }

    // retrieve conf after selected project
    useEffect(() => {
        if (assets.selectedProject) {
            setAssets({ ...assets, refreshing: true })

            fetchConf({ project_id: assets.selectedProject })
                .then(response => {
                    const projectConf = response.data.projectConf;
                    const assetFolders = getAssetFoldersFromConf(projectConf, auth.userState);
                    //  setAssets({ ...assets, folders: assetFolders, conf: projectConf })
                    return { assetFolders, conf: response.data.projectConf }
                })
                .then(({ conf, assetFolders }) => {
                    // scarico tutti i file
                    const promises = [];
                    for (const src of assetFolders) {
                        // console.log('select folder ', src.s3, 'fetching ', src.type, 'src', src)
                        promises.push(fetchS3Objects1({ src: src.s3, name: src.name, type: src.type, tags: src.tags, sharedProjectId: src.sharedProjectId }))
                    }
                    Promise.all(promises)
                        .then(folderResList => {
                            //console.log(folderResList)
                            const dataTables = folderResList.map((singleFolder, index) => {
                                return manageFetchedSingleFolder({ singleFolder, assetFolder: assetFolders[index] });
                            })
                            //console.log('data tables ', dataTables)
                            setAssets({ ...assets, folders: assetFolders, dataTables, conf, fetching: false, refreshing: false })
                        })
                        .catch(err => console.log(err))

                })
        }
    }, [assets.selectedProject, deleteItemFlag]); //, assets.refresh]);

    // update project list
    useEffect(() => {
        setAttachedProjects(auth.userState.attached_projects)
    }, [auth.userState.attached_projects]);

    return (
        <Box sx={{ marginTop: '10em', marginBottom: 0, marginLeft: '2em', marginRight: '2em' }}>
            <AppBar position="fixed" sx={{ top: '3em', zIndex: 1, }} >
                <Stack direction='row' spacing={8} justifyContent='flex-start' alignItems='flex-start'
                    sx={{ paddingTop: '1em', paddingLeft: '32px', paddingRight: '32px', background: 'white' }}
                >
                    {/*   <IconButton onClick={refreshPage} > <Refresh /> </IconButton> */}
                    <Autocomplete id="project-selector"
                        disablePortal
                        options={attachedProjects}
                        sx={{ width: 'fit-content', minWidth: 350 }}
                        clearOnEscape
                        defaultValue={attachedProjects.length === 1 ? attachedProjects[0] : ''}
                        value={assets.selectedProject}
                        onChange={handleChangeProject}
                        size='small'
                        renderInput={(params) => <TextField {...params} label="project id" />}
                    />
                    {(assets.dataTables.length === 0 && assets.fetching) && (
                        <Stack direction='row' spacing={1}>
                            <Skeleton animation="wave" width={150} height={50} />
                            <Skeleton animation="wave" width={150} height={50} />
                        </Stack>
                    )}
                    {!(assets.dataTables.length === 0 && assets.fetching) && (
                        <StyledTabs
                            value={assets.selectedFolder}
                            onChange={handleSelectFolder}
                            variant="scrollable"
                            scrollButtons={assets.folders.length > 0 ? "auto" : false}
                        >
                            {assets.folders.map(f => <TabStyled key={f.name}
                                label={
                                    <Stack direction='row' alignItems={'center'} spacing={1}>
                                        {SetIcon(f.type)}
                                        <Typography variant='h6' >
                                            {assets.dataTables[assets.selectedFolder] && f.name === assets.dataTables[assets.selectedFolder].name ? <b>{f.name}</b> : f.name}
                                        </Typography>
                                    </Stack>
                                }
                                sx={{ textTransform: 'none', color: Primarytheme.palette.neutral.main, pt: '0.3em', pb: 0 }}
                            />)}
                        </StyledTabs>
                    )}
                </Stack>
                {assets.refreshing && <LinearProgress variant='indeterminate' sx={{ height: 2 }} />}
            </AppBar>



            <Box >
                {assets.selectedProject && (
                    <Box >
                        {assets.fetching && (
                            <Box mt='10em'>
                                <Skeleton animation="wave" />
                                <Skeleton variant="rectangular" animation="wave" height={100} />
                            </Box>
                        )}
                        {!assets.fetching && assets.dataTables.length === 0 && assets.init === false && (
                            <Box sx={{ textAlign: 'center' }}
                                mt={20}
                            >
                                {/*   <Alert elevation={0} severity="info" sx={{ marginTop: '0.5em', display: 'inline-flex', pr: '2em' }}>The project doesn't support assets upload</Alert> */}
                                <img src={logoNotFound} style={{ width: "auto", height: "301px" }} />
                                <Typography variant="h6" mt={5}>
                                    The project doesn't support assets upload
                                </Typography>


                            </Box >
                        )}
                        {assets.dataTables[assets.selectedFolder] &&
                            (assets.dataTables[assets.selectedFolder].type === 'img' || assets.dataTables[assets.selectedFolder].type === 'additional-data') && (
                                <AssetImage key={assets.dataTables[assets.selectedFolder].name}
                                    auth={auth} conf={assets.conf}
                                    handleRefreshTable={() => handleRefreshTable({ folderId: assets.selectedFolder })}
                                    dataTable={assets.dataTables[assets.selectedFolder]}
                                    folderObjList={assets.folders}
                                />
                            )}
                        {assets.dataTables[assets.selectedFolder] && !assets.dataTables[assets.selectedFolder].type && (
                            <AssetInDesign key={assets.dataTables[assets.selectedFolder].name}
                                auth={auth} conf={assets.conf}
                                handleRefreshTable={() => handleRefreshTable({ folderId: assets.selectedFolder })}
                                dataTable={assets.dataTables[assets.selectedFolder]}
                                tagsKeys={assets.folders[assets.selectedFolder].tags}
                                format={assets.folders[assets.selectedFolder].format}
                                folderName={assets.folders[assets.selectedFolder].name}
                                folderPath={assets.folders[assets.selectedFolder].s3}
                                checkLinks={assets.folders[assets.selectedFolder].checkLinks}
                            />
                        )}
                    </Box>
                )}
                {!assets.selectedProject && (
                    <Stack direction='row' spacing={2} alignItems='center'>
                        <Typography variant='h6' color='primary' > <b>Select the project</b></Typography>
                        <ArrowUpwardIcon size='large' color='primary' />
                    </Stack>
                )}
            </Box>
        </Box >
    )


}


function SetIcon(type) {
    if (type === 'img') return <ImageIcon fontSize='small' />
    else if (type === 'indesign') return <SvgIcon color='inherit' fontSize='small' component={InddLogo} viewBox="0 0 24 24"></SvgIcon>
    else return <InsertDriveFileIcon fontSize='small' />
}