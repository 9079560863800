
import { useEffect, useState, } from 'react';

import Box from '@mui/material/Box';

import { styled } from '@mui/material/styles';


import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';



import Stack from '@mui/material/Stack';
import Primarytheme from '../utility/theme';

import Typography from '@mui/material/Typography';
import Tooltip from "@mui/material/Tooltip";
import HelpIcon from '@mui/icons-material/Help';


import IconButton from '@mui/material/IconButton';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

import CloseIcon from '@mui/icons-material/Close';



import GenericMultiUpload from './GenericMultiUpload';



const filter = createFilterOptions();
const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};


export default function AssetUploadNewIndd({ editTags = false, uploadOpen, folderName, tagsKeys, tagOrderedObj, format, auth, bucket, project_id, folderPath, checkLinks, handleUploadDialogClose, handleRefreshTable }) {

    const [uploadInddTag, setUploadInddTag] = useState(tagsKeys.map(t => { return { name: t, value: '' } }))

    const handleChangeInddTag = (tag, value) => {
        const tagList = uploadInddTag.map(t => {
            if (t.name === tag) {
                t.value = value ? value.name || value : value
            }
            return t
        });

        setUploadInddTag(tagList)
    }

    // clean tags when changing tab folder
    useEffect(() => {
        setUploadInddTag(tagsKeys.map(t => { return { name: t, value: '' } }))
    }, [tagsKeys]);


    return <Dialog
        disableEscapeKeyDown={true}
        fullWidth
        maxWidth='md'
        onClose={handleUploadDialogClose}
        aria-labelledby="customized-dialog-title"
        open={uploadOpen.isOpen}
    >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleUploadDialogClose}>
            {folderName}
        </BootstrapDialogTitle>
        <DialogContent dividers>
            <Typography component='div' variant='body'>
                Enter the tags by which you want to identify the static document.
                <Tooltip title={
                    <Typography variant='caption' >
                        Tags are used during the Pagination process to pick up the correct static document.
                    </Typography>}>
                    <HelpIcon sx={{ fontSize: '1em', ml: '0.5em', mb: '-0.2em', color: Primarytheme.palette.neutral.main }} />
                </Tooltip>
            </Typography>

            <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                {tagsKeys.map(tag => {
                    if (tagsKeys.length <= 1) return (
                        <TextField
                            key={tag}
                            label={tag}
                            //disabled={}
                            //helperText={}
                            value={uploadInddTag.filter(t => t === tag)[0]}
                            size='small'
                            onChange={(event) => handleChangeInddTag(tag, event.target.value)}
                            sx={{ mr: '1em', mt: '1em' }}
                        //variant="filled"
                        />
                    )
                    // menu a tendina che pesca dai tag esistenti
                    else return (
                        < Autocomplete
                            key={tag}
                            size='small'
                            value={uploadInddTag.filter(t => t === tag)[0]}
                            onChange={(event, newValue) => {
                                if (typeof newValue === 'string') {
                                    handleChangeInddTag(tag, newValue)
                                } else if (newValue && newValue.inputValue) {
                                    // Create a new value from the user input
                                    handleChangeInddTag(tag, newValue.inputValue)
                                } else {
                                    handleChangeInddTag(tag, newValue)
                                }
                            }}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);

                                const { inputValue } = params;
                                // Suggest the creation of a new value
                                const isExisting = options.some((option) => inputValue === option.name);
                                if (inputValue !== '' && !isExisting) {
                                    filtered.push({
                                        inputValue,
                                        name: `Add "${inputValue}"`,
                                    });
                                }

                                return filtered;
                            }}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            id={tag}
                            options={tagOrderedObj[tag]}
                            getOptionLabel={(option) => {
                                // Value selected with enter, right from the input
                                if (typeof option === 'string') {
                                    return option;
                                }
                                // Add "xxx" option created dynamically
                                if (option.inputValue) {
                                    return option.inputValue;
                                }
                                // Regular option
                                return option.name;
                            }}
                            renderOption={(props, option) => <li {...props}>{option.name}</li>}
                            sx={{ mr: '1em', mt: '1em', minWidth: 200, flexGrow: 1, maxWidth: '31%' }}
                            freeSolo
                            renderInput={(params) => (
                                <TextField {...params} label={tag} />
                            )}
                        />)
                }
                )}
            </Box>


            <Typography component='div' variant='body' color={uploadInddTag.filter(el => el.value === '').length !== 0 ? 'textSecondary' : undefined} mt='1.5em'>
                Please upload the contents of an InDesign Package.
                <IconButton size="small" aria-label="close" color="inherit" onClick={() => window.open('https://support.pagination.com/portal/en/kb/articles/package-indesign-files')} >
                    <HelpIcon sx={{ fontSize: 'medium', color: Primarytheme.palette.neutral.main }} />
                </IconButton>

            </Typography>



            <GenericMultiUpload
                auth={auth.userState}
                bucket={bucket}
                project_id={project_id}
                folderObj={{
                    "inDesign": `${folderPath}/${uploadOpen.reqIdDoc}`,
                    Links: `${folderPath}/${uploadOpen.reqIdDoc}/Links`,
                    "Document fonts": `${folderPath}/${uploadOpen.reqIdDoc}/Document fonts`,
                    "Pdf": `${folderPath}/${uploadOpen.reqIdDoc}`,
                }}
                format={format}
                uploadTags={uploadInddTag}
                refreshTable={handleRefreshTable}
                closeDialog={handleUploadDialogClose}
                disabled={uploadInddTag.filter(el => el.value === '').length !== 0}
                folderName={folderName}
                checkLinks={checkLinks}
            //deleteDocument={() => deleteDocumentS3(`${folderPath}/${uploadOpen.reqIdDoc}`)}

            //refresh={refreshPage}
            />

        </DialogContent>

    </Dialog>
}