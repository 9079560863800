import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';





export default function LoadingPage() {

    const size = 80;

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                m: '32px',
                mt: '4em',
                width: '100%',
                height: '80vh',

                /*                 '& > :not(style)': {
                                    m: '32px',
                                    mt: '4em',
                                    width: '30%',
                                    height: '30vh',
                                }, */
            }}
        >
            <Paper component={Box}
                width={size}
                height={size}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}

            >
                <CircularProgress size={size*0.7} sx={{}} />
            </Paper>

        </Box>
    );
}