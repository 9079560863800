import { useHistory } from 'react-router-dom';
import { Box, Paper, Button, Typography,Stack } from '@mui/material';

import logo from './../../logos/Logo_Cerchio_1000px.svg'

export default function ForgotPasswordConfirm() {

    const history = useHistory();

    const redirect = (dst) => {
        history.push(dst);
    }


    return (
        <Paper sx={{ width: '60%', minWidth: '30em', maxWidth: '40em', m: 'auto', marginTop: '10%', paddingTop: '2em' }} elevation={3}>
            <Stack

                direction='column'
                alignItems='center'
            >
                {/*  <FormErrors formerrors={credentialsForm.errors} /> */}

                <img src={logo} height="100" alt="pagination logo" />

                <Typography sx={{ m: '2em' }}>
                    Your password has been changed!
                </Typography>


                <Button color="primary" variant='contained' onClick={() => redirect('/login')} sx={{marginBottom:'2em'}}>
                    Go back to Login
                </Button>

            </Stack >



        </Paper>

    );
}