
import { useEffect, useState, } from 'react';

import Box from '@mui/material/Box';

import { styled } from '@mui/material/styles';


import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';



import Stack from '@mui/material/Stack';
import Primarytheme from '../utility/theme';

import Typography from '@mui/material/Typography';
import Tooltip from "@mui/material/Tooltip";
import HelpIcon from '@mui/icons-material/Help';


import IconButton from '@mui/material/IconButton';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

import CloseIcon from '@mui/icons-material/Close';



import GenericMultiUpload from './GenericMultiUpload';



const filter = createFilterOptions();
const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};


export default function AssetUpdateNewIndd({ openUpdate, handleCloseUpdate, document, folderName, tagsKeys, format, auth, bucket, project_id, folderPath, checkLinks, handleRefreshTable, setLinks }) {

    const [uploadInddTag, setUploadInddTag] = useState(tagsKeys.map(t => { return { name: t, value: document.tags[t] } }))


    return <Dialog
        disableEscapeKeyDown={true}
        fullWidth
        maxWidth='md'
        onClose={handleCloseUpdate}
        aria-labelledby="customized-dialog-title"
        open={openUpdate}
    >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseUpdate}>
            {folderName} update
        </BootstrapDialogTitle>
        <DialogContent dividers>

            <GenericMultiUpload
                auth={auth.userState}
                bucket={bucket}
                project_id={project_id}
                folderObj={{
                    "inDesign": `${folderPath}/${document.reqid}`,
                    Links: `${folderPath}/${document.reqid}/Links`,
                    "Document fonts": `${folderPath}/${document.reqid}/Document fonts`
                }}
                format={format}
                uploadTags={uploadInddTag}
                refreshTable={handleRefreshTable}
                closeDialog={handleCloseUpdate}
                action={'update'}
                disabled={uploadInddTag.filter(el => el.value === '').length !== 0}
                setLinks={setLinks}
                folderName={folderName}
                checkLinks={checkLinks}
            //deleteDocument={() => deleteDocumentS3(`${folderPath}/${document.reqid}`)}

            //refresh={refreshPage}
            />

        </DialogContent>

    </Dialog>
}