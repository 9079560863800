import Primarytheme from './theme';
import React, { useState, useEffect } from 'react';


import RecapLaunch from './RecapLaunch';
import DataUpload from './DataUpload';
import Options from './Options'
import CoversIndesign from './CoversIndesign'
import AssetManagementDashboard from './AssetManagementDashboard';
import AssetsLaunch from './AssetsLaunch';
import AdditionalDataLaunch from './AdditionalDataLaunch';

import Box from '@mui/material/Box';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Autocomplete from '@mui/material/Autocomplete';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Fade from "@mui/material/Fade";

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Tooltip from "@mui/material/Tooltip";

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HelpIcon from '@mui/icons-material/Help';


import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';

import Process from './Process';
import AlertDialog from './AlertDialog';
import stepsObj from '../helpers/steps';

const axios = require('axios');

const {
    REACT_APP_X_API_KEY,
    REACT_APP_USER_ID,
    REACT_APP_USER_POOL_ID,
    REACT_APP_invokeUrl
} = process.env;

const stepRaw = [
    stepsObj.project,
    stepsObj.data,
    stepsObj.launch
];

export default function Launch({ auth, setParentForceRefresh }) {

    const [attachedProjects, setAttachedProjects] = useState(auth.userState.attached_projects);

    const [conf, setConf] = useState({});

    const [launch, setLaunch] = useState({
        selectedProject: attachedProjects.length === 1 ? attachedProjects[0] : null,
        fetching: false,
        tag: null,
        dataFormat: ['.csv', '.xlsx'],
        steps: stepRaw,
        activeStep: 0,
        isReady: false,
        isLoading: false,
        isLoaded: false,
        selectedFile: null,
        isTriggering: false,
        projectData: {},
        openSnack: false,
        launchMessage: '',
        error: undefined,
        firstStep: false,
    })

    const [snack, setSnack] = useState({
        open: false,
        launchMessage: ''
    })

    const [drawerOpen, setDrawerOpen] = useState(false);

    const [stepAppear, setStepAppear] = useState({
        'Project': true,
        'Process': false,
        'Data': true,
        'Static Pages': false,
        'Options': false,
        'Launch': true
    });

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen)
    }
    const handleNext = () => {
        if (launch.activeStep === 0) {
            setLaunch({ ...launch, firstStep: true, activeStep: launch.activeStep + 1 });
        } else {
            setLaunch({ ...launch, activeStep: launch.activeStep + 1 })
        }
    };

    const handleBack = () => {
        setLaunch(launch => { return { ...launch, activeStep: launch.activeStep - 1, } })
    };

    const handleAbort = () => {
        handleStopProcess({ reqId: launch.projectData.reqId, project_id: conf.project_id })
            .then(resp => console.log(resp.data))
            .catch(err => console.log(err))
        handleClearProject();
        setLaunch(launch => { return { ...launch, activeStep: 0, } })
    }

    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') return;
        setSnack({ open: false, launchMessage: '' });
    };

    const handleChangeProject = (event, value) => {
        if (!value) handleClearProject();
        else setLaunch({ ...launch, fetching: true, selectedProject: value, error: undefined });
    };

    const handleChangeTag = (event) => {
        const tag = event.target.value;

        let flag_adminShowOptions = auth.userState.isAdmin;

        // change data upload format
        const dataFormat = conf.dataFormat ? conf.dataFormat[tag] || conf.dataFormat.default || launch.dataFormat : launch.dataFormat;

        const realPagOpts = conf.paginationOptions ? conf.paginationOptions.filter(opt => !opt.tags || opt.tags.indexOf(tag) !== -1) : [];
        const flag_isCurrOpts = launch.steps.filter(el => el.label === 'Options').length > 0;
        const updatedSteps = [...launch.steps];

        // change options choice
        if (realPagOpts.length > 0) {
            setConf(conf => {
                conf.selectedPaginationOptions = realPagOpts
                return conf
            })
        }
        // reset options step
        if (!flag_adminShowOptions && realPagOpts.length > 0 && !flag_isCurrOpts) {
            updatedSteps.splice(-1, 0, stepsObj.options)
        }
        else if (!flag_adminShowOptions && realPagOpts.length === 0 && flag_isCurrOpts) updatedSteps.splice(-2, 1);

        //console.log('change options ', realPagOpts.length, flag_isCurrOpts)
        //console.log('data format ', dataFormat, conf.dataFormat)

        setLaunch(launch => {
            return {
                ...launch, tag,
                dataFormat,
                steps: updatedSteps,
                isLoading: false, isLoaded: false, selectedFile: null
            }
        })
    }

    const handleClearProject = () => {
        setStepAppear(stepAppear => { return { ...stepAppear, 'Static Pages': false, 'Options': false, 'Process': false, } })
        setLaunch(launch => {
            return {
                ...launch,
                selectedProject: null,
                dataFormat: ['.csv', '.xlsx'],
                fetching: false,
                steps: stepRaw,
                activeStep: 0,
                isReady: false,
                isLoaded: false,
                isLoading: false,
                tag: null,
                selectedFile: null,
                isTriggering: false,
                projectData: {},
                openSnack: false,
                launchMessage: '',
                error: undefined,
                firstStep: false,
            }
        })
        setConf({})
        setDrawerOpen(false);


    };

    const handleStopProcess = ({ reqId, project_id }) => {
        return axios.delete(REACT_APP_invokeUrl || auth.userState.ssmParams.invokeUrl + '/operations', {
            headers: {
                "x-api-key": REACT_APP_X_API_KEY || auth.userState.ssmParams.X_API_KEY,
                reqId,
                project_id,
                bucket: conf.bucket,
                user_id: 'web-app'
            }
        })


    }

    const handleTrigger = () => {
        console.log('triggering')
        setLaunch(launch => { return { ...launch, isTriggering: true } });
        triggerPagination({ auth, projectData: launch.projectData, conf, tag: launch.tag })
            .then(resp => {
                //console.log(resp.data)
                setSnack({ open: true, launchMessage: resp.data.message });
                setParentForceRefresh(parentForceRefresh => parentForceRefresh + 1);
                handleClearProject()
            })
            .catch(err => {
                console.log('triggering failed ', err);
                setSnack({ open: true, launchMessage: `Launch failed. \n ${err}` });
                handleClearProject();
            })
    }


    const fetchConf = ({ project_id }) => {
        const [customer, project_name] = project_id.split('/').slice(0, 2);
        //console.log(auth.userState.email)
        return axios.get('https://api.pagination.com/vpc/projects-webapp',
            {
                headers: {
                    'user_id': auth.userState.user,
                    "content-type": "application/json",
                    "x-api-key": REACT_APP_X_API_KEY || auth.userState.ssmParams.X_API_KEY,
                    "project_id": project_id,
                    "project_name": project_name,
                    "customer": customer,
                }

            },
        );
    }

    const getOptions = (singleOpt) => {
        return new Promise((resolve, reject) => {
            auth.userState.s3.getObject({
                Bucket: singleOpt.s3List.split('/').shift(),
                Key: singleOpt.s3List.split('/').slice(1).join('/'),
            }, function (err, data) {
                if (err) reject(err);
                else resolve(JSON.parse(data.Body));
            });
        })
    }

    // update attached project once the user table is fetched
    useEffect(() => {
        //console.log('is fetching è cambiato ', auth.userState.isFetching)
        if (auth.userState.userItem) {
            setAttachedProjects(auth.userState.attached_projects)
        }
    }, [auth]);

    // retrieve conf after selected project
    useEffect(() => {
        if (launch.selectedProject) {
            fetchConf({ project_id: launch.selectedProject })
                .then(response => {
                    const projectConf = response.data.projectConf;
                    if (!projectConf.paginationOptions) projectConf.paginationOptions = [];
                    if (!projectConf.project_status) projectConf.projectStatus = "";
                    if (!projectConf.tags) projectConf.tags = [];
                    if (!projectConf.custom_tags) projectConf.custom_tags = [];
                    if (!projectConf.deactivation) projectConf.deactivation = {};
                    if (!projectConf.assetFolder || !Array.isArray(projectConf.assetFolder)) projectConf.assetFolder = [];
                    if (!projectConf.indesignFolder || !Array.isArray(projectConf.indesignFolder)) projectConf.indesignFolder = [];
                    if (!projectConf.additionalDataFolder || !Array.isArray(projectConf.additionalDataFolder)) projectConf.additionalDataFolder = [];

                    // reset parameters
                    projectConf.internalTest = auth.userState.isAdmin ? true : false;
                    projectConf.doOnlyValidation = false;

                    const updatedSteps = [...stepRaw];


                    let flag_isTag = false;
                    let flag_showOptions = auth.userState.isAdmin;
                    let flag_showCovers = false;
                    let flag_showAssets = false;
                    let flag_showAdditionalData = false;


                    if ((projectConf.tags.length > 0 || projectConf.limit_custom_tags) && updatedSteps.filter(el => el.label === 'Process').length === 0) {
                        flag_isTag = true;
                        updatedSteps.splice(1, 0, stepsObj.process)
                    }

                    if (projectConf.additionalDataFolder.filter(el => el.inLaunch).length > 0 && updatedSteps.filter(el => el.label === 'Additional data file').length === 0) {
                        //console.log(projectConf.indesignFolder)
                        flag_showAdditionalData = true;
                        updatedSteps.splice(-1, 0, stepsObj.additionalData)
                    }

                    if (projectConf.indesignFolder.filter(el => !el.automatic).length > 0 && updatedSteps.filter(el => el.label === 'Static Pages').length === 0) {
                        //console.log(projectConf.indesignFolder)
                        flag_showCovers = true;
                        updatedSteps.splice(-1, 0, stepsObj.static)
                    }

                    if (projectConf.assetFolder.filter(el => el.inLaunch).length > 0 && updatedSteps.filter(el => el.label === 'Visuals').length === 0) {
                        //console.log(projectConf.indesignFolder)
                        flag_showAssets = true;
                        updatedSteps.splice(-1, 0, stepsObj.assets)
                    }

                    if ((projectConf.paginationOptions.length > 0 || flag_showOptions) && updatedSteps.filter(el => el.label === 'Options').length === 0) {
                        updatedSteps.splice(-1, 0, stepsObj.options)
                        flag_showOptions = true;
                    }

                    setLaunch(launch => {
                        return { ...launch, fetching: false, steps: updatedSteps, dataFormat: projectConf.dataFormat ? projectConf.dataFormat.default : ['.csv', '.xlsx'] }
                    })
                    setStepAppear(stepAppear => { return { ...stepAppear, 'Additional data file': flag_showAdditionalData, 'Static Pages': flag_showCovers, 'Visuals': flag_showAssets, 'Options': flag_showOptions, Launch: true, 'Process': (projectConf.tags.length > 0 || projectConf.limit_custom_tags) } })

                    //manage options default
                    if (auth.userState.policy.filterUser && auth.userState.userItem.paginationOptions && auth.userState.userItem.paginationOptions.length) {
                        projectConf.selectedPaginationOptions = auth.userState.userItem.paginationOptions.map(opt => {
                            if (opt.component === 'form-list') {
                                for (const block of opt.list) {
                                    for (const singleBlock of block) {
                                        singleBlock.value = opt.persistent ? (singleBlock.value ? singleBlock.value : singleBlock.default) : singleBlock.default
                                    }
                                }
                            }
                            else opt.value = opt.persistent ? (opt.value ? opt.value : opt.default) : opt.default;
                            return opt
                        });
                    } else {
                        projectConf.selectedPaginationOptions = projectConf.paginationOptions.map(opt => {
                            if (opt.component === 'form-list') {
                                for (const block of opt.list) {
                                    for (const singleBlock of block) {
                                        singleBlock.value = opt.persistent ? (singleBlock.value ? singleBlock.value : singleBlock.default) : singleBlock.default
                                    }
                                }
                            }
                            else opt.value = opt.persistent ? (opt.value ? opt.value : opt.default) : opt.default;
                            return opt
                        });
                    }

                    // set list for autocomplete if s3List param is defined
                    for (const opt of projectConf.selectedPaginationOptions) {
                        if (opt.component === 'form-list') {
                            for (const block of opt.list) {
                                for (const singleOpt of block) {
                                    if (singleOpt.component === 'autocomplete') {
                                        if (singleOpt.s3List) {
                                            getOptions(singleOpt).then(data => {
                                                singleOpt.list = data;
                                                singleOpt.value = opt.persistent ? (singleOpt.value ? singleOpt.value : data[0]) : data[0];
                                            })
                                        }
                                    }
                                }
                            }
                        }
                        if (opt.component === 'autocomplete') {
                            if (opt.s3List) {
                                getOptions(opt).then(data => {
                                    opt.list = data;
                                    opt.value = opt.persistent ? (opt.value ? opt.value : data[0]) : data[0];
                                })
                            }
                        }
                    }

                    // manage covers final value
                    projectConf.selectedCovers = [];
                    const filteredIndesign = projectConf.indesignFolder.filter(el => !el.automatic);
                    filteredIndesign.forEach(cover => {
                        projectConf.selectedCovers.push({
                            folder: cover.name,
                            path: cover.path,
                            tags: cover.tags,
                            fullPath: '',
                            value: '',
                            noCover: cover.disableSwitch,
                            required: cover.required,
                            automatic: cover.automatic,
                        })
                    })

                    // manage asset folders for final value
                    projectConf.selectedAssets = [];
                    const filteredAssets = projectConf.assetFolder.filter(el => el.inLaunch);
                    filteredAssets.forEach(asset => {
                        projectConf.selectedAssets.push({
                            folder: asset.name,
                            path: asset.path,
                            ext: asset.ext,
                            fullPath: '',
                            value: '',
                            required: asset.required,
                            inLaunch: asset.inLaunch,
                            sharedProjectId: asset.sharedProjectId,
                        })
                    })

                    // manage additional data folders for final value
                    projectConf.selectedAdditionalData = [];
                    const filteredAdditionalData = projectConf.additionalDataFolder.filter(el => el.inLaunch);
                    filteredAdditionalData.forEach(additionalData => {
                        projectConf.selectedAdditionalData.push({
                            folder: additionalData.name,
                            path: additionalData.path,
                            ext: additionalData.ext,
                            fullPath: '',
                            value: '',
                            required: additionalData.required,
                            inLaunch: additionalData.inLaunch,
                            sharedProjectId: additionalData.sharedProjectId,
                        })
                    })

                    setConf(projectConf);
                })
                .catch(err => {
                    setLaunch({ ...launch, error: 'Error retrieving your project. Please contact support@pagination.com' })
                })
        }
    }, [launch.selectedProject, launch.firstStep]);


    // Component
    const StepperButtonsMove = ({ stepLabel }) => {

        let disabled, loading, onClick, color, endIcon;
        let label = 'next';

        switch (stepLabel) {
            case 'Project':
                disabled = !launch.selectedProject || !(auth.userState.isAdmin || !conf.disableLaunch);
                loading = launch.fetching;
                onClick = handleNext;
                break;
            case 'Process':
                disabled = !launch.tag;
                onClick = handleNext;
                break;
            case 'Data':
                disabled = !launch.isLoaded
                loading = launch.isLoading;
                onClick = handleNext;
                break;
            case 'Static Pages':
                disabled = conf.selectedCovers.filter(c => c.value === '' || c.value == null).length > 0
                //loading = launch.isTriggering;
                onClick = handleNext;
                break;
            case 'Launch':
                disabled = !launch.isLoaded;
                loading = launch.isTriggering;
                onClick = handleTrigger;
                color = 'secondary';
                label = 'launch';
                // endIcon = <RocketLaunchIcon />
                break;
            default:
                disabled = !launch.selectedProject;
                loading = false;
                onClick = handleNext;
                break;
        }


        if (launch.error) {
            return <Alert elevation={1} severity="error" sx={{ marginTop: '1em' }}><b>{launch.error}</b></Alert>
        }
        // if the project is disabled form the conf a message is displayed in the launch card
        if (!(auth.userState.isAdmin || !conf.disableLaunch))
            return <Alert elevation={1} severity="warning" sx={{ marginTop: '2em', marginLeft: '32px', marginRight: '32px' }}><b>{conf.disableLaunch}</b></Alert>
        else if (!(auth.userState.isAdmin || !(conf.deactivation && conf.deactivation.dateChecked && (new Date(conf.deactivation.date)).getTime() <= (new Date()).getTime()))) {
            return <Alert elevation={1} severity="warning" sx={{ marginTop: '2em', marginLeft: '32px', marginRight: '32px' }}><b>{conf.deactivation.disableLaunch}</b></Alert>
        }
        else if (!auth.userState.policy.canLaunch) {
            return <Alert elevation={1} severity="warning" sx={{ marginTop: '2em', marginLeft: '32px', marginRight: '32px' }}><b>You don't have the permission to launch a Pagination process.</b></Alert>
        }
        // usual buttons for the stepper
        else return (
            <Stack direction='column' spacing={2}>
                <Divider orientation="vertical" flexItem />
                <Stack sx={{ mb: 2 }} direction='row' justifyContent="space-between">
                    <Stack direction='row'>
                        <Button sx={{ mt: 1, mr: 1 }}
                            disabled={stepLabel === 'Project'}
                            onClick={handleBack} >
                            Back
                        </Button>
                        <AlertDialog
                            handleActionOnAgree={handleAbort}
                            title='Reset request'
                            text='Do you want to reset the request?'
                            buttonProps={{
                                sx: { mt: 1, mr: 1 },
                                disabled: stepLabel === 'Project',
                                label: 'Reset',
                                color: 'warning'
                            }}
                        />
                    </Stack>
                    <LoadingButton variant="contained" color={color}
                        loading={loading}
                        disabled={disabled}
                        onClick={onClick}>
                        {label}
                        {endIcon}
                    </LoadingButton>

                </Stack>
            </Stack>

        )
    }


    return (
        <Box>
            <Typography variant='h5'
                color={Primarytheme.palette.primary.main}
                sx={{ marginBottom: '1em' }}>
                <b>Launch</b>
            </Typography>
            <Box >
                <Stepper activeStep={launch.activeStep} orientation="horizontal" alternativeLabel>
                    {launch.steps.map((step, index) => (
                        <Fade key={step.label} in={stepAppear[step.label]} >
                            <Step sx={{
                                '& .MuiStepLabel-root .Mui-completed': {
                                    color: Primarytheme.palette.secondary.main, // circle color (COMPLETED)
                                },
                                '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                                {
                                    color: 'black', // Just text label (COMPLETED)
                                },
                                '& .MuiStepLabel-root .Mui-active': {
                                    color: Primarytheme.palette.primary.main, // circle color (ACTIVE)
                                },
                                '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
                                {
                                    color: 'black', // Just text label (ACTIVE)
                                },
                                /* '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                                    fill: 'black', // circle's number (ACTIVE)
                                }, */
                            }}>
                                <StepLabel >
                                    {step.label}
                                </StepLabel>
                            </Step>
                        </Fade>
                    ))}
                </Stepper>


                <Box sx={{ marginTop: '2em', marginLeft: '2em', marginRight: '2em' }}>
                    {launch.steps[launch.activeStep].description}

                    {launch.steps[launch.activeStep].label === 'Project' &&
                        <Autocomplete
                            disablePortal
                            id="project-selector"
                            options={attachedProjects}
                            sx={{ width: '100%', maxWidth: 400 }}
                            clearOnEscape
                            defaultValue={attachedProjects.length === 1 ? attachedProjects[0] : ''}
                            value={launch.selectedProject}
                            onChange={handleChangeProject}
                            size='small'
                            renderInput={(params) => <TextField {...params} label="project" />}
                        />
                    }
                    {launch.steps[launch.activeStep].label === 'Process' &&
                        <Process launch={launch} conf={conf} setConf={setConf} handleChangeTag={handleChangeTag} auth={auth.userState.ssmParams} />

                    }
                    {launch.steps[launch.activeStep].label === 'Data' &&
                        <DataUpload launch={launch} setLaunch={setLaunch} conf={conf} auth={auth.userState} handleStopProcess={handleStopProcess} />
                    }
                    {launch.steps[launch.activeStep].label === 'Additional data file' &&
                        <AdditionalDataLaunch conf={conf} setConf={setConf} tag={launch.tag} auth={auth.userState.ssmParams} authAll={auth.userState} />
                    }
                    {launch.steps[launch.activeStep].label === 'Static Pages' &&
                        <CoversIndesign conf={conf} setConf={setConf} tag={launch.tag} auth={auth.userState.ssmParams} authAll={auth.userState} />
                    }
                    {launch.steps[launch.activeStep].label === 'Visuals' &&
                        <AssetsLaunch conf={conf} setConf={setConf} tag={launch.tag} auth={auth.userState.ssmParams} authAll={auth.userState} />
                    }
                    {launch.steps[launch.activeStep].label === 'Options' && launch.selectedProject !== '' &&
                        <Options conf={conf} setConf={setConf} tag={launch.tag} isAdmin={auth.userState.isAdmin} auth={auth.userState.ssmParams} />
                    }
                    {launch.steps[launch.activeStep].label === 'Launch' && launch.selectedProject !== '' &&
                        <RecapLaunch launch={launch} conf={conf} />
                    }


                </Box>

                <StepperButtonsMove stepLabel={launch.steps[launch.activeStep].label} />

                <Box>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        open={snack.open}
                        autoHideDuration={6000}
                        onClose={handleCloseSnack}
                        message={snack.launchMessage}
                        action={
                            <React.Fragment>
                                <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </React.Fragment>
                        }
                    />
                </Box>
                {auth.userState.policy.canManageAsset && ([...(conf.assetFolder || []).filter(el => !el.setup), ...(conf.indesignFolder || []).filter(el => !el.setup), ...(conf.additionalDataFolder || []).filter(el => !el.setup)].length > 0) && (
                    <Accordion expanded={drawerOpen} onChange={toggleDrawer}
                        elevation={0}
                        sx={{ mt: '2em', borderTop: 0, '&:before': { display: 'none' } }} disableGutters>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            id="panel1bh-header"
                            sx={{ borderTop: 2, borderColor: Primarytheme.palette.neutral.light }}
                        >
                            <Stack direction='row' justifyContent='flex-start' alignItems='center' spacing={2}>
                                <Typography variant='h6' color={Primarytheme.palette.primary.main}>
                                    <b>Upload Assets</b>
                                </Typography>
                                <Tooltip title="Open Asset Management in a new tab">
                                    <IconButton onClick={() => window.open("/assets", "_blank")} > <OpenInNewIcon /> </IconButton>
                                </Tooltip>

                            </Stack>

                        </AccordionSummary>
                        <AccordionDetails sx={{ pb: 0 }}>
                            < AssetManagementDashboard auth={auth} project_id={conf.project_id} conf={conf} />
                        </AccordionDetails>
                    </Accordion>
                )}
            </Box>
        </Box>
    )


}



async function triggerPagination({ auth, projectData, conf, tag }) {

    const pagOptionsToSend = !conf.selectedPaginationOptions ? [] : conf.selectedPaginationOptions.map(opt => {
        return {
            ...opt,
            value: opt.value !== undefined ? opt.value : opt.default
        }
    });

    // save options in user if filterUser is active
    // not include covers, assets in launch and additional data file
    if (auth.userState.policy.filterUser) {
        if (pagOptionsToSend.length) {
            await axios.patch(`https://mjj82gfcl8.execute-api.eu-west-1.amazonaws.com/vpc/users`,
                {
                    userInfo_id: auth.userState.user,
                    paginationOptions: pagOptionsToSend,
                    action: 'updateOptions',
                    user_pool_id: REACT_APP_USER_POOL_ID || auth.userState.ssmParams.USER_POOL_ID
                },
                {
                    headers: {
                        'user_id': REACT_APP_USER_ID || auth.userState.ssmParams.USER_ID,
                        "content-type": "application/json",
                        "x-api-key": REACT_APP_X_API_KEY || auth.userState.ssmParams.X_API_KEY,
                    }

                })
        }
    }

    const staticPagesList = !conf.selectedCovers ? [] : conf.selectedCovers.map(cover => {
        return { nome: cover.folder, value: cover.tagsValues, filepath: cover.value, }
    });

    const assetsList = !conf.selectedAssets ? [] : conf.selectedAssets.map(asset => {
        return { nome: asset.folder, value: asset.value, filepath: asset.fullPath.replace('###', '/') }
    });

    const additionalDataList = !conf.selectedAdditionalData ? [] : conf.selectedAdditionalData.map(data => {
        return { nome: data.folder, value: data.value, filepath: data.fullPath.replace('###', '/') }
    });

    // attach static pages to pagination options
    pagOptionsToSend.push(...staticPagesList);
    pagOptionsToSend.push(...assetsList);
    pagOptionsToSend.push(...additionalDataList);

    // attach tag to pagination options
    if (tag) pagOptionsToSend.push({ nome: 'tag', value: tag });


    //console.log('triggering')
    return axios.post('https://api.pagination.com/vpc/projects-webapp',
        {
            "project_id": conf.project_id,
            "project_name": conf.project_name,
            "reqId": projectData.reqId,
            "email": auth.userState.email,
            "user_id": auth.user,
            "customer": conf.customer,
            "bucket": conf.bucket,
            "prefix": conf.project_id,
            "dataFolder": projectData.dataFolder,
            "key": encodeURIComponent(projectData.key),
            "projectConf": conf,
            "paginationOptions": pagOptionsToSend,
            "internalTest": conf.internalTest,
            "doOnlyValidation": conf.doOnlyValidation
        },
        {
            headers: {
                'user_id': auth.userState.user,
                "content-type": "application/json",
                "x-api-key": REACT_APP_X_API_KEY || auth.userState.ssmParams.X_API_KEY,
            }

        },
    );
}


function optionalTextStep(flag) {
    if (flag) {

        return (
            <Box sx={{ textAlign: 'center' }}>
                <Typography variant='caption' sx={{ marginBottom: '1em' }}>
                    Optional
                </Typography>
            </Box>
        )
    }
    else return null
}