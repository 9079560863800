import { CampaignOutlined } from "@mui/icons-material";

function validateForm(state) {

  const errors = { username: "", password: "", confirmPassword: "", oldPassword: "", email: "", verificationcode: '' };

  checkNotEmpty({ state, errors, listaCampi: ['username', 'email', 'verificationcode'] });
  checkPasswords({ state, errors, listaCampi: ['confirmPassword'] })

  if (state.hasOwnProperty("email")) {
    if (state.email && (state.email.indexOf('@') === -1 || state.email.indexOf('.') === -1)) {
      errors.email = 'The email is invalid';
    }
  }


  if (state.hasOwnProperty("password") && state.hasOwnProperty("confirmPassword")) {
    if (state.password === '' && state.confirmPassword === '') {
      errors.password = "";
      errors.confirmPassword = "";
    }
    else if (state.password !== state.confirmPassword) {
      errors.password = "";
      errors.confirmPassword = "Passwords fields don't match.";
    }
  }

  if (state.hasOwnProperty("newpassword") && state.hasOwnProperty("confirmPassword")) {
    if (state.newpassword === '' && state.confirmPassword === '') {
      errors.newpassword = "";
      errors.confirmPassword = "";
    }
    else if (state.newpassword !== state.confirmPassword) {
      errors.password = "";
      errors.confirmPassword = "Passwords fields don't match.";
    }
  }

  return errors
}

export default validateForm;


function checkNotEmpty({ state, errors, listaCampi }) {
  for (const campo of listaCampi) {
    if (state.hasOwnProperty(campo) && state[campo] === '') {
      errors[campo] = 'Field is empty.'
    }
  }
}

function checkPasswords({ state, errors, listaCampi }) {
  const regexLowerCase = new RegExp(/[a-z]/)
  const regexUpperCase = new RegExp(/[A-Z]/);
  const regexNumbers = new RegExp(/[0-9]/);
 // const regexSymb = new RegExp(/[\^$*.\[\]{}\(\)?\-"!@#%&\/,><\’:;|_~`]/);
  const regexWhiteSpace = new RegExp(/\s/);

  // gestito da cognito il controllo passwords
  for (const campo of listaCampi) {
    if (state.hasOwnProperty(campo)) {
      if (!regexLowerCase.test(state[campo])) {
        errors[campo] = buildErrorMessage(errors[campo], 'a lower case char')
      }
      if (!regexUpperCase.test(state[campo])) {
        console.log('missingu pper', state[campo])
        errors[campo] = buildErrorMessage(errors[campo], 'an upper case char')
      }
      if (!regexNumbers.test(state[campo])) {
        errors[campo] = buildErrorMessage(errors[campo], 'a number')
      }
/*       if (!regexSymb.test(state[campo])) {
        errors[campo] = buildErrorMessage(errors[campo], 'a special char')
      } */
      if (regexWhiteSpace.test(state[campo])) {
        errors[campo] = buildErrorMessage(errors[campo], 'no white spaces')
      }
      if (state[campo].length < 8) {
        errors[campo] = buildErrorMessage(errors[campo], 'at least 8 chars')
      }
      //console.log('validation', errors['confirmPassword'])
    }
  }
}

function buildErrorMessage(currentError, attachMessage) {
  if (currentError.length > 0) {
    currentError += ', ' + attachMessage
  }
  else {
    currentError += 'Password must have ' + attachMessage
  }
  return currentError;
}