
import React, { useEffect, useState, useMemo, useRef } from "react";
import Primarytheme from './theme';

import Box from '@mui/material/Box';

import Stack from '@mui/material/Stack';

import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Download } from "@mui/icons-material";

import LinearProgress from '@mui/material/LinearProgress';

import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import { getFileFromS3, retrieveSignedUrl } from '../helpers/operations';
import { useWindowDimension } from "../helpers/useWindowDimension";

import path from 'path';

export default function PreviewBlock({ refCard, bucket, output, thumbs, thumbnailsFolder, mainDownload, ssm }) {

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    // preview thumbnails
    const [thumbnails, setThumbnails] = useState({ isFetching: true, thumbUrl: null, isPdfValid: true, nPages: 2 })

    const urlThumbn = useMemo(() => thumbnails.thumbUrl, [thumbnails.thumbUrl]);


    // Ref
    const reference = useRef(null);

    const width1 = useWindowDimension({ refCard });

    const [height, setHeight] = useState('20em');

    const fetchThumbnails = () => {
        if (output && thumbs && !thumbnails.thumbKey) {
            retrieveSignedUrl({ key: path.join(output.replace(bucket + '/', ''), 'thumbnails', 'export_thumb.pdf'), bucket, ssmParams: ssm })
                .then(response => {
                    fetch(response.data.signedUrl)
                        .then(respFetch => {
                            if (respFetch.status !== 404) {
                                setThumbnails({
                                    thumbUrl: response.data.signedUrl,
                                    nPages: isNaN(thumbs) || thumbs === true ? 2 : thumbs,
                                    isFetching: false,
                                })
                            } else {
                                const keyPath = thumbnailsFolder
                                    .replace('z:/projects/frontend/', '')
                                    .replace('z:\\projects\\frontend', '')
                                    .replace('/' + bucket + '/', '')
                                    .replace('\\' + bucket + '\\', '')
                                    .replace(bucket + '/', '')
                                    .replace(bucket + '\\', '')
                                    .replace(/\\/g, '/')
                                retrieveSignedUrl({ key: path.join(keyPath, 'export_thumb.pdf'), bucket, ssmParams: ssm })
                                    .then(response => {
                                        setThumbnails({
                                            thumbUrl: response.data.signedUrl,
                                            nPages: isNaN(thumbs) || thumbs === true ? 2 : thumbs,
                                            isFetching: false,
                                        })
                                    })
                                    .catch(err => {
                                        setThumbnails({
                                            nPages: isNaN(thumbs) || thumbs === true ? 2 : thumbs,
                                            isFetching: false,
                                        })
                                    })
                            }
                        })
                        .catch(err => console.log('err thumbnails', err))


                })
                .catch(err => {
                    console.log('err thumbnails', err)
                })
        } else {
            setThumbnails({
                nPages: isNaN(thumbs) || thumbs === true ? 2 : thumbs,
                isFetching: false,
            })
        }
    }


    const onDocumentLoadSuccess = ({ numPages }) => {
        //console.log('document loaded')
        setNumPages(numPages);
        setPageNumber(1);
    }

    const onLoadPageSuccess = (page) => {
        if (height !== page.height) setHeight(page.height)
    }

    // da spostare dentro un use effect per evitare il warning di re render mentre ancora stava renderizzando il document
    const onErrorLoadPdfPreview = () => {
        setThumbnails({ ...thumbnails, isPdfValid: false })
    }

    const changePage = (offset) => {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    const previousPage = () => {
        changePage(-2);
    }

    const nextPage = () => {
        changePage(2);
    }

    useEffect(() => {
        fetchThumbnails()
    }, [])


    if (!thumbs && !thumbnails.thumbUrl) {
        return (
            <Box sx={{ textAlign: 'center', mb: '1em' }}>
                <Button variant="contained" startIcon={<Download />} color='secondary' disabled={mainDownload == undefined}
                    onClick={() => getFileFromS3({ key: mainDownload, bucket: bucket, ssmParams: ssm })}>
                    Get Output
                </Button>
            </Box>
        )
    }
    else return (
        <Box ref={reference} sx={{ margin: 0, }}>
            <Box sx={{ margin: 0, borderTop: 1, borderBottom: 1, borderColor: 'grey.200' }}>
                {!isNaN(width1) &&
                    <Document
                        file={urlThumbn}
                        onLoadSuccess={onDocumentLoadSuccess}
                        loading={< LinearProgress
                            sx={{ color: Primarytheme.palette.primary.main }}
                            variant="indeterminate"
                        //value={progressState.progress}
                        />}
                        onLoadError={onErrorLoadPdfPreview}
                        noData=''
                    //onLoadProgress={({ loaded, total }) => alert('Loading a document: ' + (loaded / total) * 100 + '%')}
                    >
                        <Stack direction='row' justifyContent="flex-start" sx={{ height: height }}>
                            <Page renderTextLayer={false} renderAnnotationLayer={false} loading={null} onLoadSuccess={onLoadPageSuccess} onRenderSuccess={onLoadPageSuccess} pageNumber={pageNumber} width={width1 / thumbnails.nPages} error={() => null} />
                            {thumbnails.nPages > 1 && (
                                <Page renderTextLayer={false} renderAnnotationLayer={false} loading={null} onLoadSuccess={onLoadPageSuccess} onRenderSuccess={onLoadPageSuccess} pageNumber={pageNumber + 1} width={width1 / thumbnails.nPages} error={() => null} />
                            )}
                        </Stack>
                    </Document>}
            </Box>
            {numPages > 2 && (
                <Stack direction='row' justifyContent="space-between">
                    <IconButton onClick={previousPage} disabled={pageNumber <= 2} >
                        <KeyboardArrowLeftIcon />
                    </IconButton>
                    <IconButton onClick={nextPage} disabled={pageNumber >= numPages - 1} >
                        <KeyboardArrowRightIcon />
                    </IconButton>
                </Stack>
            )}
            {numPages <= 2 && (
                <Box marginBottom={'1em'}></Box>
            )}

        </Box>
    )
}

