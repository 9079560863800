

// rendering table
export function mapTableData({ type, name, S3rows, size, ignoreCols = new Set(), renderCell }) {
    const assetHeaderObj = {
        Name: 'Name',
        Size: 'Size (byte)',
        'Last Modified': 'Last Modified (UTC)'
    };

    const columns = [
        'Name',
        'Size',
        'Last Modified',
        'fullPath'
    ];

    const table = {
        name: name,
        type: type,
        size,
        nItems: S3rows.length,
        columns: columns.filter(h => !ignoreCols.has(h)).map(h => {
            return {
                field: h,
                renderHeader: () => (
                    <strong key={h}>{h === 'fullPath' ? '' : assetHeaderObj[h]}</strong>
                ),
                flex: h === 'Name' ? 20 : h === 'fullPath' ? 2 : 6,
                renderCell: h === 'fullPath' ? renderCell(name) : undefined,
            }
        }),

        rows: S3rows,

    }
    // console.log(table);
    return table
}

export function buildFakeDataTables(assetFolders) {
    const dataTables = [];
    for (const src of assetFolders) {

        if (src.type === 'img' || src.type === 'additional-data') {
            dataTables.push({ name: src.name, type: 'img', setup: src.setup })
        }
        if (src.type === 'indesign') {
            dataTables.push([{ indd: { Name: 'fake' }, pdf: 'fake', type: 'indesign', tags: src.tags || ['tag'], }])
        }

    }
    return dataTables
}

export function getAssetFoldersFromConf(projectConf, user) {
    const assetFolders = [];
    if (projectConf.assetFolder) {
        for (const fold of projectConf.assetFolder) {
            let realPath = fold.path ? fold.path.replace('<%- bucket %>', projectConf.bucket).replace('<%- prefix %>', projectConf.prefix) : '';
            if (user.policy && user.policy.filterUser === true) realPath = realPath + '/' + user.user;
            assetFolders.push({ name: fold.name, type: 'img', s3: realPath, setup: fold.setup, sharedProjectId: fold.sharedProjectId, inLaunch: fold.inLaunch, ext: fold.ext })
        }
    }
    if (projectConf.indesignFolder) {
        for (const fold of projectConf.indesignFolder) {
            let realPath = fold.path ? fold.path.replace('<%- bucket %>', projectConf.bucket).replace('<%- prefix %>', projectConf.prefix) : '';
            if (user.policy && user.policy.filterUser === true) realPath = realPath + '/' + user.user;
            assetFolders.push({ name: fold.name, type: 'indesign', tags: fold.tags || ['tag'], s3: realPath, automatic: fold.automatic, setup: fold.setup, format: fold.format, required: fold.required })
        }
    }
    if (projectConf.additionalDataFolder) {
        for (const fold of projectConf.additionalDataFolder) {
            let realPath = fold.path ? fold.path.replace('<%- bucket %>', projectConf.bucket).replace('<%- prefix %>', projectConf.prefix) : '';
            if (user.policy && user.policy.filterUser === true) realPath = realPath + '/' + user.user;
            console.log('realPath', realPath);
            assetFolders.push({ name: fold.name, type: 'additional-data', setup: fold.setup, tags: fold.tags, s3: realPath, sharedProjectId: fold.sharedProjectId, inLaunch: fold.inLaunch, ext: fold.ext })
        }
    }
    return assetFolders;
}

const typeObj = {
    bmp: 'image/bmp',
    cod: 'image/cis-cod',
    exe: 'application/octet-stream',
    gif: 'image/gif',
    ief: 'image/ief',
    jpe: 'image/jpeg',
    jpeg: 'image/jpeg',
    jpg: 'image/jpeg',
    jfif: 'image/pipeg',
    png: 'image/png',
    svg: 'image/svg+xml',
    tif: 'image/tiff',
    tiff: 'image/tiff',
    ras: 'image/x-cmu-raster',
    cmx: 'image/x-cmx',
    ico: 'image/x-icon',
    pnm: 'image/x-portable-anymap',
    pbm: 'image/x-portable-bitmap',
    pgm: 'image/x-portable-graymap',
    ppm: 'image/x-portable-pixmap',
    rgb: 'image/x-rgb',
    xbm: 'image/x-xbitmap',
    xpm: 'image/x-xpixmap',
    xwd: 'image/x-xwindowdump',
    evy: 'application/envoy',
    fif: 'application/fractals',
    spl: 'application/futuresplash',
    hta: 'application/hta',
    doc: 'application/msword',
    dot: 'application/msword',
    pdf: 'application/pdf',
    ai: ' application/postscript',
    eps: 'application/postscript',
    ps: ' application/postscript',
    rtf: 'application/rtf',
    xla: 'application/vnd.ms-excel',
    xlc: 'application/vnd.ms-excel',
    xlm: 'application/vnd.ms-excel',
    xls: 'application/vnd.ms-excel',
    xlt: 'application/vnd.ms-excel',
    xlw: 'application/vnd.ms-excel',
    pot: 'application/vnd.ms-powerpoint',
    pps: 'application/vnd.ms-powerpoint',
    ppt: 'application/vnd.ms-powerpoint',
    wdb: 'application/vnd.ms-works',
    wks: 'application/vnd.ms-works',
    wps: 'application/vnd.ms-works',
    z: '  application/x-compress',
    tgz: 'application/x-compressed',
    cpio: 'application/x-cpio',
    csh: 'application/x-csh',
    gtar: 'application/x-gtar',
    gz: 'application/x-gzip',
    js: 'application/x-javascript',
    latex: 'application/x-latex',
    mdb: 'application/x-msaccess',
    pub: 'application/x-mspublisher',
    wri: 'application/x-mswrite',
    cdf: 'application/x-netcdf',
    nc: 'application/x-netcdf',
    pma: 'application/x-perfmon',
    zip: 'application/zip',
}
export const getContentType = (fileExt = 'exe') => typeObj[fileExt.toLowerCase()]


export function encodeTag(tag) {
    const outTag = tag
        .replace(/\(/g, '+//10') // (
        .replace(/\)/g, '+//11') // )
        .replace(/\[/g, '+//12') // [
        .replace(/\]/g, '+//13') // ]
        .replace(/%/g, '+//14') // %
        .replace(/\|/g, '+//15') // |
        .replace(/"/g, '+//16') // "
        .replace(/'/g, '+//17') // '
        .replace(/!/g, '+//18') // !
        .replace(/\?/g, '+//19') // ?
        .replace(/\^/g, '+//20') // ^
        .replace(/&/g, '+//21') // &
        .replace(/£/g, '+//22') // £
        .replace(/\$/g, '+//23') // $
        .replace(/€/g, '+//24') // €
        .replace(/\*/g, '+//25') // *
        .replace(/@/g, '+//26') // @
        .replace(/#/g, '+//27') // #
        .replace(/§/g, '+//28') // §
        .replace(/;/g, '+//29') // ;
        .replace(/,/g, '+//30') // ,
        .replace(/:/g, '+//31') // :
        .replace(/\\/g, '+//32') // \
        .replace(/°/g, '+//33') // °
        .replace(/™/g, '+//34') // °
        .replace(/®/g, '+//35') // °
    return outTag
}

export function decodeTag(tag) {
    const outTag = tag
        .replace(/\+\/\/10/g, '(')// (
        .replace(/\+\/\/11/g, ')') // )
        .replace(/\+\/\/12/g, '[') // [
        .replace(/\+\/\/13/g, ']') // ]
        .replace(/\+\/\/14/g, '%')// %
        .replace(/\+\/\/15/g, '|') // |
        .replace(/\+\/\/16/g, '"')// "
        .replace(/\+\/\/17/g, '\'')// '
        .replace(/\+\/\/18/g, '!')// !
        .replace(/\+\/\/19/g, '?') // ?
        .replace(/\+\/\/20/g, '^') // ^
        .replace(/\+\/\/21/g, '&')// &
        .replace(/\+\/\/22/g, '£')// £
        .replace(/\+\/\/23/g, '$') // $
        .replace(/\+\/\/24/g, '€')// €
        .replace(/\+\/\/25/g, '*') // *
        .replace(/\+\/\/26/g, '@')// @
        .replace(/\+\/\/27/g, '#')// #
        .replace(/\+\/\/28/g, '§')// §
        .replace(/\+\/\/29/g, ';')// ;
        .replace(/\+\/\/30/g, ',')// ,
        .replace(/\+\/\/31/g, ':')// :
        .replace(/\+\/\/32/g, '\\') // \
        .replace(/\+\/\/33/g, '°') // °   
        .replace(/\+\/\/34/g, '™') // ™   
        .replace(/\+\/\/35/g, '®') // ®                                                                                      
    return outTag
}

// formats
export const supportedLinksFormats = [
    ".jpg",
    ".png",
    ".pdf",
    ".tif",
    ".tiff",
    ".svg",
    ".ai",
    ".pdf",
    ".swg",
    ".eps",
    ".bmp",
    ".jpeg",
    ".psd",
    ".gif",
    ".dcs",
    ".idms",
    ".wmf",
    ".emf",
    ".pcx",
    ".sct",
    ".txt",
    ".doc",
    ".docx",
    ".xls",
    ".xlsx",
    ".rtf",
    ".mp4",
    ".avi",
    ".mov",
    ".mp3"
];
