
import Primarytheme from './theme';
import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import TextField from '@mui/material/TextField';
import { Snackbar } from '@mui/material';

import { Typography, Stack, FormControlLabel, Switch } from '@mui/material';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import Tooltip from "@mui/material/Tooltip";
import HelpIcon from '@mui/icons-material/Help';

const axios = require('axios');

const {
    REACT_APP_X_API_KEY,
    REACT_APP_USER_ID,
    REACT_APP_USER_POOL_ID,
    REACT_APP_UPDATE_CONF_KEY,
    REACT_APP_UPDATE_CONF_URL,
} = process.env;

const filter = createFilterOptions();

const limits = {
    row_limit: '',
    image_limit: '',
    gb_asset_limit: '',
    gb_limit_total: '50',
}

const limitInfo = {
    row_limit: {
        oder: 1,
        descr: 'maximum number of rows (SKUs) to use in a data file.',
        values: ['no limit', '1000', '4000', '20000', '50000', '100000', 'more than 100000']
    },
    image_limit: {
        order: 2,
        descr: 'maximum number of images to use in a document.',
        values: ['no limit', '200', '1000', '5000', '12500', '25000', 'more than 25000']
    },
    gb_asset_limit: {
        order: 3,
        descr: 'maximum GigaBytes to use for assets (images, InDesign, additional data).',
        values: ['no limit', '0.5', '1', '5', '10', '30', '50']
    },
    gb_limit_total: {
        order: 4,
        descr: 'maximum GigaBytes to use including outputs, data, and assets.',
        values: ['no limit', '20', '50', '100', '150', '1000', '2000']
    },
}



export default function ProjectParameters({ auth, projects, isBilling = false }) {

    const [projectParams, setProjectParams] = useState({
        project: null,
        isPutting: false,
        isGetting: false,

        folders: [],
        shared_folders: [],
        conf: {
            row_limit: 'no limit',
            image_limit: 'no limit',
            gb_asset_limit: 'no limit',
            gb_limit_total: '50',
            exclude_folders_gb_asset_limit: [],
            exclude_folders_gb_limit_total: [],
            fields_image_limit: '',
            shared_folders: [],
        }
    })

    const [snack, setSnack] = useState({
        open: false,
        creationMessage: ''
    })

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnack({
            open: false,
            creationMessage: ''
        });
    };

    const handleChangeProject = async (event, value) => {
        if (!value) {
            resetProject();
            setProjectParams({
                conf: {
                    row_limit: 'no limit',
                    image_limit: 'no limit',
                    gb_asset_limit: 'no limit',
                    gb_limit_total: '50',
                    exclude_folders_gb_asset_limit: [],
                    exclude_folders_gb_limit_total: [],
                    fields_image_limit: '',
                    shared_folders: [],
                },
                folders: [],
                shared_folders: [],
                project: undefined,
                isGetting: false
            });
        }
        else {
            setProjectParams({
                ...projectParams,
                isGetting: true,
            });
            const currentConf = await fetchConf({ project_id: value })
            const currentFolders = await fetchFolders({ prefix: `${currentConf.data.projectConf.bucket}/${currentConf.data.projectConf.prefix}` })
            console.log('prefix', `${currentConf.data.projectConf.bucket}/${currentConf.data.projectConf.prefix}`, currentFolders);
            let sharedProjectId = new Set();
            if (currentConf.data.projectConf.assetFolder) {
                for (const assetObj of currentConf.data.projectConf.assetFolder) {
                    if (assetObj.sharedProjectId) sharedProjectId.add(assetObj.path);
                }
            }
            sharedProjectId = Array.from(sharedProjectId);
            setProjectParams({
                conf: {
                    row_limit: 'no limit',
                    image_limit: 'no limit',
                    gb_asset_limit: 'no limit',
                    gb_limit_total: '50',
                    exclude_folders_gb_asset_limit: [],
                    exclude_folders_gb_limit_total: [],
                    fields_image_limit: '',
                    shared_folders: sharedProjectId,
                    ...currentConf.data.projectConf
                },
                folders: [
                    ...currentFolders.data.folders
                ],
                shared_folders: [
                    ...sharedProjectId
                ],
                project: value,
                isGetting: false
            })
        }
    }

    const fetchConf = async ({ project_id }) => {
        const [customer, project_name] = project_id.split('/').slice(0, 2);
        return axios.get('https://api.pagination.com/vpc/projects-webapp',
            {
                headers: {
                    'user_id': auth.userState.user,
                    "content-type": "application/json",
                    "x-api-key": REACT_APP_X_API_KEY || auth.userState.ssmParams.X_API_KEY,
                    "project_id": project_id,
                    "project_name": project_name,
                    "customer": customer,
                }

            },
        );
    }

    const fetchFolders = async ({ prefix }) => {
        return axios({
            method: "get",
            url: 'https://mjj82gfcl8.execute-api.eu-west-1.amazonaws.com/vpc/assets',
            headers: {
                'user_id': auth.userState.user,
                "content-type": "application/json",
                "x-api-key": REACT_APP_X_API_KEY || auth.userState.ssmParams.X_API_KEY,
                "prefix": prefix,
                "action": 'fetch-folders'
            },
        }).catch(err => {
            //console.log('err fetch folder', err);
        })
    }

    const updateParameters = async () => {
        setProjectParams({ ...projectParams, isPutting: true });
        // call lambda
        axios({
            method: "put",
            url: REACT_APP_UPDATE_CONF_URL || auth.userState.ssmParams.UPDATE_CONF_URL,
            headers: {
                'x-api-key': REACT_APP_UPDATE_CONF_KEY || auth.userState.ssmParams.UPDATE_CONF_KEY,
                'user': 'webapp',
                "content-type": "application/json",
            },
            data: projectParams,
            json: true
        }).then(response => {
            setSnack({ open: true, creationMessage: 'Parameters are updated!' })
            setProjectParams({ ...projectParams, isPutting: false });
        })
    }

    const updateKeyConf = async (key, value) => {
        const conf = {
            ...projectParams.conf,
        };
        if (key.indexOf('folders') !== -1) conf[key] = [...value];
        else if (key.indexOf('fields') !== -1) conf[key] = [...value.split(',')];
        else conf[key] = (!value || value === '') ? 'no limit' : value;
        setProjectParams({
            ...projectParams,
            conf: {
                ...conf
            }
        })
    }

    const resetProject = () => {
        setProjectParams({
            project: null,
            conf: {
                ...limits
            }
        });
    }


    return (

        <div>
            <Stack spacing={2} direction='column'>
                <Autocomplete id="project-params-selector"
                    disabled={projects.list.length === 0}
                    limitTags={5}
                    options={projects.list}
                    value={projectParams.project}
                    defaultValue='no limit'
                    onChange={handleChangeProject}
                    sx={{ width: '40%' }}
                    size='small'
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                        <TextField {...params} variant="outlined" label={projects.retrieving ? 'loading...' : 'project-ids'} placeholder="" />
                    )}
                />

                {Object.keys(limits).sort((a, b) => limitInfo[a].order - limitInfo[b].order).map((k, value) => {
                    return (
                        <Stack direction='row' spacing={2} key={k}>
                            <Autocomplete
                                id={k}
                                key={k}
                                disabled={!projectParams.project || isBilling}
                                limitTags={5}
                                options={limitInfo[k].values}
                                value={projectParams.conf[k] === '' ? 'no limit' : projectParams.conf[k]}
                                onChange={(event, value) => {
                                    if (typeof value === 'string') {
                                        updateKeyConf(k, value)
                                    } else if (value && value.inputValue) {
                                        updateKeyConf(k, value.inputValue)
                                    } else {
                                        updateKeyConf(k, value)
                                    }
                                }}
                                getOptionLabel={(option) => {
                                    // Value selected with enter, right from the input
                                    if (typeof option === 'string') {
                                        return option;
                                    }
                                    // Add "xxx" option created dynamically
                                    if (option.inputValue) {
                                        return option.inputValue;
                                    }
                                    // Regular option
                                    return option;
                                }}
                                filterOptions={(options, params) => {
                                    const filtered = filter(options, params);

                                    const { inputValue } = params;
                                    // Suggest the creation of a new value
                                    const isExisting = options.some((option) => inputValue === option);
                                    if (inputValue !== '' && !isExisting) {
                                        filtered.push({
                                            inputValue,
                                            additional: `Add "${inputValue}"`,
                                        });
                                    }

                                    return filtered;
                                }}
                                renderOption={(props, option) => <li {...props}>{option.additional ? option.additional : option}</li>}
                                freeSolo
                                selectOnFocus
                                clearOnBlur
                                handleHomeEndKeys
                                sx={{ width: '20%', color: isBilling ? 'black' : undefined }}
                                size='small'
                                renderInput={(params) => <TextField {...params} label={projectParams.isGetting ? 'loading...' : k} />}
                            />

                            {/* {(k === 'gb_asset_limit' || k === 'gb_limit_total') && (
                                    <TextField
                                        label={'exclude_folders_' + k}
                                        id={'exclude_folders_' + k}
                                        helperText={'List the folders to exclude (the path after bucket/prefix), !!! comma separated values'}
                                        value={projectParams.conf['exclude_folders_' + k]}
                                        size='small'
                                        sx={{ width: '50%' }}
                                        disabled={!projectParams.project}
                                        onChange={(event) => {
                                            updateKeyConf('exclude_folders_' + k, event.target.value)
                                        }}
                                    />
                                )} */}

                            {(k === 'gb_asset_limit' || k === 'gb_limit_total') && (
                                <Autocomplete
                                    multiple
                                    disableCloseOnSelect
                                    disabled={!projectParams.project || isBilling}
                                    id={"multiple-folders" + k}
                                    options={projectParams.folders}
                                    value={projectParams.conf['exclude_folders_' + k].length ? projectParams.conf['exclude_folders_' + k] : []}
                                    onChange={(event, values) => {
                                        updateKeyConf('exclude_folders_' + k, values)
                                    }}
                                    sx={{ width: (k === 'gb_limit_total') ? '61%' : '30%', color: isBilling ? 'black' : undefined }}
                                    size='small'
                                    getOptionLabel={(option) => option}
                                    //defaultValue={projectParams.folders}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="outlined" label={projectParams.isGetting ? 'loading...' : 'exclude_folders_' + k} placeholder="" />
                                    )}
                                />
                            )}

                            {(k === 'gb_asset_limit') && (
                                <Autocomplete
                                    multiple
                                    disableCloseOnSelect
                                    disabled={!projectParams.project || isBilling}
                                    id={"multiple-folders" + k}
                                    options={projectParams.shared_folders}
                                    value={projectParams.conf.shared_folders.length ? projectParams.conf.shared_folders : []}
                                    onChange={(event, values) => {
                                        updateKeyConf('shared_folders', values)
                                    }}
                                    sx={{ width: '30%', color: isBilling ? 'black' : undefined }}
                                    size='small'
                                    getOptionLabel={(option) => option}
                                    //defaultValue={projectParams.folders}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="outlined" label={projectParams.isGetting ? 'loading...' : 'shared_folders'} placeholder="" />
                                    )}
                                />
                            )}

                            {(k === 'image_limit') && (
                                <TextField
                                    label={'fields_' + k}
                                    id={'fields_' + k}
                                    helperText={'List the fields with images to check !!! comma separated values'}
                                    value={projectParams.conf['fields_' + k]}
                                    size='small'
                                    sx={{ width: '61%', color: isBilling ? 'black' : undefined }}
                                    disabled={!projectParams.project || isBilling}
                                    onChange={(event) => {
                                        updateKeyConf('fields_' + k, event.target.value)
                                    }}
                                />
                            )}

                            <Tooltip title={
                                <Typography variant='caption' >
                                    {limitInfo[k].descr}
                                </Typography>}>
                                <HelpIcon sx={{ fontSize: '1.5em', ml: '0.5em', color: Primarytheme.palette.neutral.main }} />
                            </Tooltip>

                        </Stack>
                    )
                })
                }

            </Stack>
            {!isBilling && (
                <LoadingButton
                    disabled={!projectParams.project}
                    loading={projectParams.isPutting}
                    //loadingPosition="start"
                    //startIcon={<PersonAddAltIcon />}
                    variant="contained"

                    onClick={updateParameters}
                    sx={{ marginTop: '2em' }}
                >
                    Update parameters
                </LoadingButton>
            )}
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={snack.open}
                autoHideDuration={6000}
                onClose={handleClose}
                message={snack.creationMessage}
                action={
                    <React.Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose} >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            />
        </div>
    )
}