import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth } from "aws-amplify";

import Button from '@mui/material/Button';

import Validate from "../utility/FormValidation";
import { Box, Paper, Stack, Snackbar } from '@mui/material';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import LoadingButton from '@mui/lab/LoadingButton';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import logo from './../../logos/Logo_Cerchio_1000px.svg'

export default function ForgotPassword({ auth }) {

    const [credentialsForm, setCredentialsForm] = useState({
        email: '',
        checkEmail: false,
        isSending: false,
        openSnack: false,
        messageSnack: "",
        errors: {
            email: "",
            cognito: ""
        },
    });



    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setCredentialsForm({
            ...credentialsForm,
            openSnack: false,
            messageSnack: ''
        });
    };

    const handleSubmit = async () => {
        try {
            setCredentialsForm({
                ...credentialsForm,
                isSending: true,
            });
            await Auth.forgotPassword(credentialsForm.email);
            setCredentialsForm({
                ...credentialsForm,
                email: '',
                isSending: false,
                checkEmail: true,
                openSnack: true,
                messageSnack: 'We have sent you an email with the link'
            });
        } catch (error) {
            setCredentialsForm({
                ...credentialsForm,
                errors: {
                    ...credentialsForm.errors,
                    cognito: error.message || 'Unexpect error during authentication',
                }
            });
        }
    }


    const onInputChange = event => {
        const error = Validate(credentialsForm => { return { ...credentialsForm, [event.target.id]: event.target.value } });
        setCredentialsForm(credentialsForm => {
            return {
                ...credentialsForm,
                [event.target.id]: event.target.value,
                errors: { ...credentialsForm.errors, ...error, cognito: null }
            }
        });

    }


    const history = useHistory();
    const redirect = (dst) => {
        history.push(dst);
    }

    return (

        <Paper sx={{ width: '60%', minWidth: '30em', maxWidth: '40em', m: 'auto', marginTop: '10%', paddingTop: '2em' }} elevation={3}>
            <Stack
                component="form"
                noValidate
                autoComplete="off"
                direction='column'
                alignItems='center'
            >
                {/*  <FormErrors formerrors={credentialsForm.errors} /> */}

                <img src={logo} height="100" alt="pagination logo" />



                <Typography sx={{ m: '1em', marginTop: '2em', width: '80%', maxWidth: '30em' }}>
                    Please enter the email address associated with your account and we'll
                    email you a password reset link.
                </Typography>
                <TextField
                    label="email"
                    id="email"
                    value={credentialsForm.email}
                    onChange={onInputChange}
                    error={credentialsForm.errors.email !== ""}
                    helperText={credentialsForm.errors.email ? credentialsForm.errors.email : ''}
                    sx={{ m: 1, width: '80%', maxWidth: '30em' }}
                />


                {credentialsForm.errors.cognito !== undefined &&

                    <Typography variant='caption' color='error' sx={{ marginBottom: '1em' }}>
                        {credentialsForm.errors.cognito}
                    </Typography>

                }


                <LoadingButton
                    color="primary"
                    disabled={credentialsForm.errors.email !== '' || credentialsForm.email === ''}
                    loading={credentialsForm.isSending}
                    //loadingPosition="start"
                    variant="contained"

                    onClick={handleSubmit}
                    sx={{ m:'1em', marginBottom: '2em' }}
                >
                    Submit
                </LoadingButton>


            </Stack >

            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={credentialsForm.openSnack}
                autoHideDuration={60000}
                onClose={handleClose}
                message={credentialsForm.messageSnack}
                action={
                    <Stack direction='row'>
                        <Button color="primary" variant='contained' onClick={() => redirect('/login')}>
                            Return to Login
                        </Button>

                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose} >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </Stack>
                }

            />


        </Paper >

    )

}
