import React from 'react';
import oops from './../../logos/webapp_ooops.svg'
import { Box, Typography } from '@mui/material';

export default class ErrorBoundary extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            error: { message: '', stack: '' },
            info: { componentStack: '' }
        };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }


    componentDidCatch(error, info) {
        // You can also log the error to an error reporting service
        console.log(error, info)
        this.setState({ error, info });
        //logErrorToMyService(error, errorInfo);
    }

    render() {
        const { hasError, error, info } = this.state;
        const { children } = this.props;

        return hasError ? <ErrorComponent /> : children;
    }
}


function ErrorComponent() {

    return <Box
        sx={{ textAlign: 'center' }}
        mt={20}
    >
        <img src={oops} style={{ width: "auto", height: "300px" }} />
        <Typography variant="h4" mt={5}>
            Ooops, something went wrong!
        </Typography>


    </Box >
}