

import { useEffect, useState } from "react";

export function useWindowDimension({ refCard }) {


    const [dimension, setDimension] = useState(refCard.current ? refCard.current.offsetWidth : undefined);

    useEffect(() => {
        setDimension(refCard.current.offsetWidth);
    }, [refCard.current && refCard.current.offsetWidth]); // Note this empty array. this effect should run only on mount and unmount



    useEffect(() => {
        const debouncedResizeHandler = debounce(() => {
           // console.log('***** debounced resize', refCard.current ? refCard.current.offsetWidth / 2 : undefined); // See the cool difference in console
            setDimension(refCard.current ? refCard.current.offsetWidth  : undefined);
        }, 100); // 100ms
        window.addEventListener('resize', debouncedResizeHandler);
        return () => window.removeEventListener('resize', debouncedResizeHandler);
    }, []); // Note this empty array. this effect should run only on mount and unmount


    return dimension;
}

function debounce(fn, ms) {
    let timer;
    return _ => {
        clearTimeout(timer);
        timer = setTimeout(_ => {
            timer = null;
            fn.apply(this, arguments);
        }, ms);
    };
}
