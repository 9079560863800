import { createTheme } from '@mui/material/styles';
import './../../App.css'

const Primarytheme = createTheme({

  palette: {
    // attack mui CssBaseline compoent
    background: {
      default: '#EDEDED',
    },


    primary: {
      light: '#C0E1F8',
      main: '#3397E2',
      dark: '#336FB6',
      contrastText: '#ffffff',

    },

    secondary: {
      light: '#A2F1D2',
      main: '#2FCD8F',
      dark: '#26A976',
      contrastText: '#ffffff',

    },
    neutral: {
      light: '#CECECE',
      main: '#8F8F8F',
      dark: '#686868',
      contrastText: '#ffffff',
    },
    lightPrimary: {
      light: '#CECECE',
      main: '#b5b5b5',
      dark: '#686868',
      contrastText: '#ffffff',
    },
    white: {
      light: '#ffffff',
      main: '#ffffff',
      dark: '#336FB6',
      contrastText: '#000000',
    },

    error: {
      main: '#C13030',
      dark: '#802020',
      light: '#FF4D4D'
    },

    warning: {
      main: '#EF9C41',
      dark: '#B37430',
      light: '#EF9C41',
      contrastText: '#ffffff',
    },
  },
  typography: {
    fontFamily: [
      'Work Sans',
    ].join(','),

  },
});

export default Primarytheme


