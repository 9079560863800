import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import HelpIcon from '@mui/icons-material/Help';
import Popover from '@mui/material/Popover';
import TextField from '@mui/material/TextField';
import { SketchPicker, ChromePicker } from 'react-color'

import { Tooltip, Box, Paper, sliderClasses } from '@mui/material';
import Primarytheme from './theme';

function cmykToHex(c, m, y, k) {
  // Normalize CMYK values
  const normalizedC = c / 100;
  const normalizedM = m / 100;
  const normalizedY = y / 100;
  const normalizedK = k / 100;

  // Calculate RGB values
  const r = Math.round(255 * (1 - normalizedC) * (1 - normalizedK));
  const g = Math.round(255 * (1 - normalizedM) * (1 - normalizedK));
  const b = Math.round(255 * (1 - normalizedY) * (1 - normalizedK));

  // Convert RGB to hexadecimal
  const rgbHex = ((r << 16) | (g << 8) | b).toString(16).padStart(6, '0');
  return '#' + rgbHex.toUpperCase();
}



export default function ColorOption({ handleChangeCheckOption, opt, id, conf }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [c, setC] = useState(conf.selectedPaginationOptions[id].value ? conf.selectedPaginationOptions[id].value.split('-')[0] : '000')
  const [m, setM] = useState(conf.selectedPaginationOptions[id].value ? conf.selectedPaginationOptions[id].value.split('-')[1] : '000')
  const [y, setY] = useState(conf.selectedPaginationOptions[id].value ? conf.selectedPaginationOptions[id].value.split('-')[2] : '000')
  const [k, setK] = useState(conf.selectedPaginationOptions[id].value ? conf.selectedPaginationOptions[id].value.split('-')[3] : '000')
  const [errorMessage, setErrorMessage] = useState('');
  const [cmyk, setCmyk] = useState(conf.selectedPaginationOptions[id].value ? cmykToHex(c, m, y, k) : '#ffffff')

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const idPopOver = open ? 'simple-popover' : undefined;

  if (opt.type === 'picker') {
    return (
      <Stack direction='row' key={id} alignItems='center' sx={{ marginBottom: '2em' }}>
        <div>
          <Stack direction='row' key={id + 'button'} alignItems='center' spacing={2}>

            <Button variant='contained' onClick={(event) => { handleChangeCheckOption(id, opt.value); handleClick(event) }}>
              {opt.nome}
            </Button>

            <Paper
              sx={{
                //width: 24,
                // height: 24,
                paddingLeft: '0.5em',
                paddingRight: '0.5em',
                backgroundColor: opt.value
              }}
              variant="outlined"
            >
              {opt.value}
            </Paper>
          </Stack>
          <Popover
            id={idPopOver}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <ChromePicker
              color={opt.value}
              disableAlpha={true}
              onChange={(e) => { console.log(e); handleChangeCheckOption(id, e.hex) }}
              width={opt.width}
            />
          </Popover>

        </div>
        <Tooltip title={
          <Typography variant='caption' >
            {opt.descrizione}
          </Typography>}>
          <HelpIcon sx={{ fontSize: '1em', ml: '0.5em', color: Primarytheme.palette.neutral.main }} />
        </Tooltip>
      </Stack>
    )
  }

  if (opt.type === 'cmyk') {
    return (
      <Stack key={id} sx={{ marginBottom: '2em' }} spacing={1} >
        <Typography mb={1} variant="body1" component="div" color="primary">
          {opt.nome}
        </Typography>
        <Stack direction='row' alignItems='center' spacing={1} key={opt.nome} sx={{ marginBottom: '2em' }}>
          <TextField
            sx={{ width: opt.width ? opt.width : 60 }}
            label={'C'}
            size='small'
            value={c}
            onChange={(e) => {
              if (!isNaN(e.target.value) && parseInt(e.target.value) >= 0 && parseInt(e.target.value) <= 100) {
                setErrorMessage('')
                let currentC = ('000' + e.target.value).slice(-3);
                setC(currentC);
                setCmyk(cmykToHex(currentC, m, y, k));
                handleChangeCheckOption(id, `${currentC}-${m}-${y}-${k}`)
              } else {
                setErrorMessage('Please enter a number between 000 and 100.')
              }
            }}
          />
          <TextField
            label={'M'}
            size='small'
            value={m}
            onChange={(e) => {
              if (!isNaN(e.target.value) && parseInt(e.target.value) >= 0 && parseInt(e.target.value) <= 100) {
                setErrorMessage('')
                let currentM = ('000' + e.target.value).slice(-3);
                setM(currentM);
                setCmyk(cmykToHex(c, currentM, y, k));
                handleChangeCheckOption(id, `${c}-${currentM}-${y}-${k}`)
              } else {
                setErrorMessage('Please enter a number between 000 and 100.')
              }
            }}
            sx={{ width: opt.width ? opt.width : 60 }}
          />
          <TextField
            label={'Y'}
            size='small'
            value={y}
            onChange={(e) => {
              if (!isNaN(e.target.value) && parseInt(e.target.value) >= 0 && parseInt(e.target.value) <= 100) {
                setErrorMessage('')
                let currentY = ('000' + e.target.value).slice(-3);
                setY(currentY);
                setCmyk(cmykToHex(c, m, currentY, k));
                handleChangeCheckOption(id, `${c}-${m}-${currentY}-${k}`)
              } else {
                setErrorMessage('Please enter a number between 000 and 100.')
              }
            }}
            sx={{ width: opt.width ? opt.width : 60 }}
          />
          <TextField
            label={'K'}
            size='small'
            value={k}
            onChange={(e) => {
              if (!isNaN(e.target.value) && parseInt(e.target.value) >= 0 && parseInt(e.target.value) <= 100) {
                setErrorMessage('')
                let currentK = ('000' + e.target.value).slice(-3);
                setK(currentK);
                setCmyk(cmykToHex(c, m, y, currentK));
                handleChangeCheckOption(id, `${c}-${m}-${y}-${currentK}`)
              } else {
                setErrorMessage('Please enter a number between 000 and 100.')
              }
            }}
            sx={{ width: opt.width ? opt.width : 60 }}
          />
          <Paper sx={{ backgroundColor: cmyk, width: 30, height: 30 }}></Paper>
          <Tooltip title={
            <Typography variant='caption' >
              {opt.descrizione}
            </Typography>}>
            <HelpIcon sx={{ fontSize: '1em', ml: '0.5em', color: Primarytheme.palette.neutral.main }} />
          </Tooltip>
        </Stack>
        {errorMessage && (
          <Typography mb={1} variant="caption" component="div" color="error">
            {errorMessage}
          </Typography>
        )}
      </Stack >
    )
  }
}