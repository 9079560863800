import Primarytheme from '../utility/theme';

import Typography from '@mui/material/Typography';
import Tooltip from "@mui/material/Tooltip";
import HelpIcon from '@mui/icons-material/Help';


export default {
    project: {
        label: 'Project',
        description:
            <Typography variant='body2' color='textSecondary' sx={{
                marginBottom: '1em', display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap'
            }}>
                Select the project you want to launch.
                <Tooltip title={
                    <Typography variant='caption' >
                        After selection, you can upload the input data file, customize the request and launch the process.
                    </Typography>}>
                    <HelpIcon sx={{ fontSize: '1em', ml: '0.5em', color: Primarytheme.palette.neutral.main }} />
                </Tooltip>
            </Typography>
    },
    data: {
        label: 'Data',
        description: <Typography variant='body2' color='textSecondary' sx={{
            marginBottom: '1em', display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap'
        }}>
            Upload the main data file (its name identifies the request).
            <Tooltip title={
                <Typography variant='caption' >
                    The data file extension (e.g. .xlsx, .csv, .zip, .idml) depends on the project. The new request will overwrite the previous one if you upload a data file with the same name as one previously uploaded.
                </Typography>}>
                <HelpIcon sx={{ fontSize: '1em', ml: '0.5em', color: Primarytheme.palette.neutral.main }} />
            </Tooltip>
        </Typography>,
    },
    launch: {
        label: 'Launch',
        description: <Typography variant='body2' color='textSecondary' sx={{ marginBottom: '1em' }}>Review the configuration.</Typography>,
    },

    process: {
        label: 'Process',
        description: <Typography variant='body2' color='textSecondary' sx={{
            marginBottom: '1em', display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap'
        }}>
            Choose the process
            <Tooltip title={
                <Typography variant='caption' >
                    The process you choose determines the options available next.
                </Typography>}>
                <HelpIcon sx={{ fontSize: '1em', ml: '0.5em', color: Primarytheme.palette.neutral.main }} />
            </Tooltip>
        </Typography>
    },
    static: {
        label: 'Static Pages',
        description:
            <Typography variant='body2' color='textSecondary' sx={{
                marginBottom: '1em', display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap'
            }}>
                Select the InDesign file(s) to load. Options are listed by their tags.
                <Tooltip title={
                    <Typography variant='caption' >
                        Your document can include static pages. Static pages must be uploaded as InDesign packages (front cover, back cover, introductory pages, final pages, etc.). Check the "ASSET MANAGEMENT" tab to verify that you have uploaded the correct files and you have assigned them the proper tags (an "ID" icon should guide you to the InDesign assets).
                    </Typography>}>
                    <HelpIcon sx={{ fontSize: '1em', ml: '0.5em', color: Primarytheme.palette.neutral.main }} />
                </Tooltip>
            </Typography>
    },

    assets: {
        label: 'Visuals',
        description:
            <Typography variant='body2' color='textSecondary' sx={{
                marginBottom: '1em', display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap'
            }}>
                Select the pdf or image file(s) to use.
            </Typography>
    },

    additionalData: {
        label: 'Additional data file',
        description:
            <Typography variant='body2' color='textSecondary' sx={{
                marginBottom: '1em', display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap'
            }}>
                Select the additional data file(s) to use.
            </Typography>
    },

    options: {
        label: 'Options',
        description: <Typography variant='body2' color='textSecondary' sx={{ marginBottom: '1em' }}>
            Configure the available options.
        </Typography>,
    }


}

