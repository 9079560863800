import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Tooltip from "@mui/material/Tooltip";
import HelpIcon from '@mui/icons-material/Help';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

import ColorOption from './ColorOption';
import Primarytheme from './theme';

import axios from 'axios';

const filter = createFilterOptions();

export default function Options({ conf, setConf, isAdmin, auth }) {

    const handleChangeCheckOption = (idOpt, newValue) => {
        // console.log(idOpt, newValue)
        const newConf = { ...conf };
        newConf.selectedPaginationOptions[idOpt].value = newValue;
        setConf(newConf);
    }
    const handleFormList = (idOpt, idBlock, idSingle, newValue) => {
        const newConf = { ...conf };
        newConf.selectedPaginationOptions[idOpt].list[idBlock][idSingle].value = newValue;
        setConf(newConf)
    }
    const handleChangeAutocomplete = (idOpt, newValue, idBlock, idSingle) => {
        const newConf = { ...conf };
        if (idBlock !== undefined) {
            if (newValue) newConf.selectedPaginationOptions[idOpt].list[idBlock][idSingle].value = newValue;
            else newConf.selectedPaginationOptions[idOpt].list[idBlock][idSingle].value = newConf.selectedPaginationOptions[idOpt].list[idBlock][idSingle].default;
        } else {
            if (newValue) newConf.selectedPaginationOptions[idOpt].value = newValue;
            else newConf.selectedPaginationOptions[idOpt].value = newConf.selectedPaginationOptions[idOpt].default;
        }
        setConf(newConf)
    }

    const handleAdminBox = ({ property, newValue }) => {
        const newConf = { ...conf };
        newConf[property] = newValue;
        setConf(newConf)
    }

    const handleAdminQueueBox = ({ property, newValue }) => {
        const newConf = { ...conf };
        if (newValue) {
            newConf[property] = 'requests_vpc_test';
            setConf(newConf)
        } else {
            newConf[property] = 'requests_vpc';
            setConf(newConf)
        }
    }

    const handleChangeInDesignVersion = (newValue) => {
        const newConf = { ...conf };
        if (newValue) newConf.indesign_version = newValue;
        //else newConf.indesign_version = conf.indesign_version;
        setConf(newConf)
    }

    const handleChangeAutocompleteCreatable = (idOpt, newValue) => {
        const newConf = { ...conf };
        if (newValue) newConf.selectedPaginationOptions[idOpt].value = newValue;
        else {
            if (newConf.selectedPaginationOptions[idOpt].default) newConf.selectedPaginationOptions[idOpt].value = newConf.selectedPaginationOptions[idOpt].default;
            else newConf.selectedPaginationOptions[idOpt].value = null;
        }
        setConf(newConf)
    }

    const updateConf = (updatedConf) => {
        return axios({
            method: "put",
            url: auth.UPDATE_CONF_URL,
            headers: {
                'x-api-key': auth.UPDATE_CONF_KEY,
                'user': 'webapp',
                "content-type": "application/json",
            },
            data: JSON.stringify({ conf: updatedConf }),
            json: true
        }).then(response => {
            console.log('updated')
        })
            .catch(err => console.log(err))
    }

    const handleAddOptionConf = (idOpt, inputValue) => {
        const newConf = { ...conf };

        if (inputValue) {
            if (newConf.customPaginationOptions && newConf.customPaginationOptions[idOpt].custom_listAutocomplete) {
                if (newConf.customPaginationOptions[idOpt].custom_listAutocomplete.filter(t => t === inputValue).length === 0) {
                    newConf.customPaginationOptions[idOpt].custom_listAutocomplete = newConf.customPaginationOptions[idOpt].custom_listAutocomplete.concat([inputValue]);
                }
            } else {
                newConf.customPaginationOptions = {};
                newConf.customPaginationOptions[idOpt] = {};
                newConf.customPaginationOptions[idOpt].custom_listAutocomplete = [inputValue];
            }

            const updatedConf = { ...newConf };
            updateConf(updatedConf);
            setConf(updatedConf);
        }
    }

    const deleteOptionCreatable = (idOpt, delValue) => {
        const newConf = { ...conf };

        // update conf
        if (delValue && newConf.customPaginationOptions && newConf.customPaginationOptions[idOpt]) {
            if (newConf.customPaginationOptions[idOpt].custom_listAutocomplete) {
                newConf.customPaginationOptions[idOpt].custom_listAutocomplete = newConf.customPaginationOptions[idOpt].custom_listAutocomplete.filter(t => t !== delValue);
            }

            const updatedConf = { ...newConf };
            updateConf(updatedConf);
            setConf(updatedConf);

            handleChangeAutocompleteCreatable(idOpt);
        }
    }

    const handleRenderOptionCreatable = (id, props, option) => {
        if (conf.customPaginationOptions && conf.customPaginationOptions[id].custom_listAutocomplete.filter(t => t === option).length) {
            // option added manually
            return (
                <Stack direction='row' key={'stack' + option} alignItems='center'>
                    <Box component="li" sx={{ width: '100%' }} {...props}>{option}</Box><IconButton size="small" onClick={() => deleteOptionCreatable(id, option)} ><DeleteIcon /></IconButton>
                </Stack>
            )

        } else {
            const optionToPrint = option.name ? option.name : option;
            return <Box component="li" {...props}>{optionToPrint}</Box>
        }
    }


    return (
        <Box>
            <Box sx={{ marginTop: '2em' }}>
                {conf.selectedPaginationOptions.map((opt, id) => {
                    if (opt.component === 'form') {
                        return (
                            <Stack direction='row' key={id} alignItems='center' sx={{ marginBottom: '2em' }}>
                                <TextField
                                    label={opt.nome}
                                    size='small'
                                    value={conf.selectedPaginationOptions[id].value}
                                    onChange={(e) => handleChangeCheckOption(id, e.target.value)}
                                    sx={{ width: opt.width }}
                                />
                                <Tooltip title={
                                    <Typography variant='caption' >
                                        {opt.descrizione}
                                    </Typography>}>
                                    <HelpIcon sx={{ fontSize: '1em', ml: '0.5em', color: Primarytheme.palette.neutral.main }} />
                                </Tooltip>
                            </Stack>


                        )
                    }
                    if (opt.component === 'form-list') {
                        return (
                            <Box >
                                <Typography  >
                                    <b>{opt.nome}</b>
                                </Typography>
                                <Box component='ul' sx={{ display: 'flex', justifyContent: 'left', flexWrap: 'wrap', listStyle: 'none', p: 0, m: 0 }}>
                                    {opt.list.map((block, idBlock) => (

                                        <Stack direction='column' spacing={1} sx={{ mt: '1.5em', mr: '0.5em' }} >
                                            {block.map((singleOpt, idSingle) => (
                                                <li>
                                                    {(!singleOpt.component || singleOpt.component === 'form') && (
                                                        <Stack direction='column' alignItems='left' key={opt.nome} sx={{ marginBottom: '2em' }}>
                                                            <TextField
                                                                key={singleOpt.nome}
                                                                label={singleOpt.nome}
                                                                size='small'
                                                                value={conf.selectedPaginationOptions[id].list[idBlock][idSingle].value}
                                                                onChange={(e) => handleFormList(id, idBlock, idSingle, e.target.value)}
                                                                sx={{ width: singleOpt.width, }}
                                                            />
                                                            <Typography variant='caption' >
                                                                {singleOpt.descrizione}
                                                            </Typography>
                                                        </Stack>
                                                    )}
                                                    {singleOpt.component === 'autocomplete' && (
                                                        <Stack direction='column' alignItems='left' key={opt.nome} sx={{ marginBottom: '2em' }}>
                                                            <Autocomplete
                                                                disablePortal
                                                                options={singleOpt.list}
                                                                sx={{ width: singleOpt.width }}
                                                                clearOnEscape
                                                                disableClearable={singleOpt.disableClearable}
                                                                value={conf.selectedPaginationOptions[id].list[idBlock][idSingle].value}
                                                                onChange={(event, value) => handleChangeAutocomplete(id, value, idBlock, idSingle)}
                                                                size='small'
                                                                renderInput={(params) => <TextField {...params} label={singleOpt.nome} />}
                                                            />
                                                            <Typography variant='caption' >
                                                                {singleOpt.descrizione}
                                                            </Typography>
                                                        </Stack>
                                                    )}
                                                </li>

                                            ))}
                                        </Stack>
                                    ))}
                                </Box>
                            </Box>
                        )
                    }

                    if (opt.component === 'checkBox') {
                        //console.log('checkState = ', conf.selectedPaginationOptions[id].value)
                        return (
                            <Stack direction='row' alignItems='center' key={opt.nome} sx={{ marginBottom: '2em' }}>
                                <FormControlLabel
                                    label={opt.nome}
                                    control={<Checkbox
                                        checked={conf.selectedPaginationOptions[id].value}
                                        onChange={(e) => handleChangeCheckOption(id, e.target.checked)}
                                    />}
                                />
                                <Tooltip title={
                                    <Typography variant='caption' >
                                        {opt.descrizione}
                                    </Typography>}>
                                    <HelpIcon sx={{ fontSize: '1em', ml: '0.5em', color: Primarytheme.palette.neutral.main }} />
                                </Tooltip>
                            </Stack>
                        )
                    }
                    if (opt.component === 'radio') {
                        return (
                            <Stack direction='row' alignItems='center' key={opt.nome} sx={{ marginBottom: '2em' }}>
                                <Stack direction='row' justifyContent="flex-start" alignItems="center" >
                                    <FormControl>
                                        <RadioGroup
                                            name="flow-selector"
                                            value={conf.selectedPaginationOptions[id].value ? conf.selectedPaginationOptions[id].value : opt.default}
                                            onChange={(e) => handleChangeCheckOption(id, e.target.value)}
                                        >
                                            {conf.selectedPaginationOptions[id].radioSet.map(tag => <FormControlLabel key={tag} value={tag} control={<Radio />} label={<Typography color='textSecondary'>{tag}</Typography>} />)}
                                        </RadioGroup>
                                    </FormControl>
                                </Stack>
                                <Tooltip title={
                                    <Typography variant='caption' >
                                        {opt.descrizione}
                                    </Typography>}>
                                    <HelpIcon sx={{ fontSize: '1em', ml: '0.5em', color: Primarytheme.palette.neutral.main }} />
                                </Tooltip>
                            </Stack>
                        )
                    }
                    if (opt.component === 'autocomplete') {
                        return (
                            <Stack direction='row' alignItems='center' key={opt.nome} sx={{ marginBottom: '2em' }}>
                                <Autocomplete
                                    disablePortal
                                    options={opt.list}
                                    sx={{ width: opt.width }}
                                    clearOnEscape
                                    disableClearable={opt.disableClearable}
                                    value={conf.selectedPaginationOptions[id].value}
                                    onChange={(event, value) => handleChangeAutocomplete(id, value)}
                                    size='small'
                                    renderInput={(params) => <TextField {...params} label={opt.nome} />}
                                />
                                <Tooltip title={
                                    <Typography variant='caption' >
                                        {opt.descrizione}
                                    </Typography>}>
                                    <HelpIcon sx={{ fontSize: '1em', ml: '0.5em', color: Primarytheme.palette.neutral.main }} />
                                </Tooltip>
                            </Stack>
                        )
                    }
                    if (opt.component === 'autocomplete-creatable') {
                        return (
                            <Stack direction='row' alignItems='center' key={opt.nome} sx={{ marginBottom: '2em' }}>
                                <Autocomplete
                                    size='small'
                                    value={conf.selectedPaginationOptions[id].value}
                                    onChange={(event, newValue) => {
                                        if (typeof newValue === 'string') {
                                            handleChangeAutocompleteCreatable(id, newValue)
                                        } else if (newValue && newValue.inputValue) {
                                            // Create a new value from the user input
                                            handleChangeAutocompleteCreatable(id, newValue.inputValue);
                                            handleAddOptionConf(id, newValue.inputValue);
                                        } else {
                                            handleChangeAutocompleteCreatable(id, newValue)
                                        }
                                    }}
                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params);

                                        const { inputValue } = params;
                                        // Suggest the creation of a new value
                                        const isExisting = options.some((option) => inputValue === option.name);
                                        if (inputValue !== '' && !isExisting) {
                                            filtered.push({
                                                inputValue,
                                                name: `Add "${inputValue}"`,
                                            });
                                        }
                                        return filtered;
                                    }}
                                    selectOnFocus
                                    clearOnBlur
                                    clearOnEscape
                                    disableClearable={opt.disableClearable}
                                    handleHomeEndKeys
                                    id={id.toString()}
                                    options={[...opt.list, ...((conf.customPaginationOptions && conf.customPaginationOptions[id].custom_listAutocomplete.length) ? conf.customPaginationOptions[id].custom_listAutocomplete : [])]}
                                    getOptionLabel={(option) => {
                                        // Value selected with enter, right from the input
                                        if (typeof option === 'string') {
                                            return option;
                                        }
                                        // Add "xxx" option created dynamically
                                        if (option.inputValue) {
                                            return option.inputValue;
                                        }
                                        // Regular option
                                        return option.name;
                                    }}
                                    renderOption={(props, option) =>
                                        (handleRenderOptionCreatable(id, props, option))
                                    }

                                    sx={{ mr: '1em', mt: 0, minWidth: 200, flexGrow: 1, maxWidth: '31%' }}
                                    freeSolo
                                    renderInput={(params) => <TextField {...params} label={opt.nome} />}
                                />
                                <Tooltip title={
                                    <Typography variant='caption' >
                                        {opt.descrizione}
                                    </Typography>}>
                                    <HelpIcon sx={{ fontSize: '1em', ml: '0.5em', color: Primarytheme.palette.neutral.main }} />
                                </Tooltip>
                            </Stack>
                        )
                    }
                    if (opt.component === 'color') {
                        return (
                            <ColorOption handleChangeCheckOption={handleChangeCheckOption} opt={opt} id={id} conf={conf} />
                        )
                    }
                })}
            </Box>

            {
                isAdmin && (
                    <Stack direction='column' key='only-admin'>
                        <Divider sx={{ mt: '1em', mb: '1em' }} />
                        <FormControlLabel key='only-admin'
                            label='internal test'
                            control={<Checkbox
                                checked={conf.internalTest}
                                onChange={(e) => handleAdminBox({ property: 'internalTest', newValue: e.target.checked })}
                            />}

                        />
                        <FormControlLabel key='onlyvalidation'
                            label='do only data integration'
                            control={<Checkbox
                                checked={conf.doOnlyValidation}
                                onChange={(e) => handleAdminBox({ property: 'doOnlyValidation', newValue: e.target.checked })}
                            />}

                        />
                        <FormControlLabel key='test-admin'
                            label='send to test queue (requests_vpc_test)'
                            control={<Checkbox
                                checked={conf.queue === 'requests_vpc_test'}
                                onChange={(e) => handleAdminQueueBox({ property: 'queue', newValue: e.target.checked })}
                            />}

                        />

                        {conf.queue === 'requests_vpc_test' && (
                            <Stack direction='row' alignItems='center' key={'indesign_version'} sx={{ marginBottom: '2em' }}>
                                <Autocomplete
                                    disablePortal
                                    options={[15, 16, 18]}
                                    sx={{ width: '25%' }}
                                    clearOnEscape
                                    disableClearable={true}
                                    value={conf.indesign_version}
                                    onChange={(event, value) => handleChangeInDesignVersion(value)}
                                    size='small'
                                    renderInput={(params) => <TextField {...params} label={'indesign_version'} />}
                                />
                                <Tooltip title={
                                    <Typography variant='caption' >
                                        {'Choose the InDesign version to use:'}<br></br>{'15 (2020)'}<br></br>{'16 (2021)'}<br></br>{'18 (2023)'}
                                    </Typography>}>
                                    <HelpIcon sx={{ fontSize: '1em', ml: '0.5em', color: Primarytheme.palette.neutral.main }} />
                                </Tooltip>
                            </Stack>
                        )}
                    </Stack>
                )
            }

        </Box >

    )
}