
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

export default function CustomFilterMenu({ label, originalList, menuListSelected, handleChange, ...props }) {

  return (
        <Autocomplete
          multiple
          //limitTags={2}
          id="multiple-limit-tags"
          options={originalList}
          value={menuListSelected}
          onChange={handleChange}
          getOptionLabel={(option) => option}
          defaultValue={[]}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label={label} placeholder="" />
          )}
          sx={{...props}}
        />
  )
}