import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from "react-router-dom";

import Primarytheme from './theme';

import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';

import Chip from '@mui/material/Chip';

import Alert from '@mui/material/Alert';
import Tooltip from "@mui/material/Tooltip";

import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';


import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import AddCircleRounded from '@mui/icons-material/AddCircleRounded';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';


import axios from 'axios';

const radioRowLimit = 10;

export default function Process({ launch, conf, setConf, handleChangeTag, auth }) {

    const [value, setValue] = React.useState('');

    const [newProcess, setNewProcess] = React.useState('');

    const [open, toggleOpen] = React.useState(false);

    const handleClose = () => {
        setDialogValue({
            title: '',
            year: '',
        });

        toggleOpen(false);
    };

    const [dialogValue, setDialogValue] = React.useState({
        title: '',
        year: '',
    });

    const handleSubmit = (event) => {
        event.preventDefault();
        setValue({
            title: dialogValue.title,
            year: parseInt(dialogValue.year, 10),
        });

        handleClose();
    };

    const handleChange = (event, newValue) => {
        console.log(newValue)
        if (!newValue) setValue('')
        else if (newValue && newValue.indexOf('... Add "') !== -1) {
            console.log('new input', newValue.slice(9, -1))
            setValue(newValue.slice(9, -1));
        } else setValue(newValue);
    }

    const submitNewCustomTags = () => {
        // axios dynamo
    }

    const handleNewName = (event) => {
        setNewProcess(event.target.value)
    }

    const updateConf = (updatedConf) => {
        return axios({
            method: "put",
            url: auth.UPDATE_CONF_URL,
            headers: {
                'x-api-key': auth.UPDATE_CONF_KEY,
                'user': 'webapp',
                "content-type": "application/json",
            },
            data: JSON.stringify({ conf: updatedConf }),
            json: true
        }).then(response => {
            console.log('updated')
        })
            .catch(err => console.log(err))
    }


    const handleDeleteItem = (tag) => {
        const updatedConf = { ...conf, custom_tags: conf.custom_tags.filter(t => t !== tag) };
        updateConf(updatedConf);
        setConf(updatedConf)
    }

    const handleAddNewProcess = () => {
        if (conf.custom_tags.filter(t => t === newProcess).length === 0) {
            const updatedConf = { ...conf, custom_tags: conf.custom_tags.concat([newProcess]) };
            updateConf(updatedConf);
            setConf(updatedConf)
        }
        setNewProcess('')
    }

    return (
        <Stack direction='column' spacing={5}>


            {conf.tags.length > 0 &&
                <Stack direction='row' justifyContent="flex-start" alignItems="center">
                    <FormControl>
                        <RadioGroup
                            aria-labelledby="flow"
                            name="flow-selector"
                            value={launch.tag}
                            onChange={handleChangeTag}
                        >
                            {conf.tags.map(tag => <FormControlLabel key={tag} value={tag} control={<Radio />} label={<Typography color='textSecondary'>{tag}</Typography>} />)}
                        </RadioGroup>
                    </FormControl>
                </Stack>
            }


            {conf.limit_custom_tags &&
                <Box>
                    <Button size="small" aria-label="close" variant='outlined' onClick={() => toggleOpen(true)}
                        startIcon={<EditIcon fontSize="small" />} sx={{ mb: '1em',mt:'1em' }}>
                        Edit Process list
                    </Button>
                    <Box >
                        {/*  <Stack direction='row' justifyContent="flex-start" alignItems="center"> */}

                        <FormControl fullWidth  sx={{ display: 'flex', }}>
                            <RadioGroup
                                aria-labelledby="flow"
                                name="flow-selector"
                                value={launch.tag}
                                onChange={handleChangeTag}
                                row={conf.custom_tags.length >= radioRowLimit}
                                style={{width: '100%'}}
                                //style={{ display: 'flex', flexFlow: 'column wrap', justifyContent: "space-between" }}

                            >
                                {conf.custom_tags.map(tag =>
                                    <FormControlLabel
                                        key={tag} value={tag} control={<Radio />}
                                        label={<Typography color='textSecondary'>{tag}</Typography>}
                                        sx={{ minWidth: '20em', width: conf.custom_tags.length >= radioRowLimit ? '50%' : '100%', marginRight: 0, paddingRight: '1em'}}
                                    />

                                )}
                            </RadioGroup>
                        </FormControl>
                    </Box>
                    <Dialog open={open} onClose={handleClose}>

                        <DialogTitle>Edit Process List</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Here you can add new custom processes and delete unused ones.<br />
                                The selected process is used to pick up automatically static InDesign files or additional data.
                            </DialogContentText>
                            <Stack direction='row' justifyContent='space-between' sx={{ mt: '1em' }} >
                                <Stack direction='row' alignItems='flex-end' spacing={1}  >
                                    <Box>
                                        <IconButton sx={{ padding: 0.5 }} color='secondary' onClick={handleAddNewProcess}
                                            disabled={conf.custom_tags.length >= conf.limit_custom_tags}
                                        >
                                            <AddCircleRounded fontSize='medium' />
                                        </IconButton>
                                    </Box>

                                    <TextField
                                        disabled={conf.custom_tags.length >= conf.limit_custom_tags}
                                        autoFocus
                                        margin="dense"
                                        id="name"
                                        value={newProcess}
                                        onChange={handleNewName}
                                        label="name"
                                        type="text"
                                        variant="standard"
                                    />
                                </Stack>
                                {conf.custom_tags.length >= conf.limit_custom_tags && (
                                    <Alert elevation={1} severity="info" sx={{ marginTop: '1em' }}>Limit reached </Alert>
                                )}
                            </Stack>
                            <Stack direction='column' sx={{ mt: '1em' }}>
                                {conf.custom_tags.map(tag => (
                                    <Stack key={tag} direction='row' alignItems='center' spacing={1}>
                                        <IconButton sx={{ padding: 0.5 }} onClick={() => handleDeleteItem(tag)}>
                                            <DeleteIcon fontSize='small' />
                                        </IconButton>
                                        <Typography color='textSecondary'>{tag}</Typography>
                                    </Stack>
                                ))
                                }
                            </Stack>


                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Done</Button>

                        </DialogActions>

                    </Dialog>
                </Box>



            }




        </Stack >

    )
}