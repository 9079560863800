import React, { useState, useEffect } from 'react';

import Primarytheme from './utility/theme';
import Autocomplete from '@mui/material/Autocomplete';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import CloseIcon from '@mui/icons-material/Close';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TableVirtuoso } from 'react-virtuoso';

import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Snackbar } from '@mui/material';

import { styled } from '@mui/material/styles';
import { Grid, Typography, Stack, Checkbox, FormControlLabel, FormGroup, Button } from '@mui/material';

import ProjectDisable from './utility/ProjectDisable';
import ProjectParameters from './utility/ProjectParameters';

import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'

const { buildUsernameFromEmail } = require('./auth/utils-auth');

const axios = require('axios');

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'left',
    color: Primarytheme.palette.primary.main,
    fontSize: '16px',
    fontWeight: 'bold',
    background: 'white',
    boxShadow: '0px 0px 0px 0px, 1px 1px 5px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%)',
    border: '1px',
    minWidth: 950
}));


const {
    REACT_APP_X_API_KEY,
    REACT_APP_USER_ID,
    REACT_APP_USER_POOL_ID,
} = process.env;

export default function Billing({ auth }) {
    const colors = [
        '#4287f5',
        '#f5c842',
        '#f5ac6c',
        '#42dbb5',
        '#c70a0a',
        '#006f89',
        '#e32282',
        '#656565',
        '#0d1aa3',
        '#c4db5a',
        '#fc6c00',
        '#1d802f',
        '#ff8585',
        '#009974',
        '#820000',
        '#ccbd00',
        '#58006d',
        '#80ccff',
        '#689e00',
        '#82532f',
        '#bf00ed',
        '#994900',
        '#ffe969',
        '#874d5f',
        '#c4c4c4',
        '#b086f2',
        '#243f00',
        '#ffaee6',
        '#8c7228',
        '#303030',



    ]
    const monthLabel = {
        1: 'Jan',
        2: 'Feb',
        3: 'Mar',
        4: 'Apr',
        5: 'May',
        6: 'Jun',
        7: 'Jul',
        8: 'Aug',
        9: 'Sep',
        10: 'Oct',
        11: 'Nov',
        12: 'Dec'
    }

    const [projects, setProjects] = useState({ list: [], retrieving: true, });
    const [selectedProjects, setSelectedProjects] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(undefined);
    const [fetchingData, setFetchingData] = useState(false);
    const [plotData, setPlotData] = useState([
        /* {
            name: 'Jan',
            uv: 4000,
            pv: 2400,
            amt: 2400,
        }, */
    ]);
    const [plotDataBar, setPlotDataBar] = useState([]);

    const [tableData, setTableData] = useState([]);

    const [userMngmt, setUserMngmt] = useState({
        email: '',
        customer: '',
        isAdmin: false,
        policy: {
            canDelete: true,
            canLaunch: true,
            canManageAsset: true
        },
        //attached_project_ids: [],
        isCreating: false,
        created: false,
        selectedList: [],
        customMessage: ''
    })

    const [patchUsrMngmt, setPatchUsrMngmt] = useState({
        email: '',
        customer: '',
        attached_projects: [],
        selectedList: [],
        isAdmin: false,
        type: '',
        policy: {
            canDelete: false,
            canLaunch: false,
            canManageAsset: false
        },
        registrationDate: undefined,
        lastLogin: undefined,
        isFetching: false,
        fetched: false,
        isPatching: false,
        patched: false,
        openDelete: false,
        customMessage: ''
    })

    const [customerFilter, setCustomerFilter] = useState({
        customer: auth.userState.isAdmin ? 'pagination' : '', // filtro qui di default a pagination, ma anche nell'autocomplete è default a quel valore
        isFetching: false,
        fetched: false,
        users: []
    })

    const [snack, setSnack] = useState({
        open: false,
        creationMessage: ''
    })

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnack({
            open: false,
            creationMessage: ''
        });
    };

    const handleCustomerFilter = (event, value, reason) => {
        if (reason === 'clear') handleResetCustomerPatch();
        else {
            setCustomerFilter({ ...customerFilter, customer: value, users: [] })
            handleResetUserPatch()
        }
    }
    const handleResetCustomerPatch = () => {
        setCustomerFilter({
            customer: '',
            isFetching: false,
            fetched: false,
            users: []
        })
        handleResetUserPatch()
    }
    const handleSelectPathEmail = (event, value, reason) => {
        if (reason === 'clear') handleResetUserPatch()
        else {
            setPatchUsrMngmt({
                ...patchUsrMngmt, email: value.email, retrieving: true
            })
            getUserInfo(value.email)
                .then(response => {
                    //console.log(response.data);
                    const userData = response.data;
                    setPatchUsrMngmt({
                        ...patchUsrMngmt,
                        retrieving: false,
                        email: value.email,
                        selectedList: userData.attached_projects,
                        fetched: true,
                        attached_projects: userData.attached_projects,
                        isAdmin: userData.type === 'administrator',
                        type: userData.type,
                        policy: userData.policy ? userData.policy : { canDelete: true, canLaunch: true, canManageAsset: true },
                        registrationDate: userData.registrationDate ? (new Date(userData.registrationDate)).toUTCString() : 'unknown',
                        lastLogin: userData.lastLogin ? (new Date(userData.lastLogin)).toUTCString() : 'unknown',
                        customMessage: userData.custom_message ? userData.custom_message : undefined,
                    });
                    setProjects({ ...projects, retrieving: false })
                    //console.log(userData)
                    return userData
                })
                .catch(err => {
                    console.log(err)
                    setSnack({ open: true, creationMessage: 'fetch user error. Check dynamo.' })
                    setPatchUsrMngmt({ ...patchUsrMngmt, fetched: false });
                })
        }
    }
    const handleSelectedProject = (event, values, reason) => {
        if (reason === 'clear') {
            setSelectedProjects([]);
            setPlotDataBar([]);
            setMonthGraph();
            setTableData([]);
        }
        if (reason === 'selectOption') setSelectedProjects([...values]);
        if (reason === 'removeOption') {
            setSelectedProjects([...values]);
            setPlotDataBar([]);
            setTableData([]);
        }
    }

    const handleSelectedCustomer = (event, value, reason) => {
        setUserMngmt({ ...userMngmt, customer: value })
        if (reason === 'clear') {
            setSelectedCustomer(undefined);
            setSelectedProjects([]);
            setPlotDataBar([]);
            setMonthGraph();
            setTableData([]);
        }
        if (reason === 'selectOption') {
            if (selectedCustomer !== undefined) setSelectedProjects([]);
            else setSelectedCustomer(value);
        }
        if (reason === 'removeOption') {
            setSelectedCustomer(value)
            setPlotDataBar([]);
            setMonthGraph();
            setTableData([]);
        }
    }

    const showPlot = (async (event, value) => {
        console.log('fetching pagination processes');
        setFetchingData(true);
        const promises = [];

        if (selectedProjects.length > 0) {
            const currentDataTable = [];

            for (const selectedProject of selectedProjects) {
                // retrieve info for each month from today
                for (let t = 0; t < 13; t++) {
                    let dateend;
                    let datestart;
                    let today = new Date();
                    if (t !== 0) {
                        let previousMonth = new Date(new Date().setMonth(today.getMonth() - t))
                        datestart = new Date(new Date(previousMonth.setDate(1)).setHours(0, 0, 0, 0)); // prendo il primo del mese da mezzanotte
                        dateend = new Date(previousMonth.getFullYear(), previousMonth.getMonth() + 1, 0)
                    } else {
                        dateend = new Date(); // data di oggi
                        datestart = new Date(new Date(new Date().setDate(1)).setHours(0, 0, 0, 0)); // prendo il primo del mese da mezzanotte
                    }
                    promises.push(
                        axios({
                            method: "get",
                            url: 'https://api.pagination.com/vpc/operations',
                            headers: {
                                'user_id': REACT_APP_USER_ID || auth.userState.ssmParams.USER_ID,
                                "content-type": "application/json",
                                "x-api-key": REACT_APP_X_API_KEY || auth.userState.ssmParams.X_API_KEY,
                                "is_admin": 'true',
                                "project_id": selectedProject,
                                "datestart": datestart.getTime(),
                                "dateend": dateend.getTime(),
                                "stati": 'tutti',
                                "show_deleted": 'true'
                            }
                        })
                            .then(resp => {
                                /* example object
                                 {
                                  1: {
                                    name: "jan 2023"
                                    arnette: 1,
                                    b: 2
                                  }
                                } */
                                const items = resp.data.items;
                                //console.log('items', resp.data)
                                const countObj = {};

                                for (const process of items) {
                                    const startMonth = new Date(process.startTime).getMonth() + 1;
                                    const startYear = new Date(process.startTime).getFullYear();
                                    const projectId = process.project_id.split('/')[1];

                                    if (!countObj[monthLabel[startMonth] + ' ' + startYear]) {
                                        countObj[monthLabel[startMonth] + ' ' + startYear] = { name: monthLabel[startMonth] + ' ' + startYear }
                                        countObj[monthLabel[startMonth] + ' ' + startYear][projectId] = 1;
                                    } else {
                                        countObj[monthLabel[startMonth] + ' ' + startYear][projectId] = countObj[monthLabel[startMonth] + ' ' + startYear][projectId] ? countObj[monthLabel[startMonth] + ' ' + startYear][projectId] + 1 : 1;
                                    }

                                    // set data table
                                    const objTable = {
                                        id: process.reqId,
                                        projectId: process.project_id,
                                        date: (new Date(process.startTime)).toLocaleString().replace(/T/, ' ').replace(/\..+/, ''),
                                        user: process.owner,
                                        deleted: process.deleted ? 'Yes' : 'No',
                                        internalTest: process.internalTest ? 'Yes' : 'No',
                                        dateEpoch: process.startTime,
                                    }
                                    currentDataTable.push(objTable);
                                }
                                for (let i = 0; i < plotData.length; i++) {
                                    const obj = plotData[i];
                                    if (countObj[obj.name]) {
                                        plotData[i] = { ...obj, ...countObj[obj.name] }
                                    }
                                }
                            })
                            .catch(err => {
                                console.log(err)
                            })
                    );

                    await Promise.all(promises);
                }
            }
            currentDataTable.sort((a, b) => b.dateEpoch - a.dateEpoch)
            setPlotData(plotData);
            setPlotDataBar(selectedProjects);
            setTableData(currentDataTable);
        }

        setFetchingData(false);
    });


    const handleProjectCustomMessage = (event, values) => {
        setPatchUsrMngmt({ ...patchUsrMngmt, customMessage: event.target.value });
    }

    const handleResetUserPatch = () => {
        setPatchUsrMngmt({
            email: '',
            attached_projects: [],
            selectedList: [],
            isAdmin: false,
            type: '',
            registrationDate: undefined,
            lastLogin: undefined,
            policy: {
                canDelete: false,
                canLaunch: false,
                canManageAsset: false
            },
            isFetching: false,
            fetched: false,
            isPatching: false,
            patched: false,
            openDelete: false,
            customMessage: ''
        })
    }

    const retrieveAllProjects = async () => {
        axios.get('https://api.pagination.com/vpc/projects-webapp',
            {
                headers: {
                    'user_id': auth.userState.user,
                    "content-type": "application/json",
                    "x-api-key": REACT_APP_X_API_KEY || auth.userState.ssmParams.X_API_KEY,
                    "type": 'project-list',
                }
            },
        )
            .then(response => {
                //console.log(response.data.projectsList.map(el => `${el.customer}/${el.project_name}`))
                setProjects({ ...projects, list: response.data.projectsList.map(el => `${el.customer}/${el.project_name}`), retrieving: false });
            })
            .catch(err => {
                console.log(err)
                setProjects({ ...projects, retrieving: false });
            })
    }

    const fetchCustomerUsers = async () => {
        setCustomerFilter({ ...customerFilter, isFetching: true });
        return axios.get(`https://mjj82gfcl8.execute-api.eu-west-1.amazonaws.com/vpc/users`,
            {
                headers: {
                    'user_id': REACT_APP_USER_ID || auth.userState.ssmParams.USER_ID,
                    "content-type": "application/json",
                    "x-api-key": REACT_APP_X_API_KEY || auth.userState.ssmParams.X_API_KEY,

                    "customer": customerFilter.customer,
                    "action": 'fetch-customer'
                }

            })
            .then(response => {
                const users = response.data.users;
                setCustomerFilter({
                    ...customerFilter,
                    //selectedList: userData.attached_projects,
                    isFetching: false,
                    fetched: true,
                    users: users,

                });
                //console.log(userData)
                return users
            })
            .catch(err => {
                console.log(err)
                setSnack({ open: true, creationMessage: 'fetch user error. Check dynamo.' })
                setCustomerFilter({ ...customerFilter, fetched: false, isFetching: false });
            })

    }
    const getUserInfo = (email) => {
        return axios.get(`https://mjj82gfcl8.execute-api.eu-west-1.amazonaws.com/vpc/users`,
            {
                headers: {
                    'user_id': REACT_APP_USER_ID || auth.userState.ssmParams.USER_ID,
                    "content-type": "application/json",
                    "x-api-key": REACT_APP_X_API_KEY || auth.userState.ssmParams.X_API_KEY,
                    "userInfo_id": buildUsernameFromEmail(email), // lo user id è l'email con la chiocciola sostituita dal punto. per i primi utenti è solo la parte prima della chiocchiola
                    "email": email
                }

            })
    }

    const patchUserMessage = async (action = 'patch') => {
        setPatchUsrMngmt({ ...patchUsrMngmt, isPatching: true });

        axios.patch(`https://mjj82gfcl8.execute-api.eu-west-1.amazonaws.com/vpc/users`,
            {
                userInfo_id: buildUsernameFromEmail(patchUsrMngmt.email),
                project_ids: patchUsrMngmt.selectedList,
                type: patchUsrMngmt.type,
                custom_message: patchUsrMngmt.customMessage ? patchUsrMngmt.customMessage : "",
                action,
                user_pool_id: REACT_APP_USER_POOL_ID || auth.userState.ssmParams.USER_POOL_ID
            },
            {
                headers: {
                    'user_id': REACT_APP_USER_ID || auth.userState.ssmParams.USER_ID,
                    "content-type": "application/json",
                    "x-api-key": REACT_APP_X_API_KEY || auth.userState.ssmParams.X_API_KEY,
                }
            })
            .then(() => {
                if (patchUsrMngmt.email === auth.userState.email) {
                    auth.setUserState({ ...auth.userState, customMessage: patchUsrMngmt.customMessage })
                }
                if (patchUsrMngmt.customMessage) setSnack({ open: true, creationMessage: 'updated message for ' + patchUsrMngmt.email })
                else setSnack({ open: true, creationMessage: 'cleared message for ' + patchUsrMngmt.email })
            })
            .catch(err => {
                console.log(err)
                setSnack({ open: true, creationMessage: JSON.stringify(err) })
            })
            .finally(() => {
                handleResetUserPatch()
            })
    }

    const setMonthGraph = () => {
        // set moth based on today date
        const monthArr = [];
        var d = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
        d.setDate(1);
        for (let i = 12; i >= 0; i--) {
            const monthObj = { name: monthLabel[d.getMonth() + 1] + ' ' + d.getFullYear(), test: 0 };
            d.setMonth(d.getMonth() + 1)
            monthArr.push(monthObj);
        }
        setPlotData(monthArr);
    }

    // refresh al montaggio
    useEffect(() => {
        retrieveAllProjects();
        setMonthGraph();
    }, []);


    return (
        <div>
            <Grid container spacing={2} sx={{
                marginTop: '7em',
                marginBottom: '2em',
                paddingLeft: '32px',
                paddingRight: '32px',
                flexGrow: 1,

            }}>

                <Grid item xs={12} md={12}>
                    <Item key='project-countChart' >
                        <Typography variant='h5' sx={{ marginBottom: '1em' }} >
                            <b>Project Info Chart</b>
                        </Typography>
                        <Stack direction='row' spacing={2} >
                            <Autocomplete
                                disabled={projects.list.length === 0}
                                limitTags={1}
                                options={Array.from(new Set(projects.list.map(p => p.split('/').shift()))).sort()}
                                onChange={handleSelectedCustomer}
                                sx={{ width: '20%' }}
                                size='small'
                                renderInput={(params) => <TextField {...params} variant="outlined" label={projects.retrieving ? 'loading...' : 'customers'} placeholder="" />}
                            />
                            <Autocomplete
                                multiple
                                disabled={projects.list.length === 0 || selectedCustomer === undefined}
                                limitTags={5}
                                disableCloseOnSelect
                                id="multiple-limit-tags"
                                options={projects.list.filter(p => userMngmt.isAdmin || p.split('/').shift() === userMngmt.customer)}
                                value={selectedProjects}
                                onChange={handleSelectedProject}
                                sx={{ width: '50%' }}
                                size='small'
                                getOptionLabel={(option) => option}
                                //defaultValue={[]}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label={projects.retrieving ? 'loading...' : 'project-ids'} placeholder="" />
                                )}

                            />
                            <Button variant='contained' onClick={async () => { await showPlot() }}>
                                show plot
                            </Button>
                            {fetchingData && (<CircularProgress />)}
                        </Stack>
                        <ResponsiveContainer width="100%" height={600}>

                            <BarChart
                                data={plotData}
                                margin={{ top: 40, right: 40, bottom: 40, left: 0, }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />

                                {plotDataBar.map((prj, index) => (
                                    <Bar dataKey={prj.split('/')[1]} stackId="a" fill={colors[index]} />
                                ))}
                            </BarChart>
                        </ResponsiveContainer>

                        <InfoProjectTable tableData={tableData} />
                    </Item>
                </Grid>
                <Grid item xs={12} md={12}>
                    <Item key='project-parameters' >
                        <Typography variant='h5' sx={{ marginBottom: '1em' }} >
                            <b>Project Management</b>
                        </Typography>
                        <ProjectDisable auth={auth} projects={projects} />
                    </Item>
                </Grid>
                <Grid item xs={12} md={12}>
                    <Item key='project-parameters' >
                        <Typography variant='h5' sx={{ marginBottom: '1em' }} >
                            <b>Project Parameters</b>
                        </Typography>
                        <ProjectParameters auth={auth} projects={projects} />
                    </Item>
                </Grid>
                <Grid item xs={12} md={12}>
                    <Item key='patch-user' >
                        <Typography variant='h5' sx={{ marginBottom: '1em' }} >
                            <b>Custom Message</b>
                        </Typography>
                        <Stack direction='row' spacing={2} alignItems='flex-start' sx={{ width: '100%' }}>
                            <Autocomplete
                                disabled={projects.list.length === 0}
                                limitTags={1}
                                options={Array.from(new Set(projects.list.map(p => p.split('/').shift()))).sort()}
                                onChange={handleCustomerFilter}
                                defaultValue={undefined}
                                sx={{ width: '13em', minWidth: '13em' }}
                                size='small'
                                renderInput={(params) => <TextField {...params} variant="outlined" label={projects.retrieving ? 'loading...' : 'customers'} placeholder="" />}
                            />
                            <LoadingButton
                                disabled={customerFilter.customer === ''}
                                loading={customerFilter.isFetching}
                                variant="contained"
                                onClick={fetchCustomerUsers}
                                sx={{ width: '10em', minWidth: '10em', }}                            >
                                fetch users
                            </LoadingButton>


                            <Autocomplete
                                disabled={customerFilter.users.length === 0}
                                limitTags={1}
                                options={customerFilter.users}
                                getOptionLabel={(option) => option.email}
                                isOptionEqualToValue={(option, value) => option.email === value.email}
                                defaultValue={{ email: '' }}
                                value={{ email: patchUsrMngmt.email }}
                                onChange={handleSelectPathEmail}
                                sx={{ width: '30%', minWidth: '15em' }}
                                size='small'
                                renderInput={(params) => <TextField {...params} variant="outlined" label={customerFilter.isFetching ? 'loading...' : 'users'} placeholder="" />}
                            />


                            <TextField
                                disabled={projects.list.length === 0 || patchUsrMngmt.email === ''}
                                id="custom-billing-message"
                                label={'custom message'}
                                value={patchUsrMngmt.customMessage}
                                onChange={handleProjectCustomMessage}
                                sx={{ width: '50%' }}
                                size='small'
                            />
                        </Stack>

                        <Stack direction='row' spacing={2} mt='1em'>
                            <LoadingButton
                                disabled={patchUsrMngmt.email === '' || patchUsrMngmt.selectedList.length === 0}
                                loading={patchUsrMngmt.isPatching}
                                loadingPosition="start"
                                variant="contained"
                                onClick={() => patchUserMessage('patch')}
                            >
                                Update Message
                            </LoadingButton>
                        </Stack>
                        <Snackbar
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            open={snack.open}
                            autoHideDuration={6000}
                            onClose={handleClose}
                            message={snack.creationMessage}
                            action={
                                <React.Fragment>
                                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose} >
                                        <CloseIcon fontSize="small" />
                                    </IconButton>
                                </React.Fragment>
                            }
                        />
                    </Item>
                </Grid>
            </Grid>
        </div >
    )


    function InfoProjectTable({ tableData }) {
        const [order, setOrder] = useState('Date');
        const [tableDataPrint, setTableDataPrint] = useState(tableData);

        const handleChangeOrder = (event) => {
            setOrder(event.target.value);
            const oderType = event.target.value;
            switch (oderType) {
                case 'Project id':
                    tableDataPrint.sort((a, b) => a.projectId.localeCompare(b.projectId));
                    setTableDataPrint(tableDataPrint);
                    break;
                case 'User':
                    tableDataPrint.sort((a, b) => a.user.localeCompare(b.user));
                    setTableDataPrint(tableDataPrint);
                    break;
                default:
                    tableDataPrint.sort((a, b) => b.dateEpoch - a.dateEpoch);
                    setTableDataPrint(tableDataPrint);
            }
        };
        const columns = [
            /* {
                // width: 100,
                label: 'id',
                dataKey: 'id',
            }, */
            {
                // width: 200,
                label: 'Project id',
                dataKey: 'projectId',
            },
            {
                // width: 120,
                label: 'Date',
                dataKey: 'date',
            },
            {
                // width: 120,
                label: 'User',
                dataKey: 'user',
            },
            {
                // width: 120,
                label: 'Deleted flag',
                dataKey: 'deleted',
            },
            {
                // width: 120,
                label: 'Internal Test flag',
                dataKey: 'internalTest',
            },
        ];

        const VirtuosoTableComponents = {
            Scroller: React.forwardRef((props, ref) => (
                <TableContainer component={Paper} {...props} ref={ref} />
            )),
            Table: (props) => (
                <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
            ),
            TableHead,
            TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
            TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
        };

        function fixedHeaderContent() {
            return (
                <TableRow>
                    {columns.map((column) => (
                        <TableCell
                            key={column.dataKey}
                            variant="head"
                            align={column.numeric || false ? 'right' : 'left'}
                            style={{ width: column.width }}
                            sx={{
                                backgroundColor: 'background.paper',
                            }}
                        >
                            {column.label}
                        </TableCell>
                    ))}
                </TableRow>
            );
        }

        function rowContent(_index, row) {
            return (
                <React.Fragment>
                    {columns.map((column) => (
                        <TableCell
                            key={column.dataKey}
                            align={column.numeric || false ? 'right' : 'left'}
                        >
                            {row[column.dataKey]}
                        </TableCell>
                    ))}
                </React.Fragment>
            );
        }

        function ExportExcel({ excelData, fileName }) {
            const fileType = 'application/vdn.openxmlformats-officedocumet.spreadsheetml.sheet;charset=UTF-8'
            const fileExt = '.xlsx';

            const exportToExcel = async () => {
                const ws = XLSX.utils.json_to_sheet(excelData);
                const wb = { Sheets: { 'processes': ws }, SheetNames: ['processes'] };
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const data = new Blob([excelBuffer], { type: fileType });
                FileSaver.saveAs(data, fileName + fileExt);
            }

            return (
                <Button sx={{ marginBottom: '1em' }} variant='contained' onClick={(e) => exportToExcel(fileName)} >Excel Export</Button>
            )
        }

        return (
            (tableDataPrint.length !== 0 &&
                <>
                    <Stack direction='row' spacing={2} justifyContent={'space-between'} alignItems="center">
                        <Typography variant='h5' >
                            <b>Table</b>
                        </Typography>
                        <ExportExcel excelData={tableDataPrint} fileName={selectedCustomer + '_excel_export'} />
                    </Stack>
                    <Stack direction='row' spacing={2} alignItems="center" sx={{ marginBottom: '1em' }}>
                        <Typography >
                            <b>Order by (ascending order):</b>
                        </Typography>
                        <FormControl size='small' sx={{ minWidth: 120, marginBottom: '1em' }}>
                            <Select
                                defaultValue="Date"
                                displayEmpty
                                id="order-select"
                                value={order}
                                onChange={handleChangeOrder}
                            >
                                <MenuItem value="Date">Date</MenuItem>
                                <MenuItem value="Project id">Project id</MenuItem>
                                <MenuItem value="User">User</MenuItem>
                            </Select>
                        </FormControl>
                    </Stack>
                    <Paper style={{ height: 400, width: '100%' }}>
                        <TableVirtuoso
                            data={tableDataPrint}
                            components={VirtuosoTableComponents}
                            fixedHeaderContent={fixedHeaderContent}
                            itemContent={rowContent}
                        />
                    </Paper>
                </>
            )
        )
    }
}